import Icon from '../Icons/OPCService';
import OpcServerList from './OpcServerList';
import OpcServerCreate from './OpcServerCreate';
import OpcServerEdit from './OpcServerEdit';
import OpcServerShow from './OpcServerShow';

const OpcServerIcon = Icon;


export {
    OpcServerIcon,
    OpcServerList,
    OpcServerShow,
    OpcServerCreate,
    OpcServerEdit,
};
