import React from 'react';
import groupBy from 'lodash/groupBy';
import Grid from '@material-ui/core/Grid';
import useQuery from '../../../dataProvider/Prometheus/useQuery';
import Loading from "../Loading";
import { URI_PROMETHEUS } from '../../../appsettings';
import ServerInstance from './ServerInstance';
import moment from "moment";



const ServerInstances = ({ filter, ...props }) => {
    const { time } = filter
    const date = moment(time)
    const { data, loading, error } = useQuery({
        uri: URI_PROMETHEUS,
        query: [
            `100 - (avg by (instance, job) (irate(node_cpu_seconds_total{mode="idle"}[5m])) * 100)&time=${date.unix()}`,
            `100 - (((node_memory_MemTotal_bytes - node_memory_MemFree_bytes - node_memory_Buffers_bytes - node_memory_Cached_bytes) / node_memory_MemTotal_bytes) * 100)&time=${date.unix()}`,
            `100 - ((node_filesystem_free_bytes / node_filesystem_size_bytes) * 100)&time=${date.unix()}`,
        ]
    })

    if (error) return ("error");
    if (loading) return (<Loading />)

    const cpuLoad = groupBy(data && data.length && data[0], instance => instance.metric.job);
    const ramLoad = groupBy(data && data.length && data[1], instance => instance.metric.job);
    const diskLoad = groupBy(data && data.length && data[2], instance => instance.metric.job);

    return (
        <Grid container spacing={2}>
            {Object.keys(cpuLoad).sort().map(instance => (
                <Grid item xs={6} key={instance}>
                    <ServerInstance
                        instance={instance}
                        cpuLoad={cpuLoad[instance] && cpuLoad[instance][0] && cpuLoad[instance][0].value[1] || 'NaN'}
                        ramLoad={ramLoad[instance] && ramLoad[instance][0] && ramLoad[instance][0].value[1] || 'NaN'}
                        diskLoad={diskLoad[instance] && diskLoad[instance][0] && diskLoad[instance][0].value[1] || 'NaN'}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

ServerInstance.defaultProps = {
    filter: {
        time: moment().format('YYYY-MM-DDTHH:mm'),
        rangeVector: '24h'
    }
}

export default ServerInstances;