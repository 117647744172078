import React, {cloneElement, Children, isValidElement, useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslate, useVersion} from 'react-admin';
import FillDataGapsServiceClient from "../../dataProvider/FillDataGapsService/FillDataGapsServiceClient";
import StartFillDataGapsServiceCard from "./StartFillDataGapsServiceCard";
import StopFillDataGapsServiceCard from "./StopFillDataGapsServiceCard";
import UpdateConfigFillDataGapsServiceCard from "./UpdateConfigFillDataGapsServiceCard";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
}));

const FillDataGapsServiceControlLayout = ({children, className, label, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { record } = props;
    const client = FillDataGapsServiceClient(record.url);
    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label, {_:label})}</Typography>}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={3} xl={2}>
                    <StartFillDataGapsServiceCard client={client} record={record}/>
                </Grid>
                <Grid item xs={6} sm={4} md={3} xl={2}>
                    <StopFillDataGapsServiceCard client={client} record={record}/>
                </Grid>
                <Grid item xs={6} sm={4} md={3} xl={2}>
                    <UpdateConfigFillDataGapsServiceCard client={client} record={record}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default FillDataGapsServiceControlLayout;