import React from 'react';
import {useShowController, TextField, DateField} from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import FileParserServiceBanner from './FileParserServiceBanner';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";
import FileParserChartsField from "./FileParserChartField";
import MetricsLayout from "../Layout/MetricsLayout";
import ParsedFilesChart from "../Metrics/ParsedFilesChart";
import SummaryDayChart from "../Metrics/SummaryDayChart";
import MetricsArrayField from "../Layout/MetricsArrayField";
import MetricsContainer from "../Layout/MetricsContainer";



const FileParserServiceShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);

    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
           
            <FileParserServiceBanner />


            <MetricsLayout>
                <MetricsContainer xs={6}>
                    <ParsedFilesChart/>
                    <SummaryDayChart/>
                </MetricsContainer>
            </MetricsLayout>

            <CardShowLayout>
                {ability.can('getOne', 'FileParserService', 'login')&&<TextField source="login" />}
                {ability.can('getOne', 'FileParserService', 'url')&&<TextField source="url" />}
                {ability.can('getOne', 'FileParserService', 'prometheusJob')&&<TextField source="prometheusJob" />}
            </CardShowLayout>

            {ability.can('getOne', 'FileParserService', 'meta')&&<CardShowLayout>
                <JsonField source="meta"/>
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'FileParserService', 'id')&&<TextField source="id" />}
                    {ability.can('getOne', 'FileParserService', 'createdAt')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'FileParserService', 'createdBy')&&<TextField source="createdBy.name" label="resources.FileParserService.fields.createdBy" />}
                    {ability.can('getOne', 'FileParserService', 'updatedAt')&&<DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default FileParserServiceShow;