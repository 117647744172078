import React, {useEffect} from 'react';
import {useShowController, TextField, DateField} from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";
import DataBusToXmlServiceBanner from './DataBusToXmlServiceBanner';
import MetricsContainer from "../Layout/MetricsContainer";
import MetricsLayout from "../Layout/MetricsLayout";
import MetricDefaultFilters from "../Layout/MetricsDefaultFilters";
import RunsCounter from "../Metrics/DataBusToXmlServiceCounters/RunsCounter";
import ErrorsCounter from "../Metrics/DataBusToXmlServiceCounters/ErrorsCounter";
import GeneratedXmlCounter from "../Metrics/DataBusToXmlServiceCounters/GeneratedXmlCounter";
import RestartCronCard from "./RestartCronCard";
import ExecGenerateXmlCard from "./ExecGenerateXmlCard";


const DataBusToXmlServiceShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);

    if(controllerProps && controllerProps.record && controllerProps.record.meta) {
        delete controllerProps.record.meta.configsIds;
    }

    return (
        <ShowView
            
        title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            
            <DataBusToXmlServiceBanner />
            
            <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/>}>
                <MetricsContainer xs={6} sm={4} md={3} xl={2}>
                    <RunsCounter />
                    <ErrorsCounter />
                    <GeneratedXmlCounter />
                    <RestartCronCard/>
                    <ExecGenerateXmlCard/>
                </MetricsContainer>
            </MetricsLayout>


            <CardShowLayout>
                {ability.can('getOne', 'DataBusToXmlService', 'login') && <TextField source="login" />}
                {ability.can('getOne', 'DataBusToXmlService', 'url') && <TextField source="url" />}
            </CardShowLayout>

            {ability.can('getOne', 'DataBusToXmlService', 'meta')&& <CardShowLayout>
                <JsonField  source="meta"/>
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'DataBusToXmlService', 'id')&&<TextField source="id" />}
                    {ability.can('getOne', 'DataBusToXmlService', 'createdAt')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'DataBusToXmlService', 'createdBy')&&<TextField source="createdBy.name" label="resources.DataBusToXmlService.fields.createdBy" />}
                    {ability.can('getOne', 'DataBusToXmlService', 'updatedAt')&&<DateField source="updatedAt" />}
                    {ability.can('getOne', 'DataBusToXmlService', 'prometheusJob')&&<TextField source="prometheusJob" />}
                </CardShowLayout>
            </MoreContent>


        </ShowView>
    );
};

export default DataBusToXmlServiceShow;