import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRedirect, useRefresh, useTranslate, fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import SyncIcon from '@material-ui/icons/Sync';
import { getAccessToken } from '../../authPrivider/getAccessToken';
import Typography from '@material-ui/core/Typography';

export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    root: {
        height: 152,
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#689F38",
        backgroundColor: "#c5e1a5",
    },
    action: {
        height: "100%",
    },
    ml2: {marginLeft: 8},
}));

const RestartCronCard = ({ 
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    client,
    redirect: redirectTo,
    ...rest }) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);

    const handleClick = () => {

        if(!record.url) {
            notify('ra.notification.databustoxml_error_url', 'warning');
        }

        setLoading(true);
        dispatch(fetchStart());

        getAccessToken().then(token => fetch(record.url+'/graphql', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'authorization': token ? `Bearer ${token}` : ''
            },
            body: JSON.stringify({ 
                query : `mutation  {
                    exechour : execSync(data: { mode: HOURS }) {
                      status
                    }
                    execday : execSync(data: { mode: DAYS }) {
                      status
                    }
                }` 
            })
        }))
        .then(res => res.json())   
        .then(res => {
            if (res.errors && res.errors.length > 0) {
                throw Error(res.errors.map(x => x.message).join('\n\r'));
            } else {
                return (res || {}).data;
            }      
        }).catch((error) => {
            notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
        }).finally(() => {
            setLoading(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    };


    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} disabled={loading} classes={{root: classes.action}}>
                <CardHeader
                    avatar={<Avatar variant="rounded" className={classes.avatar}><SyncIcon/></Avatar>}
                    title="Выгрузить XML"
                    titleTypographyProps={{variant:"subtitle1"}}
                    classes={{title: classes.title}}
                />
                <CardContent>
                    {loading && <Typography variant="body1" color="textSecondary">Генерация XML ... Может занять несколько часов</Typography>}
                </CardContent>
            </CardActionArea>
        </Card>
    );
        
};

export default RestartCronCard;