import React, {useEffect, useState} from 'react';
import { useTranslate } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Drawer from '@material-ui/core/Drawer';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InfoBanner from '../Layout/InfoBanner';
import Notification  from './Notification';
import { db } from "../../IndexedDB/IndexedDB";
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const styles = makeStyles(theme => ({
    paper: {
        minWidth:"24rem",
        maxWidth:"28rem",
    },
    mlAuto:{ marginLeft:"auto" },
    notificationHeader:{
        paddingTop: 8,
        paddingBottom: 8,
    },
    action:{
        marginTop: 0,
    },
    notification:{
        margin: 8,
    },
    notificationWrapper:{
        paddingTop: 0,
    },
    notificationFooter:{
        paddingTop: 0,
        marginTop: "auto",
        display: "flex",
        alignItems: "center",
    },
    getMoreButton:{
        marginLeft: "auto",
        marginTop: -8,
        marginBottom: -8,

    }
}));



const NotificationsDrawer = ({ open, setOpen, notifications, ...props}) => {
    const classes = styles();
    const translate = useTranslate();
    const [page, setPage] = useState(0);
    const notificationArray = notifications.slice(0 + 8 * page,Math.min( 8 + 8 * page, notifications.length));
    const handleCloseClick = () => {
        setOpen(false);
    };

    const handleClearAllNotifications = () => {
        const transaction = db.transaction("Notification", 'readwrite');
        transaction.objectStore('Notification').clear();
    };

    const handleGetMoreNotifications = () => {
        if ((page+1) * 8 < notifications.length){
            setPage(page + 1)
        }
    };

    const handleGetLessNotifications = () => {
        if (page > 0 ) setPage(page - 1);
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleCloseClick}   ModalProps={{hideBackdrop: false}}>
            <CardHeader
                title={translate("resources.Notification.name", {smart_count:2})}
                titleTypographyProps={{ variant: 'subtitle1' }}
                action={
                    <>
                        <Tooltip title={translate("ra.action.clear_all")} enterDelay={700} >
                            <IconButton  aria-label="ClearAll" onClick={handleClearAllNotifications} disabled={false/*!notifications.length > 0*/}>
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("ra.action.close")} enterDelay={700}>
                            <IconButton onClick={handleCloseClick} aria-label="Close" >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            { (notificationArray && notificationArray.length > 0)
                ? (
                    <CardContent className={classes.notificationWrapper}>
                    {notificationArray.map((notification, index) => {

            
                        return (<Notification key={ 'notification' + index } notification={notification}/>)
                    })}
                    </CardContent>
                )
                : (
                    <CardContent style={{minWidth: 356}}>
                        <InfoBanner

                            title={translate("resources.Notification.no_notifications")}
                            subtitle="Как только появится что-то интересное, Вы об этом узнаете"
                        >
                            <NotificationsOffIcon color="disabled" style={{fontSize:48}}/>
                        </InfoBanner>
                    </CardContent>
                )
            }
            <CardContent className={classes.notificationFooter}>
                <Typography variant="caption">{`Отображается c ${notifications.length ? (1 + page * 8) : 0} по ${Math.min( 8 + 8 * page, notifications.length)} из ${notifications.length}`}</Typography>
                {(notificationArray.length < notifications.length) && <div>
                    <Button size="small" onClick={handleGetLessNotifications} className={classes.getMoreButton} >
                        <ChevronLeftIcon/>
                    </Button>
                    <Button size="small" onClick={handleGetMoreNotifications} className={classes.getMoreButton} >
                        <ChevronRightIcon/>
                    </Button>
                </div>}
            </CardContent >
        </Drawer>
    
    );
};



export default NotificationsDrawer ;
