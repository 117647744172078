import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

const GET_ENUMS =  gql`
query getEnums($name: String!) {
  __type(name: $name) {
    name
    enumValues{
      name
      description
    }                          
  }
}
`

export default function(enumName) {
  const [enums, updateEnums] = useState([])

  useEffect(() => {

    async function fetchData() {
      try {
        const enumData = await apolloCoreClient.query({
          query: GET_ENUMS,
          variables: { name: enumName },
          onError: (error) => {
              alert(error)
          }              
        }); 
  
        updateEnums(enumData.data.__type.enumValues)
  
      } catch (error) {
        console.log('error: ', error)
      }
    }
    fetchData();

    
  }, [])
  return enums;
}