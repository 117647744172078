import gql from 'graphql-tag';

const EMBEDDED_SIMPLE_TYPE = `{
    id
    name
    description
}`;

const EMBEDDED_USER_TYPE = `{
    id
    name
    login
    email
    phone
}`;


const COUNTERPARTY_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    type
    inn
    kpp
    okpo
    ogrn
    fio
    passport
    passdateissue
    parentId
    addressLegal
    addressActual
    addressPostal
    phone
    phoneMobile
    email
    users ${EMBEDDED_USER_TYPE}
    accessResource
    access {id resource}
}`

export const GET_LIST_COUNTERPARTY = gql`
    query allCounterparties($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: CounterpartyFilter) {
    items: allCounterparties(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${COUNTERPARTY_TEMPLATE}
    total: _allCounterpartiesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_COUNTERPARTIES = gql`
    query allCounterparties($filter: CounterpartyFilter) {
    items: allCounterparties(filter: $filter) ${COUNTERPARTY_TEMPLATE}
    total: _allCounterpartiesMeta(filter: $filter) { count }
  }
`

export const GET_ONE_COUNTERPARTY = gql`
    query Counterparty( $where: CounterpartyWhereUniqueInput! ) {
        data: Counterparty( where: $where ) ${COUNTERPARTY_TEMPLATE}
    }
`

export const UPDATE_COUNTERPARTY = gql`
    mutation updateCounterparty( $where: CounterpartyWhereUniqueInput!, $name: String, $description: String, $inn: String, $type: CounterpartyType, $kpp: String, $okpo: String, $ogrn: String, $fio: String, $passport: String, $passdateissue: DateTime, $parentId: ID, $addressLegal: String, $addressActual: String, $addressPostal: String, $phone: String, $phoneMobile: String, $email: String, $users: [UserWhereUniqueInput!], $accessResource: AccessResource, $access: [AccessInput!] ) {
        data: updateCounterparty( where: $where, name: $name, description: $description, inn: $inn, type: $type, kpp: $kpp, okpo: $okpo, ogrn: $ogrn, fio: $fio, passport: $passport, passdateissue: $passdateissue, parentId: $parentId, addressLegal: $addressLegal, addressActual: $addressActual, addressPostal: $addressPostal, phone: $phone, phoneMobile: $phoneMobile, email: $email, users: $users, accessResource: $accessResource, access: $access ) ${COUNTERPARTY_TEMPLATE}
    }
`

export const DELETE_COUNTERPARTY = gql`
    mutation deleteCounterparty( $where: CounterpartyWhereUniqueInput! ) {
        data: deleteCounterparty( where: $where ) { id }
    }
`

const USER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    authId
    banned
    login
    email
    phone
    phoneContact
    role
    online
    counterparty ${EMBEDDED_SIMPLE_TYPE}
    position
    accessResource
    access {id resource}
    apps ${EMBEDDED_SIMPLE_TYPE}
}`

export const GET_LIST_USER = gql`
    query allUsers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilter) {
    items: allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${USER_TEMPLATE}
    total: _allUsersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_USERS = gql`
    query allUsers($filter: UserFilter) {
    items: allUsers(filter: $filter) ${USER_TEMPLATE}
    total: _allUsersMeta(filter: $filter) { count }
  }
`

export const GET_ONE_USER = gql`
    query User( $where: UserWhereUniqueInput! ) {
        data: User( where: $where ) ${USER_TEMPLATE}
    }
`

export const UPDATE_USER = gql`
    mutation updateUser( $where: UserWhereUniqueInput!, $name: String, $description: String, $authId: ID, $banned: Boolean, $login: String, $email: String, $phone: String, $phoneContact: String, $role: UserRole, $counterparty: CounterpartyWhereUniqueInput, $position: String, $accessResource: AccessResource, $access: [AccessInput!], $apps: [AppWhereUniqueInput!] ) {
        data: updateUser( where: $where, name: $name, description: $description, authId: $authId, banned: $banned, login: $login, email: $email, phone: $phone, phoneContact: $phoneContact, role: $role, counterparty: $counterparty, position: $position, accessResource: $accessResource, access: $access, apps: $apps) ${USER_TEMPLATE}
    }
`

export const DELETE_USER = gql`
    mutation deleteUser( $where: UserWhereUniqueInput! ) {
        data: deleteUser( where: $where ) { id }
    }
`

export const UPDATE_PROFILE = gql`
    mutation updateProfile( $where: UserWhereUniqueInput!, $name: String, $description: String, $authId: ID, $login: String, $email: String, $phone: String, $phoneContact: String, $role: UserRole, $position: String) {
        data: updateProfile( where: $where, name: $name, description: $description, authId: $authId, login: $login, email: $email, phone: $phone, phoneContact: $phoneContact, role: $role, position: $position) ${USER_TEMPLATE}
    }
`

const APP_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    users ${EMBEDDED_USER_TYPE}
    avatar
    url
}`;

export const GET_LIST_APP = gql`
    query allApps($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: AppFilter) {
        items: allApps(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${APP_TEMPLATE}
        total: _allAppsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_APPS = gql`
    query allApps($filter: AppFilter) {
        items: allApps(filter: $filter) ${APP_TEMPLATE}
        total: _allAppsMeta(filter: $filter) { count }
    }
`;

export const GET_ONE_APP = gql`
    query App( $where: AppWhereUniqueInput! ) {
        data: App( where: $where ) ${APP_TEMPLATE}
    }
`;

export const UPDATE_APP = gql`
    mutation updateApp( $where: AppWhereUniqueInput!, $name: String, $description: String, $url: String, $users: [UserWhereUniqueInput!], $avatar: String) {
        data: updateApp( where: $where, name: $name, description: $description, url: $url, users: $users, avatar: $avatar) ${APP_TEMPLATE}
    }
`;

export const DELETE_APP = gql`
    mutation deleteApp( $where: AppWhereUniqueInput! ) {
        data: deleteApp( where: $where ) { id }
    }
`;

const DATA_BUS_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    login
    prometheusJob
    meta
}`;

export const GET_LIST_DATA_BUS = gql`
    query allDataBuses($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: DataBusFilter) {
        items: allDataBuses(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${DATA_BUS_TEMPLATE}
        total: _allDataBusesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_DATA_BUSES = gql`
    query allDataBuses($filter: DataBusFilter) {
        items: allDataBuses(filter: $filter) ${DATA_BUS_TEMPLATE}
        total: _allDataBuses(filter: $filter) { count }
    }
`;

export const GET_ONE_DATA_BUS = gql`
    query DataBus( $where: DataBusWhereUniqueInput! ) {
        data: DataBus( where: $where ) ${DATA_BUS_TEMPLATE}
    }
`;

export const UPDATE_DATA_BUS = gql`
    mutation updateDataBus( $where: DataBusWhereUniqueInput!, $name: String, $description: String, $login: String, $url: String, $prometheusJob: String, $authId: ID, $meta: Json) {
        data: updateDataBus( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${DATA_BUS_TEMPLATE}
    }
`;

export const DELETE_DATA_BUS = gql`
    mutation deleteDataBus( $where: DataBusWhereUniqueInput! ) {
        data: deleteDataBus( where: $where ) { id }
    }
`;

const CONVERTER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    login
    prometheusJob
    meta
}`;

export const GET_LIST_CONVERTER = gql`
    query allConverters($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: ConverterFilter) {
        items: allConverters(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${CONVERTER_TEMPLATE}
        total: _allConvertersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_CONVERTERS = gql`
    query allConverters($filter: ConverterFilter) {
        items: allConverters(filter: $filter) ${CONVERTER_TEMPLATE}
        total: _allConverters(filter: $filter) { count }
    }
`;

export const GET_ONE_CONVERTER = gql`
    query Converter( $where: ConverterWhereUniqueInput! ) {
        data: Converter( where: $where ) ${CONVERTER_TEMPLATE}
    }
`;

export const UPDATE_CONVERTER = gql`
    mutation updateConverter( $where: ConverterWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateConverter( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${CONVERTER_TEMPLATE}
    }
`;

export const DELETE_CONVERTER = gql`
    mutation deleteConverter( $where: ConverterWhereUniqueInput! ) {
        data: deleteConverter( where: $where ) { id }
    }
`;


const FILL_DATA_GAPS_SERVICE_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    login
    prometheusJob
    meta
}`;

export const GET_LIST_FILL_DATA_GAPS_SERVICE = gql`
    query allFillDataGapsService($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: FillDataGapsServiceFilter) {
        items: allFillDataGapsServices(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${FILL_DATA_GAPS_SERVICE_TEMPLATE}
        total: _allFillDataGapsServicesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_FILL_DATA_GAPS_SERVICES = gql`
    query allFillDataGapsServices($filter: FillDataGapsServiceFilter) {
        items: allFillDataGapsServices(filter: $filter) ${FILL_DATA_GAPS_SERVICE_TEMPLATE}
        total: _allFillDataGapsServices(filter: $filter) { count }
    }
`;

export const GET_ONE_FILL_DATA_GAPS_SERVICE = gql`
    query FillDataGapsService( $where: FillDataGapsServiceWhereUniqueInput! ) {
        data: FillDataGapsService( where: $where ) ${FILL_DATA_GAPS_SERVICE_TEMPLATE}
    }
`;

export const UPDATE_FILL_DATA_GAPS_SERVICE = gql`
    mutation updateFillDataGapsService( $where: FillDataGapsServiceWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateFillDataGapsService( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${FILL_DATA_GAPS_SERVICE_TEMPLATE}
    }
`;

export const DELETE_FILL_DATA_GAPS_SERVICE = gql`
    mutation deleteFillDataGapsService( $where: FillDataGapsServiceWhereUniqueInput! ) {
        data: deleteFillDataGapsService( where: $where ) { id }
    }
`;

const CALL_SERVICE_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    login
    prometheusJob
    meta
}`;

export const GET_LIST_CALL_SERVICE = gql`
    query allCallServices($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: CallServiceFilter) {
        items: allCallServices(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${CALL_SERVICE_TEMPLATE}
        total: _allCallServicesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_CALL_SERVICES = gql`
    query allConverters($filter: CallServiceFilter) {
        items: allCallServices(filter: $filter) ${CALL_SERVICE_TEMPLATE}
        total: _allCallServices(filter: $filter) { count }
    }
`;

export const GET_ONE_CALL_SERVICE = gql`
    query CallService( $where: CallServiceWhereUniqueInput! ) {
        data: CallService( where: $where ) ${CALL_SERVICE_TEMPLATE}
    }
`;

export const UPDATE_CALL_SERVICE = gql`
    mutation updateCallService( $where: CallServiceWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateCallService( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${CALL_SERVICE_TEMPLATE}
    }
`;

export const DELETE_CALL_SERVICE = gql`
    mutation deleteCallService( $where: CallServiceWhereUniqueInput! ) {
        data: deleteCallService( where: $where ) { id }
    }
`;

const OPC_SERVER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    login
    prometheusJob
    meta
}`;

export const GET_LIST_OPC_SERVER = gql`
    query allOpcServers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: OpcServerFilter) {
        items: allOpcServers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${OPC_SERVER_TEMPLATE}
        total: _allOpcServersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_OPC_SERVERS = gql`
    query allOpcServers($filter: OpcServerFilter) {
        items: allOpcServers(filter: $filter) ${OPC_SERVER_TEMPLATE}
        total: _allOpcServers(filter: $filter) { count }
    }
`;

export const GET_ONE_OPC_SERVER = gql`
    query OpcServer( $where: OpcServerWhereUniqueInput! ) {
        data: OpcServer( where: $where ) ${OPC_SERVER_TEMPLATE}
    }
`;

export const UPDATE_OPC_SERVER = gql`
    mutation updateOpcServer( $where: OpcServerWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateOpcServer( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${OPC_SERVER_TEMPLATE}
    }
`;

export const DELETE_OPC_SERVER = gql`
    mutation deleteOpcServer( $where: OpcServerWhereUniqueInput! ) {
        data: deleteOpcServer( where: $where ) { id }
    }
`;

const FILE_PARSER_SERVICE_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    prometheusJob
    login
    meta
}`;

export const GET_LIST_FILE_PARSER_SERVICE = gql`
    query allFileParserServices($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: FileParserServiceFilter) {
        items: allFileParserServices(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${FILE_PARSER_SERVICE_TEMPLATE}
        total: _allFileParserServicesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_FILE_PARSER_SERVICES = gql`
    query allFileParserServices($filter: FileParserServiceFilter) {
        items: allFileParserServices(filter: $filter) ${FILE_PARSER_SERVICE_TEMPLATE}
        total: _allFileParserServices(filter: $filter) { count }
    }
`;

export const GET_ONE_FILE_PARSER_SERVICE = gql`
    query FileParserService( $where: FileParserServiceWhereUniqueInput! ) {
        data: FileParserService( where: $where ) ${FILE_PARSER_SERVICE_TEMPLATE}
    }
`;

export const UPDATE_FILE_PARSER_SERVICE = gql`
    mutation updateFileParserService( $where: FileParserServiceWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateFileParserService( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${FILE_PARSER_SERVICE_TEMPLATE}
    }
`;

export const DELETE_FILE_PARSER_SERVICE = gql`
    mutation deleteFileParserService( $where: FileParserServiceWhereUniqueInput! ) {
        data: deleteFileParserService( where: $where ) { id }
    }
`;

const DATABUS_TO_XML_SERVICE_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    prometheusJob
    login
    meta
}`;

export const GET_ONE_DATABUS_TO_XML_SERVICE = gql`
    query DataBusToXmlService( $where: DataBusToXmlServiceWhereUniqueInput! ) {
        data: DataBusToXmlService( where: $where ) ${DATABUS_TO_XML_SERVICE_TEMPLATE}
    }
`;

export const GET_LIST_DATABUS_TO_XML_SERVICE = gql`
    query allDataBusToXmlServices($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: DataBusToXmlServiceFilter) {
        items: allDataBusToXmlServices(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${FILE_PARSER_SERVICE_TEMPLATE}
        total: _allDataBusToXmlServicesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_DATABUS_TO_XML_SERVICE = gql`
    query allDataBusToXmlServices($filter: DataBusToXmlServiceFilter) {
        items: allDataBusToXmlServices(filter: $filter) ${DATABUS_TO_XML_SERVICE_TEMPLATE}
        total: _allDataBusToXmlServices(filter: $filter) { count }
    }
`;

export const UPDATE_DATABUS_TO_XML_SERVICE = gql`
    mutation updateDataBusToXmlService( $where: DataBusToXmlServiceWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateDataBusToXmlService( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${DATABUS_TO_XML_SERVICE_TEMPLATE}
    }
`;

const ANALITICS_SERVICE_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    searchIndex
    authId
    url
    login
    prometheusJob
    meta
}`;

export const GET_LIST_ANALITICS_SERVICE = gql`
    query allAnalyticsServices($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, , $filter: AnalyticsServiceFilter) {
        items: allAnalyticsServices(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${ANALITICS_SERVICE_TEMPLATE}
        total: _allAnalyticsServicesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`;

export const GET_MANY_ANALITICS_SERVICES = gql`
    query allAnalyticsServices($filter: AnalyticsServiceFilter) {
        items: allAnalyticsServices(filter: $filter) ${ANALITICS_SERVICE_TEMPLATE}
        total: _allAnalyticsServices(filter: $filter) { count }
    }
`;

export const GET_ONE_ANALITICS_SERVICE = gql`
    query AnalyticsService( $where: AnalyticsServiceWhereUniqueInput! ) {
        data: AnalyticsService( where: $where ) ${ANALITICS_SERVICE_TEMPLATE}
    }
`;

export const UPDATE_ANALITICS_SERVICE = gql`
    mutation updateAnalyticsService( $where: AnalyticsServiceWhereUniqueInput!, $name: String, $description: String, $login: String, $prometheusJob: String, $url: String, $authId: ID, $meta: Json) {
        data: updateAnalyticsService( where: $where, name: $name, description: $description, url: $url, login: $login, prometheusJob: $prometheusJob, authId: $authId, meta: $meta) ${ANALITICS_SERVICE_TEMPLATE}
    }
`;

export const DELETE_ANALITICS_SERVICE = gql`
    mutation deleteAnalyticsService( $where: AnalyticsServiceWhereUniqueInput! ) {
        data: deleteAnalyticsService( where: $where ) { id }
    }
`;




const HISTORY_TEMPLATE = `{
    id
    resource
    item
    name
    description
    action
    data
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
}`;

export const GET_LIST_HISTORY = gql`
    query allHistories($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: HistoryFilter) {
    items: allHistories(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${HISTORY_TEMPLATE}
    total: _allHistoriesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_HISTORIES = gql`
    query allHistories($filter: HistoryFilter) {
    items: allHistories(filter: $filter) ${HISTORY_TEMPLATE}
    total: _allHistoriesMeta(filter: $filter) { count }
  }
`

export const GET_ONE_HISTORY = gql`
    query History( $id: ID! ) {
        data: History( id: $id ) ${HISTORY_TEMPLATE}
    }
`

const TRASHITEM_TEMPLATE = `{
    id
    resource
    item
    name
    description
    deletedAt
    deletedBy ${EMBEDDED_USER_TYPE}
}`

export const GET_LIST_TRASHITEM = gql`
    query allTrashItems($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: TrashItemFilter) {
    items: allTrashItems(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${TRASHITEM_TEMPLATE}
    total: _allTrashItemsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_TRASHITEMS = gql`
    query allTrashItems($filter: TrashItemFilter) {
    items: allTrashItems(filter: $filter) ${TRASHITEM_TEMPLATE}
    total: _allTrashItemsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_TRASHITEM = gql`
    query TrashItem( $id: ID! ) {
        data: TrashItem( id: $id ) ${TRASHITEM_TEMPLATE}
    }
`