import React from 'react';
import {useShowController, TextField, DateField } from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import ConverterBanner from './ConverterBanner';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';
import MetricsContainer from "../Layout/MetricsContainer";
import ConverterСontrolLayout from "./ConverterСontrolLayout";
import apolloConverterClient from "../../dataProvider/Converter/apolloConverterClient";
import { URI_CONVERTER } from "../../appsettings";
import ConverterErrorsCounter from "../Metrics/ConverterCounters/ConverterErrorsCounter";
import ConvertItemErrorsCounter from "../Metrics/ConverterCounters/ConvertItemErrorsCounter";
import ActiveGasMetersCounter from "../Metrics/ConverterCounters/ActiveGasMetersCounter";
import ConvertItemStartCounter from "../Metrics/ConverterCounters/ConvertItemStartCounter";
import CronStartedCounter from "../Metrics/ConverterCounters/CronStartedCounter";
import ConvertStartedCounter from "../Metrics/ConverterCounters/ConvertStartedCounter";
import ConvertItemAllCounter from "../Metrics/ConverterCounters/ConvertItemAllCounter";
import ConvertItemForDayCounter from "../Metrics/ConverterCounters/ConvertItemForDayCounter";
import CronErrorsCounter from "../Metrics/ConverterCounters/CronErrorsCounter";
import InstanceCounter from "../Metrics/ConverterCounters/InstanceCounter";
import LastUpdateChart from "../Metrics/ConverterCharts/LastUpdateChart";
import MetricsLayout from "../Layout/MetricsLayout";
import MetricDefaultFilters from "../Layout/MetricsDefaultFilters";
import StartTotalHeatMap from "../Metrics/ConverterCharts/StartTotalHeatMap";
import LastUpdateHeatMap from "../Metrics/ConverterCharts/LastUpdateHeatMap";


const useStyles = makeStyles(theme => ({
   
}));

const ConverterShow = props => {
    const classes = useStyles();
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showMetricsButton = React.useRef(null);
    const isDeleted = controllerProps.record && controllerProps.record.deleted;
    const client = apolloConverterClient(URI_CONVERTER);
    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >

            <ConverterBanner />

            <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/>}>
                <MetricsContainer label="resources.Converter.fields.default_metrics" xs={6} sm={4} md={3} xl={2}>
                    <ActiveGasMetersCounter/>
                    <ConvertItemStartCounter/>
                    <CronStartedCounter/>
                    <ConvertStartedCounter/>
                    <ConvertItemAllCounter/>
                    <ConvertItemForDayCounter/>
                    <InstanceCounter/>
                    <CronErrorsCounter/>
                    <ConverterErrorsCounter />
                    <ConvertItemErrorsCounter/>
                </MetricsContainer>
            </MetricsLayout>


            <Separator action={(<div ref={showMetricsButton} ></div>)}/>

            <MoreContent showMoreButton={showMetricsButton} label="resources.Converter.fields.more_metrics">
                <MetricsLayout>
                    <MetricsContainer xs={12}>
                        <MetricsContainer xs={6}>
                            <LastUpdateChart/>
                        </MetricsContainer>
                        <StartTotalHeatMap/>
                        <LastUpdateHeatMap/>
                    </MetricsContainer>
                </MetricsLayout>
            </MoreContent>

            <ConverterСontrolLayout label="resources.Converter.fields.control_cadence"/>

            <CardShowLayout>
                {ability.can('getOne', 'Converter', 'login') && <TextField source="login" />}
                {ability.can('getOne', 'Converter', 'url') && <TextField source="url" />}
                {ability.can('getOne', 'Converter', 'prometheusJob') && <TextField source="prometheusJob" />}
                {ability.can('getOne', 'Converter', 'meta') && <JsonField source="meta"/>}
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'Converter', 'id') && <TextField source="id" />}
                    {ability.can('getOne', 'Converter', 'createdAt') && <DateField source="createdAt" />}
                    {ability.can('getOne', 'Converter', 'createdBy') && <TextField source="createdBy.name" label="resources.Converter.fields.createdBy" />}
                    {ability.can('getOne', 'Converter', 'updatedAt') && <DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default ConverterShow;