import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SimpleForm, TextInput, Toolbar, SaveButton, DeleteButton, ReferenceArrayInput, AutocompleteArrayInput, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import RestoreButton from '../Buttons/RestoreButton';
import AppBanner from './AppBanner';
import { ability } from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const AppToolbar = props => {
    const classes = useStyles();
    const isDeleted = props.record.deleted;
    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {!isDeleted && ability.can('update', 'App') && <SaveButton />}
            {isDeleted && ability.can('restore', 'App') && <RestoreButton />}
            <ToolbarSpacer />
            {!isDeleted && ability.can('remove', 'App') && <RemoveButton redirect="show" />}
            {isDeleted && ability.can('delete', 'App') && <DeleteButton undoable icon={<DeleteForeverIcon />} />}
        </Toolbar>
    );
}

const AppEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <AppBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<AppToolbar />}>
                    <TextInput source="name" validate={required()} fullWidth />
                    <TextInput source="description" multiline fullWidth />
                    {ability.can('update', 'App', 'url') && <TextInput source="url" validate={required()} fullWidth />}
                    {ability.can('update', 'App', 'avatar') && <TextInput source="avatar" fullWidth />}
                    {ability.can('update', 'App', 'users') && <ReferenceArrayInput source="usersIds" reference="User" label="resources.App.fields.users" fullWidth>
                        <AutocompleteArrayInput />
                    </ReferenceArrayInput>}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default AppEdit