import decodeJwt from 'jwt-decode';
import { AUTH_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE, DOMAIN_COOKIE } from '../appsettings';
import { getCookie, setCookie, deleteCookie } from '../utils';
import tokenRefresh from './tokenRefresh';



export const getAccessToken = async () => {
    let authTokenCookie = getCookie(AUTH_TOKEN_COOKIE);
    let refreshTokenCookie = getCookie(REFRESH_TOKEN_COOKIE);
    if(tokenExpired(authTokenCookie) && !tokenExpired(refreshTokenCookie, 'refresh')) {

        try {
            const { data: {refresh: {token, refreshToken}}} = await tokenRefresh(refreshTokenCookie);
                                 
            setCookie(AUTH_TOKEN_COOKIE, token, {domain: DOMAIN_COOKIE});
            setCookie(REFRESH_TOKEN_COOKIE, refreshToken, {domain: DOMAIN_COOKIE});
                    
            authTokenCookie = token;

        } catch (error) {           
            deleteCookie(AUTH_TOKEN_COOKIE, {domain: DOMAIN_COOKIE});
            deleteCookie(REFRESH_TOKEN_COOKIE, {domain: DOMAIN_COOKIE}); 
            throw new Error(`${error.message.replace('Error: ', '').replace('GraphQL error: ', '')}`) 
        }
    }
    
    return authTokenCookie;
};

export const tokenExpired = (token, arg) => {
    if(token) {
        const decodedToken = decodeJwt(token);
        const current_time = Date.now() / 1000;                
        const result = (decodedToken.exp - 20) < current_time; 
        return result;
    } else {     
        return true;
    }
};

