import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PrometheusClient from "../../../dataProvider/Prometheus/PrometheusClient";
import { Title, useTranslate } from 'react-admin';
import {i18nProvider} from "../../../i18n/i18nProvider";
import HeatMapChartJs from 'chartjs-chart-treemap/dist/chartjs-chart-treemap'
import ChartJs from 'chart.js';
import Color from 'color'

const useStyles = makeStyles(theme => ({
    chartbox: {
        overflow: "",
        // padding: "24px",
        height: "600px"
    },
    title: {
        // height: "40px",
        padding: "16px 24px 8px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        // borderBottom: "solid 1px #c3c3c2",
    }
}));

const defaultConverter = (metrics) => {
    if (!metrics) { return null }
    const result = [];
    let maxValueIndex = 0;
    metrics = metrics.filter(metric => parseInt(metric.value[1]) >= 0)
    const sum = metrics.reduce((prev,metric, index) => {
        const value = parseInt(metric.value[1]);
        if (value > metrics[maxValueIndex].value[1]) maxValueIndex = index;
        result.push({dbId:metric.metric.dbid, value: value})
        return prev+value;
    }, 0);
    return {result: result, max: maxValueIndex, avg: sum/metrics.length};
};

// const defaultConverter = (metrics) => {
//     if (!metrics) { return null }
//     const result = [['DBid', null, null, null]];
//     metrics.forEach(metric => {
//        result.push([metric.metric.dbid,'DBid',parseFloat(metric.value[1]), 5-parseFloat(metric.value[1])/2] )
//     });
//     return result;
// };

let red = 244;
let green = 64;
let mounted = 0;
let maxValueIndex = 0;
const HeatMapChart = ({classes: classesOverride, className, converter = defaultConverter, refresh, redFunc,...props}) => {
    const classes = useStyles({ classes: classesOverride });
    const [metrics, setMetrics] = useState([]);
    const translate = useTranslate();
    let chart = null
    const useRefresh = useSelector(state => state.admin.ui.viewVersion, shallowEqual());
    useEffect( () => {
        chart = null
        chart = new PrometheusClient(props.job).getArrayOfMetrics([props.metric]).then(metric => {
            if (!metric) { return }
            const converted = converter(metric[0])
            maxValueIndex = converted.max;
            if (converted.result.length) {
                new ChartJs(document.getElementById( props.id + 'Chart').getContext("2d"), {
                    type: 'treemap',
                    data: {

                        datasets: [{
                            label: ['value'],
                            key: 'value',
                            groups: ['dbId'],
                            tree: converted.result,
                            spacing: -0.5,
                            borderWidth: 0.5,
                            borderColor: "rgba(200,200,200,1)",
                            fontColor: "black",

                            hoverBackgroundColor: "none",
                            backgroundColor: (ctx) => {
                                const value = ctx.dataset.data[ctx.dataIndex];
                                if (!value || !value.v) return Color({r: 244, g: 75, b: 54});
                                if (redFunc) {
                                    return redFunc(value.v) ? Color({r: 244, g: 75, b: 54}).alpha(1):
                                        Color({r: 76, g: 175, b: 54}).alpha(1);
                                }
                                const { result, max, avg } = converted;
                                if (value.v / avg >= 2) {
                                    return Color({r: 244, g: 75, b: 54}).alpha(1);}
                                if (value.v / avg >= 1) return Color({r: 244, g: 175, b: 54}).alpha(1);
                                else return Color({r: 76, g: 175, b: 54}).alpha(1);
                                let r = Math.max(Math.min(value.h * value.w / 3000 * 244, 244),76) || 244;
                                if (r > 100) r = 244;
                                let g = Math.min(Math.max((1 - value.h * value.w / 3000) * 255, 64), 175) || 64;
                                if (g > 100) g = 175;
                                const b = 54;

                                const alpha = (value + 3) / 10;

                                // if (parseInt(value.v) < -1) {
                                //     return Color({r: 76, g: 175, b: 54}).alpha(1);
                                // }
                                return Color({r: r, g: g, b: b}).alpha(1);
                            },
                        }],

                    },
                    options: {
                        maintainAspectRatio: false,
                        // chart: {
                        //     hover: evt => {
                        //         console.log(evt);
                        //         return
                        //         evt.stopImmediatePropagation()},
                        // },
                        // onHover: evt => {
                        //     console.log(evt);
                        //     return
                        //     evt.stopImmediatePropagation()},
                        title: {
                            display: false,
                            text: "Switchable key and groups"
                        },
                        legend: {
                            display: false,
                            backgroundColor: "rgba(255,0,0,1)"
                        },

                        tooltips: {
                            callbacks: {
                                title: function (item, data) {
                                    return data.datasets[item[0].datasetIndex].key;
                                },
                                label: function (item, data) {
                                    const dataset = data.datasets[item.datasetIndex];
                                    const dataItem = dataset.data[item.index];
                                    const obj = dataItem._data;
                                    // console.log(obj)
                                    const label = obj.dbId;
                                    return label+ '(dbid)' + ': ' + dataItem.v;
                                },

                            },
                            backgroundColor: "rgba(0,0,0,0.7)",
                        },
                        elements: {
                            rectangle: {
                                borderSkipped: true,
                                borderWidth: 0,
                                spacing: 0.5
                            }
                        }
                    },
                })
            }
            // setMetrics(converted.result);
        }).catch(error => setMetrics([]));
        setMetrics([])
    }, [useRefresh]);
    const { record } = props;
    mounted++;


    return (<canvas id={ props.id + 'Chart'} height={400}/>)
    // return !!metrics.length && (
    //     <Card >
    //         <div className={classes.title}>
    //             <Typography  variant={"h5"} style={{zIndex: 1000000000,}}>{translate(props.title)}</Typography>
    //             {props.subtitle&&<Typography  variant={"subtitle1"}>{translate(props.subtitle)}</Typography>}
    //         </div>
    //         <CardContent className={classes.chartbox}>
    //             <Chart
    //                 width={'100%'}
    //                 height={'100%'}
    //                 chartType="TreeMap"
    //                 loader={<div>Loading Chart</div>}
    //                 data={metrics}
    //                 options={{
    //                     legend: {
    //
    //                         ...props.legend
    //                     },
    //                     titlePosition: 'in',
    //                     // ticks: [array[0][0], array[Math.floor(array.length/2)][0], array[array.length-1][0]]
    //                     hAxis: { title: props.headers[0] || '', titleTextStyle: { color: '#333' }, textStyle: {
    //                             color: "grey",
    //                             fontSize: 12,
    //                             fontName: "Roboto"
    //                         },}, //ticks: [metrics[1][0],metrics[Math.floor(metrics.length/6)][0],metrics[Math.floor(metrics.length*2/6)][0],metrics[Math.floor(metrics.length*3/6)][0],metrics[Math.floor(metrics.length*4/6)][0],metrics[Math.floor(metrics.length*5/6)][0],metrics[metrics.length - 1][0],]},
    //                     vAxis: { minValue: 0, textStyle: {
    //                             color: "grey",
    //                             fontSize: 12,
    //                             fontName: "Roboto"
    //                         }, },                    // For the legend to fit, we make the chart area smaller
    //                     chartArea: { width: '90%', height: '76%', zIndex: "1" },
    //                     width: "100%",
    //                     lineWidth: 1,
    //                     midColor: "orange",
    //                     minColor: "red",
    //                     noColor: "white",
    //                     maxDepth: 0,
    //                     showTooltips:true,
    //
    //                 }}
    //                 // For tests
    //                 rootProps={{ 'data-testid': '3' }}
    //             />
    //         </CardContent>
    //     </Card>
    // )
};

HeatMapChart.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    metric: PropTypes.array.isRequired,
    converter: PropTypes.func,
    legend: PropTypes.object
};

export default HeatMapChart;




// const HeatMapChart = ({classes: classesOverride, className, converter = defaultConverter, refresh,...props}) => {
//     const classes = useStyles({ classes: classesOverride });
//     const [metrics, setMetrics] = useState([]);
//     const translate = useTranslate();
//     // const useRefresh = useSelector(state => state.admin.ui.viewVersion, shallowEqual());
//     useEffect( () => {
//         new PrometheusClient(props.job).getArrayOfMetrics([props.metric]).then(metric => {
//             if (!metric) { return }
//             const converted = converter(metric[0])
//             maxValueIndex = converted.max;
//             setMetrics(converted.result);
//         }).catch(error => setMetrics([]));
//         setMetrics([])
//     }, [setMetrics]);
//     const { record } = props;
//     if (metrics.length) {
//         new ChartJs(document.getElementById( props.job + 'Chart').getContext("2d"), {
//             type: 'treemap',
//             data: {
//
//                 datasets: [{
//                     label: ['value'],
//                     key: 'value',
//                     groups: ['dbId'],
//                     tree: metrics,
//                     spacing: -0.5,
//                     borderWidth: 0.5,
//                     borderColor: "rgba(200,200,200,1)",
//                     fontColor: "black",
//
//                     hoverBackgroundColor: "rgba(220,230,220,0.5)",
//                     backgroundColor: (ctx) => {
//                         const value = ctx.dataset.data[ctx.dataIndex];
//                         if (!value) return Color({r: 255, g: 0, b: 0});
//                         const r = value.h * value.w / 1500 * 255;
//                         const g = (1 - value.h * value.w / 1500) * 255;
//                         const b = 30;
//                         const alpha = (value + 3) / 10;
//                         return Color({r: r, g: g, b: b}).alpha(1);
//                     },
//                 }],
//
//             },
//             options: {
//                 maintainAspectRatio: false,
//                 // chart: {
//                 //     hover: null
//                 // },
//                 title: {
//                     display: false,
//                     text: "Switchable key and groups"
//                 },
//                 legend: {
//                     display: false,
//                     backgroundColor: "rgba(255,0,0,1)"
//                 },
//                 tooltips: {
//                     callbacks: {
//                         title: function (item, data) {
//                             return data.datasets[item[0].datasetIndex].key;
//                         },
//                         label: function (item, data) {
//                             const dataset = data.datasets[item.datasetIndex];
//                             const dataItem = dataset.data[item.index];
//                             const obj = dataItem._data;
//                             // console.log(obj)
//                             const label = obj.dbId;
//                             return label+ '(dbid)' + ': ' + dataItem.v;
//                         }
//                     }
//                 },
//                 elements: {
//                     rectangle: {
//                         borderSkipped: true,
//                         borderWidth: 0,
//                         spacing: 0.5
//                     }
//                 }
//             },
//         })
//     }
//
//     return (<canvas id={ props.job + 'Chart'} height={400}/>)