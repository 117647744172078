import React, { useState, cloneElement, useMemo } from 'react';
import { ability, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListController,
    ListView,
    DateField,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    TextInput,
    BulkExportButton,
    useTranslate,
    NullableBooleanInput,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    sanitizeListRestProps,
    ListContext,
} from 'react-admin';
import EnumField from '../Fields/EnumField';
import PhoneField from '../Fields/PhoneField';
import OnlineBadge from '../Fields/OnlineBadge';
import AvatarField from '../Fields/AvatarField';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import EnumInput from '../Inputs/EnumInput';
import { converter, exporter } from "../../utils";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const UserBulkActionButtons = props => (
    <>
        {ability.can('remove', 'User') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'User') && <BulkExportButton {...props} />}
    </>
);

const filterRoles = (value) => {
    const { role_in, role } = rulesToFields("getList", "User");
    return role_in ? role_in.includes(value.name) : true;
}

const UserFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <EnumInput source="role" Enum="UserRole" filter={filterRoles} emptyText="ra.message.no" />
        <NullableBooleanInput source="banned" style={{ width: 150 }} />
    </Filter>
);



export const DefaultDatagrid = (props) => {
    const classes = useStyles();

    return (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            <OnlineBadge>
                <AvatarField name="name" />
            </OnlineBadge>
            <TextField source="name" />
            <TextField source="description" />
            {ability.can('getList', 'User', 'role') && <EnumField source="role" />}
            {ability.can('getList', 'User', 'login') && <TextField source="login" />}
            {ability.can('getList', 'User', 'phone') && <PhoneField source="phone" />}
            {ability.can('getList', 'User', 'email') && <TextField source="email" />}
            <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
            <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {ability.can('getOne', 'User') && <ShowBlankItem />}
                {ability.can('update', 'User') && <EditMenuItem />}
                {ability.can('remove', 'User') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    );
}

const UserTabs = ({ filterValues, setFilters, ...props }) => {
    const translate = useTranslate();
    const [tab, setTab] = useState("staff");

    const handleChange = (event, value) => {
        if (value === "staff") setFilters({ ...filterValues, role_in: ["DISPATCHER", "MANAGER", "EMPLOYEE"] });
        if (value === "system") setFilters({ ...filterValues, role_in: ["SUPERADMIN", "ADMIN", "SERVICE"] });
        setTab(value);
    };


    return (
        <>
            <Tabs value={tab} onChange={handleChange} centered >
                <Tab label={translate('resources.User.enums.tabs.staff')} value="staff" />
                <Tab label={translate('resources.User.enums.tabs.system')} value="system" />
            </Tabs>
            <Divider />
            {(tab === "staff") && <DefaultDatagrid {...props} />}
            {(tab === "system") && <DefaultDatagrid {...props} />}

        </>
    )
};

const convertTable = {
    id: 'ID',
    name: 'Наименование',
    description: 'Описание',
    createdAt: 'Создан',
    createdBy: 'Создал',
    updatedAt: 'Обновлен',
    email: 'Email',
    phone: 'Телефон',
    role: 'Роль',
    regions: 'Регионы',
    password: 'Пароль',
    counterparty: "Контрагент"
};

const UserList = props => {
    const classes = useStyles();
    const controllerProps = useListController(props);
    return (
        <ListContext.Provider value={useListController(props)}>
            <ListView
                filters={<UserFilter />}
                bulkActionButtons={<UserBulkActionButtons />}
                {...props}
                {...controllerProps}
                exporter={exporter(controllerProps.defaultTitle, converter(convertTable))}
            >
                <DefaultDatagrid />
            </ListView>
        </ListContext.Provider>
    )
};

UserList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};


export default UserList;