import Icon from '../Icons/FillDataGapsService';
import FillDataGapsServiceList from './FillDataGapsServiceList';
import FillDataGapsServiceCreate from './FillDataGapsServiceCreate';
import FillDataGapsServiceEdit from './FillDataGapsServiceEdit';
import FillDataGapsServiceShow from './FillDataGapsServiceShow';

const FillDataGapsServiceIcon = Icon;


export {
    FillDataGapsServiceIcon,
    FillDataGapsServiceList,
    FillDataGapsServiceShow,
    FillDataGapsServiceCreate,
    FillDataGapsServiceEdit,
};
