import dataProviderCore from './Core/graphqlProvider'

const dataProviders = [ dataProviderCore ];

export const dataProviderFactory = () => {
  return Promise.resolve((type, resource, params) => {    

    const findDataProvider = dataProviders.find(dp => dp.knownResources.includes(resource));   

    if (!findDataProvider) throw new Error('Provider is not loaded')
    return findDataProvider.dataProvider(type, resource, params);    
  });
};