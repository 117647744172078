import React, { cloneElement, Children, isValidElement } from 'react';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslate} from 'react-admin';


const sanitizeGridItemProps = ({
  lg,
  md,
  sm,
  xs,
  zeroMinWidth,
  ...props
}) => props;



const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 256,
  }
}));

const GridBlankLayout = ({children, classes: classesOverride, record, source, className, label, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();


    return (
      <Grid container spacing={2}>
      {Children.map(children, (field, index) => {
        
        const {
          lg,
          md,
          sm,
          xs,
          zeroMinWidth,
          className,
          ...inputProps
        } = field.props;

        const gridItemProps = {
          lg,
          md,
          sm,
          xs,
          zeroMinWidth
        };
        
        return isValidElement(field) ? (
          <Grid item key={field.props.source || index} {...gridItemProps} >
            {React.cloneElement(field, {
              ...inputProps,
              className: classes.root,
              fullWidth: true,
            })}
          </Grid>
      ) : null

      }
          
      )}
      </Grid>
    );
};

export default GridBlankLayout;