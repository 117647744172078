import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "space-between"
    },
    field: {
        width: "32px",
        textAlign: 'center',
        padding: 0
    },
    button: {
        padding: 0,
        marginRight: "8px"
    }
}));



const PeriodBar = ({ period, setPeriod, periods, className}) => {

    const classes = useStyles();
    
    const handleClick = (event) => {
        setPeriod(event.currentTarget.value)
    }

    return (
        <div className={`${classes.root} ${className}`}>
            {
                Object.keys(periods).map(key => {
                    const value = periods[key];
                    return (
                        <Button
                            key={value}
                            size={"small"}
                            variant={value === period ? "contained" : "text"}
                            disableElevation
                            value={value}
                            onClick={handleClick}
                        >
                            {key}
                        </Button>
                    );
                })
            }
        </div>
    )
};

PeriodBar.defaultProps = {
    periods: {
        ["1 час"]: "1h",
        ["6 часов"]: "6h",
        ["12 часов"]: "12h",
        ["Сутки"]: "24h"
    },
}


export default PeriodBar;