import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'react-admin';
import CronGuageChart from "../Metrics/CronGuageChart";
import AverageExecuteTimeChart from "../Metrics/AverageExecuteTimeChart";
import LastUpdateProgressBar from "../Metrics/ConverterCharts/LastUpdateProgressBar";
import ServerInstances from "../Metrics/SystemCounters/ServerInstances";
import MetricsLayout from "../Layout/MetricsLayout";
import MetricsContainer from "../Layout/MetricsContainer";
import { TITLE_APP } from '../../appsettings'


const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 16,
    },
    chartContainer: {
        marginTop: "30px",

    }
}));


const DashBoard = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Title title={TITLE_APP} />
            <MetricsLayout >
                <MetricsContainer xs xl={12} sm={12}>
                    <MetricsContainer xs xl={6} sm={6}>
                        <ServerInstances />
                        <LastUpdateProgressBar />
                    </MetricsContainer>
                    <MetricsContainer xs xl={6} sm={6}>
                        <AverageExecuteTimeChart />
                        <CronGuageChart />
                    </MetricsContainer>
                </MetricsContainer>
            </MetricsLayout>
        </div>

    );
};

export default DashBoard;