
import React, { useState, useCallback, cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, Button, Title } from 'react-admin';
import Dropzone from '../Layout/Dropzone';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import { URI_IMPORTEXPORT } from '../../appsettings'
import { getAccessToken } from '../../authPrivider/getAccessToken'

import validateXls from './ValidateXls'

const useStyles = makeStyles(theme => ({
    root: {
      marginTop: 20
    },
    title: {
        fontSize: 14,
    },
    dropzone: {
        margin: 20
    },
    blockItem: {   
        marginTop: theme.spacing(2), 
        marginBottom: theme.spacing(2),
        flexGrow: 1,      
        display: "flex",   
    },
    runButton: {
        height: 47,
        paddingRight: theme.spacing(2),
        marginRight: theme.spacing(2) 
    },
    runStatus : {
        marginLeft: theme.spacing(2),   
        flexGrow: 1
    },
    validateStatus : {
        margin: 20 
    }
}));


const ImportFromAisShow = props => {
    
    const translate = useTranslate(); 
    const classes = useStyles();

    const [statusProgress, setStatusProgress] = React.useState(null);
    const [statusValidate, setStatusValidate] = React.useState(null);
    let isProgress = statusProgress != null && statusProgress.st == 'info';

    const [data, setData] = useState(null);

    const getStatusUpload = (data) => {                                          
        setTimeout(async() => {                  
            const token = await getAccessToken();                                             
            const params = new URLSearchParams(data).toString();
            fetch(`${URI_IMPORTEXPORT}/import/objects/status?`+params, { headers: { 'authorization': 'Bearer '+token, 'Content-Type': 'application/json' }})
            .then(response => {              
                if(response.ok) {
                    response.json().then(json_res => {                          
                        json_res.events.forEach(ev => {
                            console.log(ev.event);
                        });
                        if(json_res.id) { 
                            delete json_res.events; 
                            getStatusUpload(json_res);
                        } else {
                            setStatusProgress({ mes: 'Файл синхронизирован', st:'success'});
                        }                                                                        
                    }); 
                } else {
                   
                    response.json().then(json_res => {
                        setStatusProgress({ mes: json_res.error, st:'error'}); 
                    });
                }                                              
            });                  
        }, 2000);
    }

    const handleRunProcess = async() => {       
        setStatusProgress({ mes: 'Загрузка файла на сервер', st:'info'});
        const formData = new FormData();
        formData.append('importfiletoupload', data);        
        const token = await getAccessToken();       
        //alert(token);       
        fetch(`${URI_IMPORTEXPORT}/import/objects/upload`, {
            headers: { 'authorization': 'Bearer '+token },
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(json_res => {                      
            setStatusProgress({ mes: 'Синхронизация данных из файла ...', st:'info'});
            getStatusUpload(json_res);                      
        }).catch(er => {
            setStatusProgress({ mes: 'Сервис синхронизации не доступен', st:'error'});
        });
    }
     
    const handleDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {                  
                validateXls(new Uint8Array(e.target.result)).then((result) => {
                    setStatusValidate(result);
                    if(result.validate_errors.length == 0) {
                        setData(file);
                    }                   
                });                                                            
            }

            reader.readAsArrayBuffer(file);
        })
    }, []);

    const handleClear = () => {
        setData(null); 
        setStatusProgress(null);
        setStatusValidate(null);
    }

    return (
        <Card className={classes.root}>
            <Title title={translate('resources.ImportFromAis.name')} />
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                   {translate('resources.ImportFromAis.description')}                   
                </Typography> 
                <Dropzone onDrop={handleDrop} onClear={handleClear} disabled={isProgress} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                {statusValidate && statusValidate.validate_errors.length > 0 ? <Alert severity="error" className={classes.validateStatus}><div>Ошибки:</div><ul>{(statusValidate.validate_errors.map(x => <li>{x}</li>))}</ul></Alert> : null}
                {statusValidate && statusValidate.validate_alert.length > 0 ? <Alert severity="warning" className={classes.validateStatus}><div>Предупреждения:</div><ul>{(statusValidate.validate_alert.map(x => <li>{x}</li>))}</ul></Alert> : null}
                <div className={classes.blockItem}>
                    <Button 
                        onClick={handleRunProcess}
                        disabled={isProgress || data == null}
                        variant="contained" 
                        color="primary" 
                        className={classes.runButton}
                        label={translate('resources.ImportFromAis.button.run')}>
                    </Button>        
                    {isProgress ? <CircularProgress variant="indeterminate" thickness={7} size={47} /> : null }
                    {statusProgress ? <Alert variant="filled" className={classes.runStatus} severity={statusProgress.st}>{statusProgress.mes}</Alert> : null}
                </div>                   
            </CardContent>           
        </Card> 
        
        // <ShowView         
        //     title="67867868"
        //     {...props}

        // >
        //      
        // </ShowView>
             
    )
};

export default ImportFromAisShow;