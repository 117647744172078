import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import HeatMapChartJs from 'chartjs-chart-treemap/dist/chartjs-chart-treemap'
import ChartJs from 'chart.js';
import Color from 'color'
import useQuery from "../../../dataProvider/Prometheus/useQuery";
import Loading from "../Loading";
import Typography from '@material-ui/core/Typography'
import BlockIcon from '@material-ui/icons/Block';
import { URI_PROMETHEUS } from "../../../appsettings";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    chartbox: {
        overflow: "",
        // padding: "24px",
        height: "600px"
    },
    title: {
        // height: "40px",
        padding: "16px 24px 8px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        // borderBottom: "solid 1px #c3c3c2",
    }
}));

const converter = (metrics) => {
    if (!metrics) { return null }
    const result = [];
    let allZero = true;
    let maxValueIndex = 0;
    const sum = metrics.reduce((prev, metric, index) => {
        const value = parseInt(metric.value[1]);
        if (value > metrics[maxValueIndex].value[1]) maxValueIndex = index;
        if (value > 0) allZero = false
        result.push({ dbID: metric.metric.dbID, value: value })
        return prev + value;
    }, 0);
    return { result: result, max: maxValueIndex, avg: sum / metrics.length, isAllValuesEqualZero: allZero };
};

let red = 244;
let green = 64;
let mounted = 0;
let maxValueIndex = 0;
let chart = null;
const ErrorsPerCorrectorHeatMap = ({ classes: classesOverride, className, job, date, ...props }) => {
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    const time = moment(date)
    const { data, loading, error } = useQuery({
        query: [`delta(askug_dcs_errors_per_corrector{job="${job}"}[1d])&time=${time.unix()}`],
        uri: URI_PROMETHEUS
    });

    useEffect(() => {
        if (chart) {
            chart.destroy();
            chart = null;
        }
        if (!data) return;
        const converted = converter(data[0]);
        maxValueIndex = converted.max;
        if (converted.result.length) {
            chart = new ChartJs(document.getElementById(props.id + 'Chart').getContext("2d"), {
                type: 'treemap',
                data: {

                    datasets: [{
                        label: ['value'],
                        key: 'value',
                        groups: ['dbID'],
                        tree: [...converted.result, { dbID: '', value: 0 }],
                        spacing: -0.5,
                        borderWidth: 0.5,
                        borderColor: "rgba(200,200,200,1)",
                        fontColor: "black",

                        hoverBackgroundColor: "none",
                        backgroundColor: (ctx) => {
                            const value = ctx.dataset.data[ctx.dataIndex];

                            if (!value || !value.v) return Color({ r: 244, g: 75, b: 54 });
                            return value.v > 100 ?
                                Color({ r: 244, g: 75, b: 54 }).alpha(1) :
                                value.v > 0 ?
                                    Color({ r: 244, g: 175, b: 54 }).alpha(1) :
                                    Color({ r: 76, g: 175, b: 54 }).alpha(1);
                        },
                    }],

                },
                options: {
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                        text: "Switchable key and groups"
                    },
                    legend: {
                        display: false,
                        backgroundColor: "rgba(255,0,0,1)"
                    },

                    tooltips: {
                        callbacks: {
                            title: function (item, data) {
                                return data.datasets[item[0].datasetIndex].key;
                            },
                            label: function (item, data) {
                                const dataset = data.datasets[item.datasetIndex];
                                const dataItem = dataset.data[item.index];
                                const obj = dataItem._data;
                                const label = obj.dbID;
                                return label + '(dbid)' + ': ' + dataItem.v;
                            },

                        },
                        backgroundColor: "rgba(0,0,0,0.7)",
                    },
                    elements: {
                        rectangle: {
                            borderSkipped: true,
                            borderWidth: 0,
                            spacing: 0.5
                        }
                    }
                },
            })
        }
    }, [data]);
    if (loading) return (<Loading />);
    if (error) return error;
    const { record } = props;
    mounted++;
    return (<canvas id={props.id + 'Chart'} height={400} />)
};

ErrorsPerCorrectorHeatMap.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    converter: PropTypes.func,
    legend: PropTypes.object
};

export default ErrorsPerCorrectorHeatMap;
