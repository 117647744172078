import { buildQuery } from 'ra-data-graphql-simple';
import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import { GET_LIST_APP, GET_MANY_APPS, GET_ONE_APP, UPDATE_APP, DELETE_APP } from './typeDefs';
import { GET_LIST_FILL_DATA_GAPS_SERVICE, GET_MANY_FILL_DATA_GAPS_SERVICES, GET_ONE_FILL_DATA_GAPS_SERVICE, UPDATE_FILL_DATA_GAPS_SERVICE, DELETE_FILL_DATA_GAPS_SERVICE } from './typeDefs';
import { GET_LIST_FILE_PARSER_SERVICE, GET_MANY_FILE_PARSER_SERVICES, GET_ONE_FILE_PARSER_SERVICE, UPDATE_FILE_PARSER_SERVICE, DELETE_FILE_PARSER_SERVICE } from './typeDefs';
import { GET_LIST_DATA_BUS, GET_MANY_DATA_BUSES, GET_ONE_DATA_BUS, UPDATE_DATA_BUS, DELETE_DATA_BUS } from './typeDefs';
import { GET_LIST_OPC_SERVER, GET_MANY_OPC_SERVERS, GET_ONE_OPC_SERVER, UPDATE_OPC_SERVER, DELETE_OPC_SERVER } from './typeDefs';
import { GET_LIST_ANALITICS_SERVICE, GET_MANY_ANALITICS_SERVICES, GET_ONE_ANALITICS_SERVICE, UPDATE_ANALITICS_SERVICE, DELETE_ANALITICS_SERVICE } from './typeDefs';
import { GET_LIST_CONVERTER, GET_MANY_CONVERTERS, GET_ONE_CONVERTER, UPDATE_CONVERTER, DELETE_CONVERTER } from './typeDefs';
import { GET_LIST_CALL_SERVICE, GET_MANY_CALL_SERVICES, GET_ONE_CALL_SERVICE, UPDATE_CALL_SERVICE, DELETE_CALL_SERVICE } from './typeDefs';
import { GET_LIST_COUNTERPARTY, GET_MANY_COUNTERPARTIES, GET_ONE_COUNTERPARTY, UPDATE_COUNTERPARTY, DELETE_COUNTERPARTY } from './typeDefs';
import { GET_LIST_USER, GET_MANY_USERS, GET_ONE_USER, UPDATE_USER, DELETE_USER } from './typeDefs';
import { UPDATE_PROFILE } from './typeDefs';
import { GET_LIST_HISTORY, GET_MANY_HISTORIES, GET_ONE_HISTORY } from './typeDefs';
import { GET_LIST_TRASHITEM, GET_MANY_TRASHITEMS, GET_ONE_TRASHITEM } from './typeDefs';
import { GET_ONE_DATABUS_TO_XML_SERVICE, GET_LIST_DATABUS_TO_XML_SERVICE, GET_MANY_DATABUS_TO_XML_SERVICE, UPDATE_DATABUS_TO_XML_SERVICE } from './typeDefs';


const customBuildQuery = introspection => (fetchType, resource, params) => {

    const { id, data, previousData } = params;

    if (resource === 'Counterparty') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_COUNTERPARTY,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_COUNTERPARTY,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_COUNTERPARTIES,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_COUNTERPARTY,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_COUNTERPARTY,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'User') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_USER,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_USER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_USERS,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_USER,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_USER,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Profile') {
        switch (fetchType) {
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_PROFILE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            default: break;
        }
    };


    if (resource === 'App') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_APP,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_APP,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_APPS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_APP,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_APP,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'FileParserService') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_FILE_PARSER_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_FILE_PARSER_SERVICE,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_FILE_PARSER_SERVICES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_FILE_PARSER_SERVICE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_FILE_PARSER_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'FillDataGapsService') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_FILL_DATA_GAPS_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_FILL_DATA_GAPS_SERVICE,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_FILL_DATA_GAPS_SERVICES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_FILL_DATA_GAPS_SERVICE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_FILL_DATA_GAPS_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'DataBusToXmlService') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_DATABUS_TO_XML_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_DATABUS_TO_XML_SERVICE,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_DATABUS_TO_XML_SERVICE,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_DATABUS_TO_XML_SERVICE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_FILL_DATA_GAPS_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'AnalyticsService') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_ANALITICS_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_ANALITICS_SERVICE,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_ANALITICS_SERVICES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_ANALITICS_SERVICE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_ANALITICS_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'DataBus') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_DATA_BUS,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_DATA_BUS,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_DATA_BUSES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_DATA_BUS,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_DATA_BUS,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'CallService') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_CALL_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_CALL_SERVICE,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_CALL_SERVICES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_CALL_SERVICE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_CALL_SERVICE,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Converter') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_CONVERTER,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_CONVERTER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_CONVERTERS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_CONVERTER,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_CONVERTER,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'OpcServer') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_OPC_SERVER,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_OPC_SERVER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_OPC_SERVERS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_OPC_SERVER,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_OPC_SERVER,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'History') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_HISTORY,
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_HISTORY,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_HISTORIES,
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'TrashItem') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_TRASHITEM,
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_TRASHITEM,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_TRASHITEMS,
                };
                break;
            }
            default: break;
        }
    };

    const builtQuery = buildQuery(introspection)(fetchType, resource, params);

    return builtQuery;
};

export default customBuildQuery;