import {useEffect, useState} from 'react';
import {useVersion} from 'react-admin';
import {getAccessToken} from "../../authPrivider/getAccessToken";

const UpdateConfigFillDataGapsService = (url) => {
    const fetchData = async () => {
        const token = await getAccessToken();
        return fetch(url + '/api/v1.0/update_config', {
            method: 'POST',
            credentials: 'omit',
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response
                } else {
                    throw new Error(`Request ended with status: ${response.status}`)
                }
            })
    };
    return fetchData();
};

export default UpdateConfigFillDataGapsService;