import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
function Icon(props) {
        return (
                <SvgIcon {...props}>
                        <path d="M5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3ZM7 7V9H9V7H7ZM11 7V9H13V7H11ZM15 7V9H17V7H15ZM7 11V13H9V11H7ZM11 11V13H13V11H11ZM15 11V13H17V11H15ZM7 15V17H9V15H7ZM11 15V17H13V15H11ZM15 15V17H17V15H15Z" />
                </SvgIcon>
        );
}

export default Icon;