import AssessmentIcon from '@material-ui/icons/Assessment';
import AnalyticsServiceList from './AnalyticsServiceList';
import AnalyticsServiceCreate from './AnalyticsServiceCreate';
import AnalyticsServiceEdit from './AnalyticsServiceEdit';
import AnalyticsServiceShow from './AnalyticsServiceShow';

const AnalyticsServiceIcon = AssessmentIcon;


export {
    AnalyticsServiceIcon,
    AnalyticsServiceList,
    AnalyticsServiceShow,
    AnalyticsServiceCreate,
    AnalyticsServiceEdit,
};
