import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
function Icon(props) {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M10 2H14V6H13V9.54145L17.0263 11.866L12.0263 14.7528L7.06217 11.8868L11 9.61325V6H10V2ZM6 5H2V9H3V16V18H5H6V19.5L11.0622 22.4226V16.5056L6 13.5829V16H5V9H6V5ZM19 9H18V5H22V9H21V16V18H19H18.1244V19.5L13.0622 22.4226V16.4641L18.1244 13.5415V16H19V9ZM5 6H3V8H5V6ZM11 3H13V5H11V3ZM21 6H19V8H21V6Z" />
        </SvgIcon>
    );
}

export default Icon;