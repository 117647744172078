import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useShowController, BooleanField, ChipField, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import EnumField from '../Fields/EnumField';
import JsonField from '../Fields/JsonField';
import LinkField from './LinkField';
import {ability} from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.link.main,
    },
}));

const HistoryShow = props => {
    const controllerProps = useShowController(props);
    const classes = useStyles();
    const showMoreButton = React.useRef(null);
    return (
        <ShowView 
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            <CardShowLayout>
                <EnumField source="action" noWrap/>
                <EnumField source="type" />
                <LinkField source="name" />
                <TextField source="description" />
                <JsonField source="data.data" />
                <JsonField source="data.previous" />
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    <TextField source="id" />
                    <DateField source="createdAt" />
                    <TextField source="createdBy.name" label="resources.History.fields.createdBy" />
                </CardShowLayout>
            </MoreContent>
            
        </ShowView>
    );
};

export default HistoryShow;