import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import StorageIcon from '@material-ui/icons/Storage';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useTranslate } from 'react-admin';



const useStyles = makeStyles(theme => ({
    card: {
        marginTop: 16,
        marginBottom: 8
    },
    cardHeader: {
        alignItems: "flex-start",
    },
    cardContent: {
        paddingTop: 0,
        height: 128,
    },
    avatar: {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.24),
    },
    metricBox: {
        marginTop: theme.spacing(2),
        '&:first-child': {
            marginTop: 0,
        },
    },
    linearProgress: {
        height: 8,
        borderRadius: 4,
    },
    bar: {
        borderRadius: 4,
    },
}));



const ServerInstance = ({instance, cpuLoad, ramLoad, diskLoad, ...props}) => {    
    const classes = useStyles();
    const translate = useTranslate();
    const title = translate(`Metrics.ServerInstances.${instance}`)
   
    return (
        <Card classes={{root: classes.card}}>
            <CardHeader
                avatar={
                    <Avatar variant="rounded" aria-label="recipe" className={classes.avatar}>
                        <StorageIcon />
                    </Avatar>
                }
                title={title}
                titleTypographyProps={{variant: "h6"}}
                classes={{root: classes.cardHeader}}
            />
            <CardContent classes={{root: classes.cardContent}}>
                <Box className={classes.metricBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="baseline">
                        <Typography variant="caption" color="textSecondary">CPU</Typography>
                        <Typography variant="caption" color="textSecondary">{`${Math.round(Number(cpuLoad))}%`}</Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={Math.round(Number(cpuLoad))} classes={{root: classes.linearProgress, bar: classes.bar}} />
                </Box>
                <Box className={classes.metricBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="baseline">
                        <Typography variant="caption" color="textSecondary">RAM</Typography>
                        <Typography variant="caption" color="textSecondary">{`${Math.round(Number(ramLoad))}%`}</Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={Math.round(Number(ramLoad))} classes={{root: classes.linearProgress, bar: classes.bar}} />
                </Box>
                <Box className={classes.metricBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="baseline">
                        <Typography variant="caption" color="textSecondary">DISK</Typography>
                        <Typography variant="caption" color="textSecondary">{`${Math.round(Number(diskLoad))}%`}</Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={Math.round(Number(diskLoad))} classes={{root: classes.linearProgress, bar: classes.bar}} />
                </Box>
            </CardContent>
        </Card>
    );
};



export default ServerInstance;