import React, {cloneElement, Children, isValidElement, useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslate, useVersion} from 'react-admin';
import apolloConverterClient from "../../dataProvider/Converter/apolloConverterClient";
import StopCronWorkflowCard from "./StopCronWorkflowCard";
import StartConverterWorkflowCard from "./StartConverterWorkflowCard";
import StartCronWorkflowButton from "./StartCronWorkflowCard";
import Loading from "../Metrics/Loading";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {ability} from "../../abilityProvider";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
}));

const ConverterMetricsLayout = ({children, className, label, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const version = useVersion();
    const { record } = props;
    const client = apolloConverterClient(record.url);
    const {data, loading, error} = client.getWorkFlows();
    if (loading) {
        return (<Loading/>);
    }
    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label, {_:label})}</Typography>}
            <Grid container spacing={2}>
                {ability.can('getOne', 'Converter', 'workFlow') && error && <Grid item xs={6} sm={4} md={3} xl={2}>
                    <Card style={{height: '100%'}}>
                        <CardHeader
                            title={translate('resources.Converter.actions.workFlowsNotAccess')}
                            titleTypographyProps={{variant:"subtitle1"}}/>
                        <CardContent>
                            Error
                        </CardContent>
                    </Card>
                </Grid>}
                {ability.can('getOne', 'Converter', 'workFlow') && data && data.allOpenWorkflows && data.allOpenWorkflows.map((workFlow, index) => {
                    return (
                        <Grid key={index} item xs={6} sm={4} md={3} xl={2}>
                            <StopCronWorkflowCard client={client} record={record}  workflow_id={workFlow.workflowId}/>
                        </Grid>
                    )
                })}
                {ability.can('control', 'Converter') && <Grid item xs={6} sm={4} md={3} xl={2}>
                    <StartCronWorkflowButton client={client} record={record}/>
                </Grid>}
                {ability.can('control', 'Converter') && <Grid item xs={6} sm={4} md={3} xl={2}>
                    <StartConverterWorkflowCard client={client} record={record}/>
                </Grid>}
            </Grid>
        </div>
    );
};

export default ConverterMetricsLayout;