import React from 'react';
import { useShowController, TextField, DateField, ChipField } from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import AppBanner from './AppBanner';
import NestedArrayField from "../Fields/NestedArrayField";
import {ability} from "../../abilityProvider";



const AppShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);

    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            <AppBanner />

            <CardShowLayout>
                {ability.can('getOne', 'App', 'url') && <TextField source="url" />}

                {ability.can('getOne', 'App', 'users') && <NestedArrayField source="users" reference="User" linkType="show" >
                    <ChipField source="name" />
                </NestedArrayField>}
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'App', 'id') && <TextField source="id" />}
                    {ability.can('getOne', 'App', 'createdAt') && <DateField source="createdAt" />}
                    {ability.can('getOne', 'App', 'createdBy') && <TextField source="createdBy.name" label="resources.App.fields.createdBy" />}
                    {ability.can('getOne', 'App', 'updatedAt') && <DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default AppShow;