import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
function Icon(props) {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 4C4 2.89 4.89 2 6 2H14L20 8V9.14116C19.3586 9.04772 18.6847 9 18 9C16.43 9 14.9165 9.25091 13.7217 9.72883C13.1298 9.96558 12.5166 10.2975 12.0187 10.7684C11.5166 11.2433 11 11.9967 11 13V21C11 21.3673 11.0692 21.7011 11.1816 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4ZM18 15C20.7614 15 23 14.1046 23 13C23 11.8954 20.7614 11 18 11C15.2386 11 13 11.8954 13 13C13 14.1046 15.2386 15 18 15ZM23 15C23 16.1046 20.7614 17 18 17C15.2386 17 13 16.1046 13 15V17C13 18.1046 15.2386 19 18 19C20.7614 19 23 18.1046 23 17V15ZM18 21C20.7614 21 23 20.1046 23 19V21C23 22.1046 20.7614 23 18 23C15.2386 23 13 22.1046 13 21V19C13 20.1046 15.2386 21 18 21Z" />
        </SvgIcon>
    );
}

export default Icon;