import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PrometheusClient from "../../../dataProvider/Prometheus/PrometheusClient";
import { Title, useTranslate } from 'react-admin';
import {i18nProvider} from "../../../i18n/i18nProvider";

const useStyles = makeStyles(theme => ({
    chartbox: {
        overflow: "hidden",
        // padding: "24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "400px"
    },
    title: {
        // height: "40px",
        padding: "16px 24px 8px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
        // borderBottom: "solid 1px #c3c3c2",
    }
}));

const defaultConverter = (metrics) => {
    metrics = metrics.filter((value) => value&&value.length);
    if (!metrics.length) { return [] }
    const result =[];

    metrics[0].forEach(metric => {
        result.push([metric.metric.dbid, parseInt(metric.value[1])])
    })
    return result;
}

const BarChart = ({classes: classesOverride, className, converter = defaultConverter, ...props}) => {
    const classes = useStyles({ classes: classesOverride });
    const [metrics, setMetrics] = useState([]);
    const translate = useTranslate();
    const useRefresh = useSelector(state => state.admin.ui.viewVersion, shallowEqual())
    useEffect( () => {
        new PrometheusClient(props.job).getArrayOfMetrics(props.metrics).then(metrics => {
            metrics = metrics.filter(value=>value&&value.length);
            if (!metrics.length) {setMetrics([]); return} else {
                setMetrics([[...props.headers.map(value => translate(value))], ...converter(metrics)]);
            }
        });
        setMetrics([]);
    }, [useRefresh]);
    return !!metrics.length && (
        <Card >
            <div className={classes.title}>
                <Typography  variant={"h5"}>{translate(props.title)}</Typography>
                <Typography  variant={"subtitle1"}>{translate(props.subtitle)}</Typography>
            </div>
            <CardContent className={classes.chartbox}>
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="Bar"
                    chartLanguage="ru"
                    loader={<img style={{alignSelf: "center"}} src="loadingChartGif.gif"/>}
                    data={metrics}
                    options={{

                        chartArea: { width: '90%', height: '76%' },
                        legend: props.legend,
                        orientation: "vertical",
                        hAxis: {
                            title: translate(props.headers[0]),
                            textStyle: {
                                bold: true,

                            }},

                        }

                    }
                    rootProps={{ 'data-testid': '2' }}
                />
            </CardContent>
        </Card>
    )
};

BarChart.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    title: PropTypes.string,
    metrics: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    converter: PropTypes.func,
    legend: PropTypes.object
};

export default BarChart;