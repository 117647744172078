import React, { Children, cloneElement, isValidElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    card: {
        height: 128,
        display: "flex",
        flexDirection: "column",
    }
}));


const MetricsContainer = ({ children, xs, md, lg, xl, sm, ...props }) => {

    if (!children) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            {Children.map(children, child => {
                return (
                    isValidElement(child) &&
                    <Grid item xs={xs} md={md} lg={lg} xl={xl} sm={sm}>{
                        cloneElement(child, {
                            ...props
                        })}
                    </Grid>
                )
            })}
        </Grid>
    )
};

// MetricsContainer.propTypes = {
//     xs: true,
//     md: false,
//     lg: false,
//     xl: false,
//     sm: false
// };

export default MetricsContainer;