import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';


const InstanceField = ({classes: classesOverride, className, record, instances, ...props}) => {

    if (!(instances && instances.length > 0 )) { return null };
    let counterAll = 0;
    let counterUp = 0;
    instances.forEach((instance) => {
        if (instance.discoveredLabels.job === record.prometheusJob){
            counterAll++;
            if (instance.health === 'up') {
                counterUp++;
            }
        }
    });

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeRestProps(props)}
        >
            {counterUp}/{counterAll}
        </Typography>
    );
};

InstanceField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default InstanceField;