import React, { useState } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "./ChartCard";
import Loading from "./Loading";
import Typography from '@material-ui/core/Typography'
import BlockIcon from '@material-ui/icons/Block';
import { useTranslate } from "react-admin";
import PeriodBar from "./PeriodBar";
import useQuery from "../../dataProvider/Prometheus/useQuery";
import { URI_PROMETHEUS } from "../../appsettings";

const useStyles = makeStyles(theme => ({
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    chip: {
        borderRadius: 4,
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
    periodBar: {
        alignSelf: "center",
    }
}));

export const SummaryDay = ({ filter }) => {
    const { time, rangeVector, job } = filter;
    const classes = useStyles();
    const date = moment(time)
    const { data, loading, error } = useQuery({
        query: [`summary_day{job="${job}"}[${rangeVector}]&time=${date.unix()}`],
        uri: URI_PROMETHEUS,
    });
    const translate = useTranslate();
    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    // const handlePeriod = (period) => {
    //     setPeriod(period)
    // }
    const summaryDay = data && data.length && data[0][0];
    const metrics = summaryDay ? summaryDay.values.map(value => ([moment.unix(value[0]).toDate(), Number(value[1])])) : [];
    if (!metrics.length) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            {/*<PeriodBar */}
            {/*    period={period}*/}
            {/*    setPeriod={handlePeriod} */}
            {/*    className={classes.periodBar}*/}
            {/*/>*/}
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    loader={<Loading />}
                    data={[["", "Инстансы"], ...metrics]}
                    options={{
                        hAxis: {
                            titleTextStyle: { color: '#333' },
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            format: 'HH:mm'
                        },
                        vAxis: {
                            minValue: 0,
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                        },
                        legend: {
                            position: "bottom"
                        },
                        chartArea: {
                            top: 10,
                            width: '86%',
                            height: '86%'
                        },
                        lineWidth: 1,
                        explorer: {
                            actions: ['dragToZoom', 'rightClickToReset'],
                            axis: 'horizontal',
                            keepInBounds: true,
                            maxZoomIn: 4.0,
                        },

                    }}
                />
            </Box>
        </Box>

    );
};

const SummaryDayChart = ({ filter, ...props }) => {
    const classes = useStyles();

    return (
        <ChartCard
            avatar={<Avatar variant="rounded"><TrendingUpIcon /></Avatar>}
            title="Metrics.summaryDay"
            action={false}
        >
            <SummaryDay filter={filter} />
        </ChartCard>
    );
};

export default SummaryDayChart;