import React from 'react';
import {useShowController, TextField, DateField} from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import OpcServerBanner from './OpcServerBanner';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";



const OpcServerShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);

    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
           
            <OpcServerBanner />

            <CardShowLayout>
                {ability.can('getOne', 'OpcServer', 'login')&&<TextField source="login" />}
                {ability.can('getOne', 'OpcServer', 'url')&&<TextField source="url" />}
                {ability.can('getOne', 'OpcServer', 'prometheusJob')&&<TextField source="prometheusJob" />}
            </CardShowLayout>

            {ability.can('getOne', 'OpcServer', 'meta')&&<CardShowLayout>
                <JsonField source="meta"/>
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'OpcServer', 'id')&&<TextField source="id" />}
                    {ability.can('getOne', 'OpcServer', 'createdAt')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'OpcServer', 'createdBy')&&<TextField source="createdBy.name" label="resources.OpcServer.fields.createdBy" />}
                    {ability.can('getOne', 'OpcServer', 'updatedAt')&&<DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default OpcServerShow;