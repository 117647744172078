// load .env
require('dotenv').config();



const global_env = (process.env.NODE_ENV === 'development') ? process.env : window.ENV;

export const TITLE_APP = global_env.REACT_APP_TITLE_APP || 'АСКУГ Конфигурация'
export const PERMISSIONS_NAMESPACE = 'ASKUG_ADMINISTRATION'
export const GROUP_NAME_APP = 'ASKUG'
export const AUTH_TOKEN_COOKIE = `${GROUP_NAME_APP}-AUTH-TOKEN`
export const REFRESH_TOKEN_COOKIE = `${GROUP_NAME_APP}-REFRESH-TOKEN`
export const DOMAIN_COOKIE = global_env.REACT_APP_DOMAIN_COOKIE || "localhost"
export const PACKAGE_NAME = global_env.REACT_APP_NAME || "web-askug-administration-portal"
export const PACKAGE_VERSION = global_env.REACT_APP_VERSION || "0.0.1"
export const PACKAGE_VERSION_COOKIE = `${GROUP_NAME_APP}-PACKAGE-VERSION`


// SERVICE URI's
export const URI_AUTH = global_env.REACT_APP_URI_AUTH || 'http://localhost:5000'
export const URI_CASL = global_env.REACT_APP_URI_CASL || 'http://localhost:4555'
export const URI_CORE = global_env.REACT_APP_URI_CORE || 'http://localhost:5550'
export const WS_CORE = global_env.REACT_APP_WS_CORE || 'ws://localhost:5550'
export const URI_DATA = global_env.REACT_APP_URI_DATA || 'http://localhost:5560'
export const URI_IMPORTEXPORT = global_env.REACT_APP_URI_IMPORTEXPORT || 'http://localhost:5570'
export const URI_SERVICE_PORTAL = global_env.REACT_APP_URI_SERVICE_PORTAL || 'http://localhost:3002'
export const URI_PROMETHEUS = global_env.REACT_APP_URI_PROMETHEUS || 'http://localhost:9091'
export const URI_CONVERTER = global_env.REACT_APP_URI_CONVERTER || 'http://localhost:8085/query'
export const URI_CALL_SERVICE = global_env.REACT_APP_URI_CALL_SERVICE || 'http://localhost:9091'
export const URI_FILE_PARSER_SERVICE = global_env.REACT_APP_URI_FILE_PARSER_SERVICE || 'http://localhost:9091'
export const URI_FILL_DATA_GAPS_SERVICE = global_env.REACT_APP_URI_FILL_DATA_GAPS_SERVICE || 'http://localhost:7777'
export const PUSH_API_SERVER = global_env.REACT_APP_PUSH_API_SERVER || 'http://localhost:8550'