import React from 'react';
import { useShowController, ChipField, TextField, DateField, ReferenceArrayField, ReferenceManyField as RaReferenceManyField, SingleFieldList, Pagination, TabbedShowLayout, Tab } from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import {ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import NestedArrayField from '../Fields/NestedArrayField';
import PhoneField from '../Fields/PhoneField';
import EnumField from '../Fields/EnumField';
import NestedField from '../Fields/NestedField';
import CounterpartyBanner from './CounterpartyBanner';
import CardBlankLayout from "../Layout/CardBlankLayout";
import {ability} from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    card:{
        paddingBottom: theme.spacing(2),
    },
}));

const CounterpartyShow = props => {
    const classes = useStyles();
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const type = controllerProps.record && controllerProps.record.type;
    const accessResource = controllerProps.record && controllerProps.record.accessResource;

    return (
        <ShowView 
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            <CounterpartyBanner />

            <CardBlankLayout classes={{card: classes.card}}>
                <TabbedShowLayout>
                    <Tab label="resources.Counterparty.tabs.general" >
                        {ability.can('getOne', 'Counterparty', 'type')&&<EnumField source="type" />}
                        {ability.can('getOne', 'Counterparty', 'inn')&&<TextField source="inn" />}
                        {ability.can('getOne', 'Counterparty', 'kpp')&&(type === "LEGALENTITY" || type === "GOVERNMENTAGENCY") && <TextField source="kpp"/>}
                        {ability.can('getOne', 'Counterparty', 'okpo')&&(type === "LEGALENTITY" || type === "GOVERNMENTAGENCY") && <TextField source="okpo"/>}
                        {ability.can('getOne', 'Counterparty', 'ogrn')&&(type === "LEGALENTITY" || type === "GOVERNMENTAGENCY") && <TextField source="ogrn"/>}
                        {ability.can('getOne', 'Counterparty', 'fio')&&type === "INDIVIDUAL" && <TextField source="fio"/>}
                        {ability.can('getOne', 'Counterparty', 'passport')&&type === "INDIVIDUAL" && <TextField source="passport"/>}
                        {ability.can('getOne', 'Counterparty', 'passdateissue')&&type === "INDIVIDUAL" && <DateField source="passdateissue"/>}
                        {ability.can('getOne', 'Counterparty', 'parentId')&&type === "SEPARATEDIVISION" && <TextField source="parentId"/>}
                        {ability.can('getOne', 'Counterparty', 'addressLegal')&&<TextField source="addressLegal" />}
                        {ability.can('getOne', 'Counterparty', 'addressActual')&&<TextField source="addressActual" />}
                        {ability.can('getOne', 'Counterparty', 'addressPostal')&&<TextField source="addressPostal"/>}
                        {ability.can('getOne', 'Counterparty', 'phone')&&<PhoneField source="phone" />}
                        {ability.can('getOne', 'Counterparty', 'phoneMobile')&&<PhoneField source="phoneMobile" />}
                        {ability.can('getOne', 'Counterparty', 'email')&&<TextField source="email"/>}
                    </Tab>
                    <Tab label="resources.Counterparty.tabs.access" path="access">
                        {accessResource && <ReferenceArrayField source="accessIds" reference={accessResource} label={`resources.Counterparty.fields.access${accessResource}`}>
                            <SingleFieldList linkType="show">
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>}
                        {ability.can('getOne', 'Counterparty', 'users')&&<NestedArrayField source="users" reference="User" linkType="show" >
                            <ChipField source="name" />
                        </NestedArrayField>}
                    </Tab>
                </TabbedShowLayout>
            </CardBlankLayout>
            
            <Separator action={(<div ref={showMoreButton} ></div>)}/>
            
            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'Counterparty', 'type')&&<TextField source="id" />}
                    {ability.can('getOne', 'Counterparty', 'type')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'Counterparty', 'type')&&<NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {ability.can('getOne', 'Counterparty', 'type')&&<DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>

            <Separator action={(<div ref={showHistoryButton} ></div>)}/>

            <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="Counterparty"/>
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>
        </ShowView>
    );
};

export default CounterpartyShow;