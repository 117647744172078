import React, { useState } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "./ChartCard";
import Loading from "./Loading";
import { useTranslate } from 'react-admin';
import BlockIcon from '@material-ui/icons/Block';
import Typography from '@material-ui/core/Typography'
import useQuery from "../../dataProvider/Prometheus/useQuery";
import { URI_PROMETHEUS } from "../../appsettings";
import PeriodBar from "./PeriodBar";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    chip: {
        borderRadius: 4,
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
    periodBar: {
        alignSelf: "center",
    }
}));

export const ParsedFiles = ({ filter }) => {
    const { time, rangeVector, job } = filter;
    const classes = useStyles();
    const date = moment(time)
    const { data, loading, error } = useQuery({
        query: [
            `{__name__=~"ok_files|not_supported_files|send_error_files|warning_files|error_files|no_configuration",job="${job}"}[${rangeVector || '1h'}]&time=${date.unix()}`
        ],
        uri: URI_PROMETHEUS,
    });
    const translate = useTranslate();


    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />);
    // const handlePeriod = (period) => {
    //     setPeriod(period)
    // }

    const converter = (metrics) => {

        metrics = metrics.filter((value) => value && value.length);
        if (!metrics.length) { return [] }
        const result = [];
        result.push(['Время', ...metrics[0].map(metric => {
            return translate('Metrics.ParsedFilesFields.' + metric.metric.__name__);
        })]);
        for (let i = 0; i < metrics[0][0].values.length; i++) {
            result.push([moment.unix(metrics[0][0].values[i][0]).toDate(), ...metrics[0].map(metric => parseInt(metric.values[i][1]))])
        }
        return result;
    };
    const metrics = converter(data);
    if (!metrics.length) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );
    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            {/*<PeriodBar */}
            {/*    period={period}*/}
            {/*    setPeriod={handlePeriod} */}
            {/*    className={classes.periodBar}*/}
            {/*/>*/}
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    loader={<Loading />}
                    data={metrics}
                    options={{
                        isStacked: true,
                        hAxis: {
                            titleTextStyle: { color: '#333' },
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            format: 'HH:mm'
                        },
                        vAxis: {
                            minValue: 0,
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                        },
                        legend: {
                            position: "bottom",
                        },
                        chartArea: {
                            top: 10,
                            width: '86%',
                            height: '86%'
                        },
                        lineWidth: 1,
                        explorer: {
                            actions: ['dragToZoom', 'rightClickToReset'],
                            axis: 'horizontal',
                            keepInBounds: true,
                            maxZoomIn: 4.0,
                        },
                    }

                    }
                    rootProps={{ 'data-testid': '2' }}
                />
            </Box>
        </Box>

    );
};

const ParsedFilesChart = ({ filter, ...props }) => {
    const classes = useStyles();
    const { job } = props;
    return (
        <ChartCard
            avatar={<Avatar variant="rounded" ><TrendingUpIcon /></Avatar>}
            title="Metrics.ParsedFiles"
            action={false}
        >
            <ParsedFiles filter={filter} />
        </ChartCard>
    );
};

export default ParsedFilesChart;