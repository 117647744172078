import gql from 'graphql-tag'
import PushApiClient from "./PushApiClient";

export const SUBSCRIBE = gql`
    mutation subscribe( $subscription: Json ) {
        data: subscribe( subscription: $subscription ) { id endpoint }
    }
`

export default (subscription) => {
    return PushApiClient.mutate({
        mutation: SUBSCRIBE,
        variables: { subscription: subscription },
        onError: (error) => {
            alert(error)
        }
    });
};
