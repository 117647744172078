import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { useTranslate } from 'react-admin';


const useChipStyles = makeStyles(theme => ({
    root: {
    },
    label: {
        display: "block",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

const EnumChip = ({ className, classes: classesOverride, record, source, resource, ...props}) => {
    const classes = useChipStyles({ classes: classesOverride });
    const translate = useTranslate();

    const enumValue = get(record, source);
    if (!enumValue) {return null};
    
    const label = translate(`resources.${resource}.enums.${source}.${enumValue}`, {_: enumValue})

    return (
        <Chip
            size="small"
            classes={{root:classes.root, label: classes.label}}
            className={`${className}`}
            label={label}
        />
    );
};


EnumChip.defaultProps = {
    addLabel: true,
};



export default EnumChip;