import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uid } from '../../utils'
import gql from 'graphql-tag'
import { useNotify, useRedirect, useRefresh, useTranslate, fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import startWorkflow from '../../dataProvider/Converter/startWorkflow';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import SyncIcon from '@material-ui/icons/Sync';



export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    root: {
        height: 156,
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#689F38",
        backgroundColor: "#c5e1a5",
    },
    action: {
        height: "100%",
    },
    ml2: {marginLeft: 8},
}));

const StartConverterWorkflowCard = ({ 
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    client,
    redirect: redirectTo,
    ...rest }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);

    const handleClick = () => {

        setLoading(true);
        dispatch(fetchStart());


        const workflowdata = record.meta && record.meta.startConverterWorkflowOptions && JSON.parse(record.meta.startConverterWorkflowOptions.input).workflowdata
        
        if (!workflowdata) {
            notify( 'ra.notification.converter_error', 'warning' );
            setLoading(false);
            dispatch(fetchEnd()); 
            return false;
        }
        
        const args = {
            workflowname: "ConverterWorkflowUpdate",
            input: JSON.stringify({
                workflowdata,
                workflowsettings: {
                    workflowId: uid("CONVERTER"),
                    executionStartToCloseTimeout: 600,
                    decisionTaskStartToCloseTimeout: 60,
                    islogging: true
                }
            })
        }

        client.startWorkFlow(args)
        .then(({ data: {workflowStart} }) => {
            notify( 'ra.notification.converter_started', 'info', { workflow_id: workflowStart.workflowId } );

            apolloCoreClient.mutate({
                mutation: gql`
                    mutation updateConverter($where: ConverterWhereUniqueInput!, $meta: Json) {
                        updateConverter(where: $where, meta: $meta) { id }
                }`,
                variables: { 
                    where: {
                        id: record.id
                    },
                    meta: {
                        ...record.meta,
                        startConverterWorkflowOptions: args
                    }
                },
                onError: (error) => {
                    alert(error)
                }
            })
            .then((data) => {

                refresh();
            });
        })
        .catch((error) => {
            notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
        })
        .finally(() => {
            setLoading(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    };


    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} disabled={loading} classes={{root: classes.action}}>
                <CardHeader
                    avatar={<Avatar aria-label="start_converter_workflow" variant="rounded" className={classes.avatar}><SyncIcon/></Avatar>}
                    title={translate('ra.action.start_converter_workflow')}
                    titleTypographyProps={{variant:"subtitle1"}}
                    classes={{title: classes.title}}
                />
                <CardContent>
                    <Typography variant="body1" color="textSecondary">Запуск рабочего процесса CONVERTER</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
        
};

export default StartConverterWorkflowCard;