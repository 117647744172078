import buildGraphQLProvider from 'ra-data-graphql-simple';
import apolloCoreClient from './apolloCoreClient';
import decorate from './decorate';
import buildQuery from './builtQuery';

const buildCoreProvider = () => buildGraphQLProvider({
    client: apolloCoreClient,
    buildQuery: buildQuery,
});


export const knownResources = ['App', 'FileParserService', 'OpcServer', 'DataBus', 'DataBusToXmlService', 'AnalyticsService', 'Converter', 'CallService', 'FillDataGapsService', 'Counterparty', 'User', 'Profile', 'History', 'TrashItem', 'GasRegion', 'GasDistrict', 'GasConsumer', 'GasMeter', 'GasMeterPad', 'GasMeterUnit'];

export const dataProvider = (type, resource, params) => {
    return buildCoreProvider().then(async (provider) => {

        const { params: paramsD } = await decorate(type, resource, params);

        return provider(type, resource, paramsD)
    }
    )
};

export default {
    dataProvider,
    knownResources,
};