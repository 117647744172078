import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { authProvider } from './authPrivider';
import { dataProviderFactory } from './dataProvider/dataProviderFactory';
import { i18nProvider } from './i18n/i18nProvider';
import { theme } from './defaultTheme';
import customRoutes from './customRoutes';
import { MyLayout } from './components/Layout';
//import MyLoginPage from './components/Auth/Login';
import user from './redux/user/reducer';
import onlineUsers from './redux/onlineUsers/reducer';
import instances from './redux/instance/reducer'
import instancesById from './redux/instancesById/reducer'
import allMetrics from './redux/allMetrics/reducer'
import metrics from './redux/metricsForInstance/reducer'
import pushDialog from './redux/pushDialog/reducer'
import { ability } from "./abilityProvider";

import Dashboard from './components/Dashboard';
import CustomLayout from "./components/Layout/CustomLayout";
import { AppCreate, AppEdit, AppIcon, AppList, AppShow } from './components/App';
import { FileParserServiceList, FileParserServiceIcon, FileParserServiceCreate, FileParserServiceEdit, FileParserServiceShow } from './components/FileParserService';
import { DataBusIcon, DataBusCreate, DataBusEdit, DataBusList, DataBusShow } from './components/DataBus';
import { OpcServerIcon, OpcServerCreate, OpcServerEdit, OpcServerList, OpcServerShow } from "./components/OpcServer";
import { ConverterCreate, ConverterEdit, ConverterIcon, ConverterList, ConverterShow } from "./components/Converter";
import { CallServiceList, CallServiceEdit, CallServiceCreate, CallServiceShow, CallServiceIcon } from "./components/CallService";
import { FillDataGapsServiceShow, FillDataGapsServiceList, FillDataGapsServiceCreate, FillDataGapsServiceEdit, FillDataGapsServiceIcon } from './components/FillDataGapsService'
import { DataBusToXmlServiceList, DataBusToXmlServiceShow, DataBusToXmlServiceCreate, DataBusToXmlServiceEdit, DataBusToXmlServiceIcon } from './components/DataBusToXml'
import { AnalyticsServiceList, AnalyticsServiceShow, AnalyticsServiceCreate, AnalyticsServiceEdit, AnalyticsServiceIcon } from './components/AnalyticsService'
import { CounterpartyList, CounterpartyCreate, CounterpartyEdit, CounterpartyShow, CounterpartyIcon } from './components/Counterparty';
import { UserList, UserCreate, UserEdit, UserShow, UserIcon } from './components/User';
import { HistoryList, HistoryShow, HistoryIcon } from './components/History';
import { TrashItemList, TrashItemIcon } from './components/TrashItem';

import { ImportFromAisIcon, ImportFromAisShow } from './components/ImportFromAis';

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const onMount = () => {
      dataProviderFactory().then(dataProvider => {
        setDataProvider(() => dataProvider);
        setLoading(false);
      });
    }

    onMount();

    return () => {
      setDataProvider(null);
    };

  }, []);

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }


  return (
    <Admin
      disableTelemetry
      theme={theme}
      layout={CustomLayout}
      //loginPage={MyLoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      customReducers={{ user, onlineUsers, instances, instancesById, metrics, allSimpleMetrics: allMetrics, pushDialog }}
      customRoutes={customRoutes}
    >
      { permissions => [

        <Resource name="FileParserService"
          list={ability.can('getList', 'FileParserService') && FileParserServiceList}
          show={ability.can('getOne', 'FileParserService') && FileParserServiceShow}
          create={ability.can('create', 'FileParserService') && FileParserServiceCreate}
          edit={ability.can('update', 'FileParserService') && FileParserServiceEdit}
          icon={FileParserServiceIcon} options={{ menuGroup: "resources" }} />,
        <Resource name="OpcServer"
          list={ability.can('getList', 'OpcServer') && OpcServerList}
          show={ability.can('getOne', 'OpcServer') && OpcServerShow}
          create={ability.can('create', 'OpcServer') && OpcServerCreate}
          edit={ability.can('update', 'OpcServer') && OpcServerEdit}
          icon={OpcServerIcon} options={{ menuGroup: "resources" }} />,
        <Resource name="DataBus"
          list={ability.can('getList', 'DataBus') && DataBusList}
          show={ability.can('getOne', 'DataBus') && DataBusShow}
          create={ability.can('create', 'DataBus') && DataBusCreate}
          edit={ability.can('update', 'DataBus') && DataBusEdit}
          icon={DataBusIcon}
          options={{ menuGroup: "resources" }} />,
        <Resource name="Converter"
          list={ability.can('getList', 'Converter') && ConverterList}
          show={ability.can('getOne', 'Converter') && ConverterShow}
          create={ability.can('create', 'Converter') && ConverterCreate}
          edit={ability.can('update', 'Converter') && ConverterEdit}
          icon={ConverterIcon}
          options={{ menuGroup: "resources" }} />,
        <Resource name="CallService"
          list={ability.can('getList', 'CallService') && CallServiceList}
          show={ability.can('getOne', 'CallService') && CallServiceShow}
          create={ability.can('create', 'CallService') && CallServiceCreate}
          edit={ability.can('update', 'CallService') && CallServiceEdit}
          icon={CallServiceIcon}
          options={{ menuGroup: "resources" }} />,
        <Resource name="AnalyticsService"
          list={ability.can('getList', 'AnalyticsService') && AnalyticsServiceList}
          show={ability.can('getOne', 'AnalyticsService') && AnalyticsServiceShow}
          create={ability.can('create', 'AnalyticsService') && AnalyticsServiceCreate}
          edit={ability.can('update', 'AnalyticsService') && AnalyticsServiceEdit}
          icon={AnalyticsServiceIcon}
          options={{ menuGroup: "resources" }} />,
        <Resource name="FillDataGapsService"
          list={ability.can('getList', 'FillDataGapsService') && FillDataGapsServiceList}
          show={ability.can('getOne', 'FillDataGapsService') && FillDataGapsServiceShow}
          create={ability.can('create', 'FillDataGapsService') && FillDataGapsServiceCreate}
          edit={ability.can('update', 'FillDataGapsService') && FillDataGapsServiceEdit}
          icon={FillDataGapsServiceIcon}
          options={{ menuGroup: "resources" }} />,
        <Resource name="DataBusToXmlService"
          list={ability.can('getList', 'DataBusToXmlService') && DataBusToXmlServiceList}
          show={ability.can('getOne', 'DataBusToXmlService') && DataBusToXmlServiceShow}
          create={ability.can('create', 'DataBusToXmlService') && DataBusToXmlServiceCreate}
          edit={ability.can('update', 'DataBusToXmlService') && DataBusToXmlServiceEdit}
          icon={DataBusToXmlServiceIcon}
          options={{ menuGroup: "resources" }} />,

        <Resource name="App"
          list={ability.can('getList', 'App') && AppList}
          show={ability.can('getOne', 'App') && AppShow}
          create={ability.can('create', 'App') && AppCreate}
          edit={ability.can('update', 'App') && AppEdit}
          icon={AppIcon} options={{ menuGroup: "access" }} />,
        <Resource name="Counterparty"
          icon={CounterpartyIcon}
          list={ability.can('getList', 'Counterparty') && CounterpartyList}
          show={ability.can('getOne', 'Counterparty') && CounterpartyShow}
          create={ability.can('create', 'Counterparty') && CounterpartyCreate}
          edit={ability.can('update', 'Counterparty') && CounterpartyEdit}
          options={{ menuGroup: "access" }} />,
        <Resource name="User"
          icon={UserIcon}
          list={ability.can('getList', 'User') && UserList}
          show={ability.can('getOne', 'User') && UserShow}
          create={ability.can('create', 'User') && UserCreate}
          edit={ability.can('update', 'User') && UserEdit}
          options={{ menuGroup: "access" }} />,
        <Resource name="ImportFromAis"
          list={ability.can('getList', 'ImportFromAis')}
          show={ability.can('getOne', 'ImportFromAis') && ImportFromAisShow}
          icon={ImportFromAisIcon}
          options={{ menuGroup: "utils" }}
        />,
        <Resource name="History"
          list={ability.can('getList', 'History') && HistoryList}
          show={ability.can('getOne', 'History') && HistoryShow}
          icon={HistoryIcon}
          options={{ menuGroup: "utils" }} />,
        <Resource name="TrashItem"
          list={ability.can('getList', 'TrashItem') && TrashItemList}
          icon={TrashItemIcon}
          options={{ menuGroup: "utils" }} />,

        <Resource name="Profile" />,
        <Resource name="GasRegion" />,
        <Resource name="GasDistrict" />,
        <Resource name="GasConsumer" />,
        <Resource name="GasMeterPad" />,
        <Resource name="GasMeterUnit" />,
      ]}
    </Admin>
  );
};

export default App;