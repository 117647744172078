import moment from 'moment'
import 'moment/locale/ru';
import { URI_PROMETHEUS } from '../../appsettings';

class PrometheusClient {
    constructor(job, period = 12, date = moment().unix()) {
        this.simpleMetrics = [
            `${URI_PROMETHEUS}/api/v1/query_range?query=askug_convert_convert_workflow_windows_guage&start=${new Date().setHours(-12) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1582621631995`,
            `${URI_PROMETHEUS}/api/v1/query_range?query=up&start=${new Date().setHours(-12) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1582621631995`,
        ];
        this.allMetrics = {
            converterLastUpdatePercent: `${URI_PROMETHEUS}/api/v1/query_range?query=count(askug_convertItem_convert_lastupdate >time()-${24 * 3600}) / count(askug_convertItem_convert_lastupdate) * 100&start=${date - period * 3600}&end=${date}&step=${Math.abs(period * 14)}`,
            convertItemAverage: `${URI_PROMETHEUS}/api/v1/query_range?query=delta(askug_convertItem_convert_workflow_elapsed_sum[20m])%2Fdelta(askug_convertItem_convert_workflow_elapsed_count[20m])&start=${date - period * 3600}&end=${date}&step=${Math.abs(period * 14)}&_=1582621631995`,
            convertAverage: `${URI_PROMETHEUS}/api/v1/query_range?query=delta(askug_convert_convert_workflow_elapsed_sum[20m])%2Fdelta(askug_convert_convert_workflow_elapsed_count[20m])&start=${date - period * 3600}&end=${date}&step=${Math.abs(period * 14)}&_=1582621631995`,
            cronAverage: `${URI_PROMETHEUS}/api/v1/query_range?query=delta(askug_convert_cron_workflow_elapsed_sum[20m])%2Fdelta(askug_convert_cron_workflow_elapsed_count[20m])&start=${date - period * 3600}&end=${date}&step=${Math.abs(period * 14)}&_=1582621631995`,
            cronGuage: `${URI_PROMETHEUS}/api/v1/query_range?query=askug_convert_convert_workflow_windows_guage${job ? `{job=~"${job}"}` : ''}&start=${new Date().setHours(-12) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1582621631995`,
            up: `${URI_PROMETHEUS}/api/v1/query_range?query=up&start=${new Date().setHours(-12) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 60)}&_=1582621631995`,
            convertItemStart: `${URI_PROMETHEUS}/api/v1/query?query=topk(30%2C%20askug_convertItem_convert_workflow_start_total${job ? `{job="${job}"}` : ''})%20and%20askug_convertItem_convert_workflow_start_total>%3D1&time=${new Date() / 1000}`,
            convertItemStartTotal: `${URI_PROMETHEUS}/api/v1/query?query=delta(askug_convertItem_convert_workflow_start_total${job ? `{job="${job}"}` : ''}[24h])&time=${new Date() / 1000}`,
            polledWithErr: `${URI_PROMETHEUS}/api/v1/query_range?query=askug_dcs_correctors_polling_with_error${job ? `{job="${job}"}` : ''}&start=${(new Date().setHours(-12)) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1584360036351`,
            dcsCorrectorsNeverPolled: `${URI_PROMETHEUS}/api/v1/query_range?query=askug_dcs_correctors_never_polled${job ? `{job="${job}"}` : ''}&start=${(new Date().setHours(-12)) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1584360036351`,
            fpsUniSomeMetrics: `${URI_PROMETHEUS}/api/v1/query?query={__name__=~"error_files|not_supported_files|send_error_files|warning_files|ok_files|no_configuration"${job ? `, job="${job}"` : ''}}&start=${(new Date().setHours(-12)) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1584360036351`,
            summaryDay: `${URI_PROMETHEUS}/api/v1/query_range?query=summary_day&start=${(new Date().setHours(-12)) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1584360036351`,
            convertItemLastUpdate: `${URI_PROMETHEUS}/api/v1/query?query=(time()%20-%20askug_convertItem_convert_lastupdate${job ? `{job="${job}"}` : ''})%2F3600%20and%20(time()%20-%20askug_convertItem_convert_lastupdate${job ? `{job="${job}"}` : ''})%2F3600%20<%20240&start=${(new Date().setHours(-12)) / 1000}&end=${new Date() / 1000}&step=${Math.abs(12 * 14)}&_=1584360036351`,
            convertItemStartTotalCount: `${URI_PROMETHEUS}/api/v1/query?query=count(count(delta(askug_convertItem_convert_workflow_start_total${job ? `{job="${job}"}` : ''}%7Bdbid%3D~".*"%7D%5B24h%5D))%20by%20(dbid))&time=${new Date() / 1000}`,
            guageOne: `${URI_PROMETHEUS}/api/v1/query?query=askug_convert_convert_workflow_gasmeters_guage${job ? `{job="${job}"}` : ''}&time=${new Date() / 1000}`,
            convertItemStartSum: `${URI_PROMETHEUS}/api/v1/query?query=sum(askug_convertItem_convert_workflow_start_total${job ? `{job="${job}"}` : ''})&time=${new Date() / 1000}`,
            convertStart: `${URI_PROMETHEUS}/api/v1/query?query=askug_convert_convert_workflow_start_total${job ? `{job="${job}"}` : ''}&time=${new Date() / 1000}`,
            convertItemTotal: `${URI_PROMETHEUS}/api/v1/query?query=avg(sum_over_time(askug_convertItem_convert_workflow_start_total${job ? `{job="${job}"}` : ''}%5B24h%5D))&time=${new Date() / 1000}`,
            cronStart: `${URI_PROMETHEUS}/api/v1/query?query=sum(askug_convert_cron_workflow_start_total${job ? `{job="${job}"}` : ''})&time=${new Date() / 1000}`,
            cronFailed: `${URI_PROMETHEUS}/api/v1/query?query=sum(askug_convert_cron_workflow_failed_total${job ? `{job="${job}"}` : ''})&time=${new Date() / 1000}`,
            convertFailed: `${URI_PROMETHEUS}/api/v1/query?query=sum(askug_convert_convert_workflow_failed_total${job ? `{job="${job}"}` : ''})&time=${new Date() / 1000}`,
            convertItemFailed: `${URI_PROMETHEUS}/api/v1/query?query=sum(askug_convertItem_convert_workflow_failed_total${job ? `{job="${job}"}` : ''})&time=${new Date() / 1000}`
        }
    }
    getAllActiveInstances(api = 'api1') {
        const url = api === 'api1' ? `${URI_PROMETHEUS}/api/v1/targets` : `${URI_PROMETHEUS}/api/v1/targets`
        return fetch(url, {
            method: "GET",
            credentials: "omit",
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: "no-referrer-when-downgrade",
            mode: "cors"
        }).catch((error) => {
            console.log(error);
        }).then((response) => response.text())
            .then(responseText => JSON.parse(responseText).data.activeTargets)
            .catch(error => []);
    }

    getMetricByName(metric) {
        return fetch(metric, {
            method: "GET",
            credentials: "omit",
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: "no-referrer-when-downgrade",
            mode: "cors"
        }).catch((error) => {
            console.log(error);
        }).then((response) => response.text())
            .then(responseText => {
                return JSON.parse(responseText).data.result
            }).catch(error => {
                console.log(error)
            });
    }

    getArrayOfMetrics(metrics) {
        return Promise.all([...metrics.map(value => this.allMetrics[value])].map(metric => {
            return this.getMetricByName(metric);
        })).then(result => {
            return result
        })
    }

    getInstancesByJob(job, time = 1) {
        return fetch(`${URI_PROMETHEUS}/api/v1/query?query={job="${job}"}`, {
            method: "GET",
            credentials: "omit",
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: "no-referrer-when-downgrade",
            mode: "cors"
        }).catch((error) => {
            console.log(error);
        }).then((response) => response.text())
            .then(responseText => JSON.parse(responseText).data.result)
    }
    getAllSimpleMetrics() {
        return Promise.all(this.simpleMetrics.map(metric => {
            return this.getMetricByName(metric);
        })).then(result => {
            return result
        }).catch(error => []);
    }
}

export default PrometheusClient;