import React, {useEffect, useState} from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { db } from "./IndexedDB";

export const useStorage = () => {
    const [state, setState] = useState({allNotifications: [], notificationsToShow: []});
    const readDB = () => {

        let transaction;
        try {
            transaction = db.transaction('Notification', 'readonly');
        } catch (e) {
            setState({allNotifications: [], notificationsToShow: []});
            return;
        }
        if (!transaction) {
            setState({allNotifications: [], notificationsToShow: []});
            window.requestAnimationFrame(readDB);
            return
        }
        const notification = transaction.objectStore('Notification').getAll();
        notification.onsuccess = event => {
            const notificationsToShow = notification.result.filter(not => !not.isShown);
            const write = db.transaction('Notification', 'readwrite');
            notificationsToShow.forEach(not => {
                not.isShown = true;
                write.objectStore('Notification').put(not);
            });
            setState({allNotifications: notification.result, notificationsToShow: notificationsToShow})
            window.requestAnimationFrame(readDB)
        };
        notification.onerror = err => {
            console.log(err)
            window.requestAnimationFrame(readDB)
        }
    };
    useEffect(() => {
        readDB();
    }, []);

    return state
};