import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useQuery from '../../../dataProvider/Prometheus/useQuery';
import MetricsCard from "../MetricsCard";
import Loading from "../Loading";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textBold: {
        fontWeight: "bold"
    },
    mtAuto: {
        marginTop: "auto"
    }
}));

export const ConvertItemForDay = ({filter}) => {
    const {time, job} = filter;
    const classes = useStyles();
    const date = moment(time);
    const {data, loading, error} = useQuery({
        query: [`round(sum(delta(askug_convertItem_convert_workflow_start_total{job="${job}"}[24h])) and sum(delta(askug_convertItem_convert_workflow_start_total{job="${job}"}[24h])) > 0)&time=${date.unix()}`],
    });
    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    const total = data && data.length && data[0][0] && data[0][0].value[1];
    
    if (!total) {
        return (<Typography variant="subtitle1" color="textSecondary" display="inline">Нет данных</Typography>)
    }
    return (
        <div className={classes.root}>
            <Typography variant="h4" display="inline" color={total < 300 ? 'error' : total < 1000 ? 'secondary' : 'primary'} className={classes.textBold}>{total}</Typography>
            {/*<Typography variant="caption" color="textSecondary" className={classes.mtAuto}>Последние 24 часа / Всего</Typography>*/}
        </div>

    );
};

const ConvertItemForDayCounter = (props) => {
    const { record } = props;
    return (
        <MetricsCard title="Metrics.ConvertItemForDay" {...props}>
            <ConvertItemForDay job={record.prometheusJob}/>
        </MetricsCard>
    );
};

export default ConvertItemForDayCounter;