import React, { cloneElement, Children } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {useTranslate} from 'react-admin';



const useStyles = makeStyles(theme => ({
    card: {
        display: "flex",
        flexDirection: "column",
        height: 152,
    },
    title: {
        padding: 16,
    },
    content: {
        paddingTop: 0,
        flexGrow: 1,
    
    },
    
}));

const MetricsCard = ({children, classes: classesOverride, className, title, action, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    return (
        <Card classes={{root: classes.card}}>
            {title && <Typography className={classes.title} >{translate(title, {_: title})}</Typography>}
            <CardContent classes={{root: classes.content}}>
                {cloneElement(Children.only(children), {
                    ...props,
                })}
            </CardContent>
        </Card>
    );
};


export default MetricsCard;