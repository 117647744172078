import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListController,
    ListView,
    Datagrid,
    DateField,
    TextField,
    Filter,
    SearchInput,
    BulkExportButton,
    ListContext
} from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import {converter, exporter} from '../../utils';
import {ability} from "../../abilityProvider";
import AppAvatarField from "./AppAvatarField";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";


const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    cellButton: { 
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const AppActionButtons = props => (
    <>
        {ability.can('remove', 'App')&&<BulkRemoveButton {...props} />}
        {ability.can('getList', 'App')&&<BulkExportButton {...props} />}
    </>
);

const AppFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const convertTable = {
    id: 'ID',
    name: 'Название',
    description: 'Описание',
    createdAt: 'Создан',
    createdBy: 'Создал',
    updatedAt: 'Обновлен',
    url: 'url адрес',
    users: 'Пользователи',
    avatar: 'Аватар'
}

const AppList = props => {
    const classes = useStyles();
    const controllerProps = useListController(props);
    return (
        <ListContext.Provider value={useListController(props)}>
            <ListView
                filters={<AppFilter/>}
                bulkActionButtons={<AppActionButtons/>}
                {...props}
                {...controllerProps}
                exporter={exporter(controllerProps.defaultTitle, converter(convertTable))}
            >
                <Datagrid rowClick="show" classes={{row: classes.row}}>
                    <AppAvatarField/>
                    <TextField source="name"/>
                    <TextField source="description"/>
                    {ability.can('getList', 'App', 'description') && <TextField source="url"/>}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth}/>
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth}/>
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'User') && <ShowBlankItem/>}
                        {ability.can('update', 'User') && <EditMenuItem/>}
                        {ability.can('remove', 'User') && <RemoveMenuItem/>}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

AppList.defaultProps = {
    filter: {},
    perPage: 10,
    sort: { field: 'name', order: 'ASC' }
};



export default AppList;