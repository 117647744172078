import { URI_PROMETHEUS } from '../../appsettings';

const fetchQuery = (query, uri = URI_PROMETHEUS) => {
    const url = `${uri}/api/v1/${query === 'targets' ? 'targets' : `query?query=${query}`}`;
    return fetch(
        url,
        {
            method: "GET",
            credentials: "omit",
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: "no-referrer-when-downgrade",
            mode: "cors"
        }
    )
        .then(response => {
            if (!response.ok) {
                throw response
            };
            return response.json();
        })
        .then(({ data }) => {
            if (query === 'targets') {
                return data.activeTargets
            } else {
                return data.result;
            }
        })
        .catch(error => {
            throw Error(error)
        })
}

const fetchQueryRange = (query, uri = URI_PROMETHEUS) => {
    const url = `${uri}/api/v1/query_range?query=${query}`;
    return fetch(
        url,
        {
            method: "GET",
            credentials: "omit",
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: "no-referrer-when-downgrade",
            mode: "cors"
        }
    )
        .then(response => {
            if (!response.ok) {
                throw response
            };
            return response.json();
        })
        .then(({ data }) => {
            return data.result;
        })
        .catch(error => {
            throw Error(error)
        })
}

const fetchTargets = (query, uri = URI_PROMETHEUS) => {
    const url = `${uri}/api/v1/targets`;
    return fetch(
        url,
        {
            method: "GET",
            credentials: "omit",
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: "no-referrer-when-downgrade",
            mode: "cors"
        }
    )
        .then(response => {
            if (!response.ok) {
                throw response
            };
            return response.json();
        })
        .then(({ data }) => {
            return data.activeTargets
        })
        .catch(error => {
            throw Error(error)
        })
}





const prometheusFetchClient = (query, uri, type = 'QUERY') => {
    let queryFunc = fetchQuery;
    switch (type) {
        case 'QUERY':
            queryFunc = fetchQuery
            break;
        case 'QUERY_RANGE':
            queryFunc = fetchQueryRange
            break;
        case 'TARGETS':
            queryFunc = fetchTargets
            break;
        default:
            break;
    }
    if (Array.isArray(query)) {
        return Promise.all(query.map(q => queryFunc(q, uri)))
            .then(result => {
                return result
            })
    } else {
        return queryFunc(query, uri)
    }
}

export default prometheusFetchClient;