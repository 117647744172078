import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';
import {useTranslate} from 'react-admin';


const EnumField = ({ className, resource, source, record = {}, option, ...props }) => {
    const translate = useTranslate();
    const enumValue = get(record, source);
    if (!enumValue) {return null};
    
    const value = option ? translate(`${option}.${enumValue}`, {_: enumValue}) : translate(`resources.${resource}.enums.${source}.${enumValue}`, {_: enumValue});
    
    return (
        <Typography
            variant="body2"
            className={className}
            {...sanitizeRestProps(props)}
        >
            {value}
        </Typography>
    )
};

EnumField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

EnumField.defaultProps = {
    addLabel: true,
};

export default EnumField;