import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import EnumChip from '../Fields/EnumChip';
import OnlineChip from '../Fields/OnlineChip';
import {ability} from "../../abilityProvider";


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    chip: {
    },
    chipBanned: {
        color: theme.palette.getContrastText(theme.palette.error.main),
        backgroundColor: theme.palette.error.main,
    } 
}));

const UserBanner = ({classes: classesOverride, className, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const { record, resource } = props;
    if (!record)  {return null}
    const name = record.name;
    const description = record.description;
    const id = record.id;
    const banned = record.banned;
    

    return (
        <Card className={`${classes.root} ${className}`}>
            <div style={{padding: 16}}>
                <Grid container spacing={4}>
                    <Grid item xs >
                        {ability.can('getOne', 'User', 'name')&&<Typography variant="h6">{name}</Typography>}
                        {ability.can('getOne', 'User', 'description')&&<Typography variant="subtitle1" gutterBottom>{description}</Typography>}
                        {ability.can('getOne', 'User', 'online')&&<OnlineChip record={record} />}
                        {banned && <Chip className={`${classes.chip} ${classes.chipBanned}`} label="Заблокирован" size="small" />}
                    </Grid>
                    <Grid item >
                        <EnumChip {...props} source="role" />
                    </Grid>
                </Grid>
            </div> 
        </Card>
    );
};



export default UserBanner;