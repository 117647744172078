import {createHttpLink} from "apollo-link-http";
import {setContext} from "apollo-link-context";
import {getAccessToken} from "../../authPrivider/getAccessToken";
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {PUSH_API_SERVER} from "../../appsettings";


const httpLink = createHttpLink({
    uri: PUSH_API_SERVER,
});

// const authLink = setContext(async (_, { headers }) => {
//
//     const token = await getAccessToken();
//     return {
//         headers: {
//             ...headers,
//             authorization: token ? `Bearer ${token}` : "",
//         }
//     }
// });

// const link = authLink.concat(httpLink);

export default new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

