import React, { cloneElement, Children } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {useTranslate} from 'react-admin';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    card: {
        display: "flex",
        flexDirection: "column",
        height: "480px",
        marginTop: 16,
        marginBottom: 8
    },
    cardHeader: {
        alignItems: "flex-start",
    },
    avatar: {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.24),
    },
    content: {
        display: "flex",
        paddingTop: 0,
        flexGrow: 1,
    },
    
}));

const ChartCard = ({children, classes: classesOverride, className, label, avatar, title, subheader, action, ...props}) => {
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    return (
        <div className={`${classes.root} ${className}`}>
            {label && <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate(label)}</Typography>}
            <Card classes={{root: classes.card}}>
            <CardHeader
                avatar={ cloneElement(avatar, {
                    className: classes.avatar,
                })}
                action={action}
                title={title && translate(title, {_: title})}
                titleTypographyProps={{variant: "h6"}}
                subheader={subheader && typeof subheader === "string" ? translate(subheader, {_: subheader}) : subheader}
                classes={{root: classes.cardHeader}}
            />
                <CardContent classes={{root: classes.content}}>
                    {cloneElement(Children.only(children), {
                        ...props,
                    })}
                </CardContent>
            </Card>
        </div>
        
    );
};


export default ChartCard;