import React from 'react';
import PropTypes from 'prop-types';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord, useTranslate } from 'ra-core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';



const EditButton = ({
    basePath = '',
    label = 'ra.action.edit',
    record,
    icon = defaultIcon,
    ...rest
}) => {
    const translate = useTranslate();

    return (
        <MenuItem
            component={Link}
            to={linkToRecord(basePath, record && record.id)}
            onClick={stopPropagation}
            {...rest}
        >
            <ListItemIcon>
                <ContentCreate fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{translate(label)}</Typography>
        </MenuItem>
    )
};

const defaultIcon = <ContentCreate />;

const stopPropagation = e => e.stopPropagation();

EditButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default EditButton;
