import { CREATE, GET_ONE, GET_LIST, GET_MANY, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import createClient from '../../authPrivider/createClient';
import updateClient from '../../authPrivider/updateClient';
import createServiceClient from "../../authPrivider/createServiceClient";
import updateServiceClient from "../../authPrivider/updateServiceClient";


// eslint-disable-next-line import/no-anonymous-default-export
export default async (type, resource, params) => {

    const { data, previousData } = params;

    if (resource === 'App') {
        switch (type) {
            case CREATE: {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
            }
            case UPDATE: {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
            }
        }
    }

    if (resource === 'FileParserService') {
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core
                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;

                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'FillDataGapsService') {
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core
                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;

                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'AnalyticsService') {
        // eslint-disable-next-line default-case
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;

                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'OpcServer') {
        // eslint-disable-next-line default-case
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;

                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'CallService') {
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;

                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'DataBus') {
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;
                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'Converter') {
        switch (type) {
            case CREATE: {
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createServiceClient({
                    id: data.authId,
                    login: data.login,
                    password: data.password,
                    name: data.name,
                    role: 'SERVICE',
                });

                data.authId = auth.id;
                break;
            }

            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateServiceClient({
                        id: data.authId,
                        login: data.login,
                        password: data.password,
                        name: data.name,
                    })

                    authUser = auth;
                };

                data.authId = authUser.id;

                break;
            }
        }
    }

    if (resource === 'Counterparty') {
        switch (type) {
            case CREATE: {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                break;
            }
            case UPDATE: {
                if (data.usersIds) { data.users = data.usersIds.map(id => ({ id })) }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                break;
            }
            default: break;
        }
    }

    if (resource === 'User') {
        switch (type) {
            case CREATE: {

                if (data.counterparty) { data.counterparty = (data.counterparty.id) ? { id: data.counterparty.id } : null }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                if (data.appsIds) { data.apps = data.appsIds.map(id => ({ id })) }
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createClient({
                    login: data.login,
                    email: data.email,
                    phone: data.phone,
                    name: data.name,
                    password: data.password,
                    role: data.role,
                });

                params.data = {
                    ...data,
                    authId: auth.id,
                    login: auth.login,
                    email: auth.email,
                    phone: auth.phone,
                    name: auth.name,
                    role: auth.role,
                }

                break;
            }
            case UPDATE: {

                if (data.counterparty) { data.counterparty = (data.counterparty.id || previousData.counterparty) ? { id: data.counterparty.id } : null }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                if (data.appsIds) { data.apps = data.appsIds.map(id => ({ id })) }
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createClient({
                        login: data.login,
                        email: data.email,
                        phone: data.phone,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateClient({
                        id: data.authId,
                        login: data.login,
                        email: data.email,
                        phone: data.phone,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                params.data = {
                    ...data,
                    authId: authUser.id,
                    login: authUser.login,
                    email: authUser.email,
                    phone: authUser.phone,
                    name: authUser.name,
                    role: authUser.role,
                }

                break;
            }
            default: break;
        }
    }

    if (resource === 'Profile') {
        switch (type) {
            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createClient({
                        login: data.login,
                        email: data.email,
                        phone: data.phone,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateClient({
                        id: data.authId,
                        login: data.login,
                        email: data.email,
                        phone: data.phone,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                params.data = {
                    ...data,
                    authId: authUser.id,
                    login: authUser.login,
                    email: authUser.email,
                    phone: authUser.phone,
                    name: authUser.name,
                    role: authUser.role,
                }

                break;
            }
            default: break;
        }
    }

    return { type, resource, params };
};