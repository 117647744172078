import React from 'react';
import PropTypes from 'prop-types';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, useTranslate } from 'ra-core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';


const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const ShowButton = React.forwardRef(({
    basePath = '',
    label = 'ra.action.show_blank',
    icon = defaultIcon,
    record,
    ...rest
}, ref) => {
    const translate = useTranslate();

    return (
        <MenuItem
            component={LinkRef}
            to={`${linkToRecord(basePath, record && record.id)}/show`}
            target="_blank"
            ref={ref}
            onClick={stopPropagation}
            {...rest}
        >
            <ListItemIcon>
                {React.cloneElement(icon, {
                    fontSize: "small"
                })}
            </ListItemIcon>
            <Typography variant="inherit">{translate(label)}</Typography>
        </MenuItem>
    )
});

const defaultIcon = <ImageEye />;

const stopPropagation = e => e.stopPropagation();

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default ShowButton;
