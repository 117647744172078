import gql from 'graphql-tag'
import PushApiClient from "./PushApiClient";

export const UNSUBSCRIBE = gql`
    query unsubscribe( $subscription: Json ) {
        data: unsubscribe( subscription: $subscription ) { id endpoint }
    }
`

export default (subscription) => {
    return PushApiClient.mutate({
        query: UNSUBSCRIBE,
        variables: { subscription: subscription },
        onError: (error) => {
            alert(error)
        }
    });
};
