import { useEffect, useState } from 'react'
import prometheusFetchClient from './prometheusFetchClient';
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useVersion } from 'react-admin';

export default function(args) {

  const version = useVersion()
  const [state, setState] = useState({
    data: undefined,
    error: undefined,
    loading: true,
  })

  useDeepCompareEffect(() => {

    const query = args && args.query;
    const uri = args && args.uri;
    if (!query) return;

    prometheusFetchClient(query, uri, 'QUERY')
        .then((data) => {
          setState({
            data: data,
            loading: false,
            loaded: true,
          });
        })
        .catch(error => {
          setState({
            error,
            loading: false,
            loaded: false,
          });
        });

  }, [args, version])

  return state;
}