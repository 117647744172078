import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';
import {useEffect, useState} from "react";
import { useRefresh } from 'react-admin';

export const GET_GAS_METER = gql`
    query GasMeter( $where: GasMeterWhereUniqueInput!) {
        data: GasMeter(where: $where) { id dbId name }
    }
`;

export const getUUG = (args) => {
    return apolloCoreClient.query({
        query: GET_GAS_METER,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};