import React, {useState} from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import {Chart} from "react-google-charts";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "../ChartCard";
import Loading from "../Loading";
import useQueryRange from "../../../dataProvider/Prometheus/useQueryRange";
import BlockIcon from '@material-ui/icons/Block';
import Typography from "@material-ui/core/Typography";
import {useTranslate} from "react-admin";


const useStyles = makeStyles(theme => ({
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    chip: {
        borderRadius: 4,
    },
    periodBar:{
        alignSelf: "center",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
}));

export const LastUpdateChart = ({filter, ...props}) => {
    const { time, job, rangeVector, } = filter
    const classes = useStyles();
    const date = moment(time)
    const translate = useTranslate();
    const convert = {
        ["1h"]: 1,
        ["6h"]: 6,
        ["12h"]: 12,
        ["24h"]: 24,
    }
    const {data, loading, error} = useQueryRange({
        query: [`count(askug_convertItem_convert_lastupdate >time()-${24 * 3600}) / count(askug_convertItem_convert_lastupdate) * 100&start=${date.unix() - convert[rangeVector] * 3600}&end=${date.unix()}&step=${Math.abs(convert[rangeVector] * 14)}`],
    });

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading/>)

    // const handlePeriod = (period) => {
    //     setPeriod(period)
    // }
    const lastUpdateMetrics = data && data.length && data[0][0];
    const metrics = lastUpdateMetrics ? lastUpdateMetrics.values.map(value => ([moment.unix(value[0]).toDate(), Number(value[1])])) : [];

    if (!metrics.length) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon}/>
        </div>
    );

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            {/*<PeriodBar*/}
            {/*    period={period}*/}
            {/*    setPeriod={handlePeriod}*/}
            {/*    className={classes.periodBar}*/}
            {/*    periods={{*/}
            {/*        ["1 час"]: 1,*/}
            {/*        ["6 часов"]: 6,*/}
            {/*        ["12 часов"]: 12,*/}
            {/*        ["Сутки"]: 24,*/}
            {/*    }}*/}
            {/*/>*/}
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    loader={<Loading/>}
                    data={[["", "Процент"], ...(metrics.length ? metrics : [[date.toDate(), 0]])]}
                    options={{
                        hAxis: {
                            titleTextStyle: {color: '#333'},
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            format: 'HH:mm'
                        },
                        vAxis: {
                            minValue: 0,
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                        },
                        legend: {
                            position: "bottom"
                        },
                        chartArea: {
                            top: 10,
                            width: '86%',
                            height: '86%'
                        },
                        lineWidth: 1,
                        explorer: {
                            actions: ['dragToZoom', 'rightClickToReset'],
                            axis: 'horizontal',
                            keepInBounds: true,
                            maxZoomIn: 4.0,
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const LastUpdateMetric = ({ filter, ...props}) => {
    const classes = useStyles();
    return (
        <ChartCard
            avatar={<Avatar variant="rounded"><TrendingUpIcon/></Avatar>}
            title="Metrics.LastUpdate"
            // action={<Chip size="small" label="Конвертер" className={classes.chip} />}
        >
            <LastUpdateChart filter={filter}/>
        </ChartCard>

    );
};

export default LastUpdateMetric;