import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {Datagrid} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import PrometheusClient from "../../dataProvider/Prometheus/PrometheusClient";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SimpleMetricChart from "../Fields/Charts/SimpleMetricChart";
import BarChart from "../Fields/Charts/BarChart";
import HeatMapChart from '../Fields/Charts/HeatMap';
import CardBlankLayout from "../Layout/CardBlankLayout";
import ParsedFilesChart from "../Metrics/ParsedFilesChart";
import SummaryDayChart from "../Metrics/SummaryDayChart";
import MetricsArrayField from "../Layout/MetricsArrayField";

const useStyles = makeStyles(theme => ({
    row: {
        height: 52
    },
    chartContainer: {
        marginTop: "16px",
        marginBottom: "8px"
    }
}));


const FileParserChartsField = ({classes: classesOverride, className, ...props}) => {
    const classes = useStyles();
    const { record } = props;
    if (!record.prometheusJob) {
        return null;
    }
    return (
        <MetricsArrayField>
            <ParsedFilesChart job={record.prometheusJob}/>
            <SummaryDayChart job={record.prometheusJob}/>
        </MetricsArrayField>
    )

};

FileParserChartsField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
};

export default FileParserChartsField;