import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    }
}));

const Loading = (props) => {    
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
};

export default Loading;