import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { getAccessToken } from '../../authPrivider/getAccessToken';
import {split} from "apollo-link";
import {getMainDefinition} from "apollo-utilities";
import {WebSocketLink} from "apollo-link-ws";
import {URI_CONVERTER, WS_CORE} from "../../appsettings";
import getWorkFlows from "./getWorkFlows";
import startWorkflow from "./startWorkflow";
import stopWorkflow from "./stopWorkflow";


class apolloConverterClient {
    constructor(url = URI_CONVERTER) {
        const httpLink = createHttpLink({
            uri: url,
        });

        const authLink = setContext(async (_, { headers }) => {

            const token = await getAccessToken();
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : "",
                }
            }
        });

        const link = authLink.concat(httpLink);

        this.client = new ApolloClient({
            link: link,
            cache: new InMemoryCache(),
        });
    }
    getWorkFlows(){
        return getWorkFlows(this.client);
    }
    startWorkFlow(args){
        return startWorkflow(this.client, args)
    }
    stopWorkFlow(args){
        return stopWorkflow(this.client, args)
    }
}

export default (url) => new apolloConverterClient(url);
