import gql from 'graphql-tag'
import apolloConverterClient from './apolloConverterClient';


const WORKFLOW_START = gql`
    mutation workflowStart($workflowname: String!, $input: String!){
        workflowStart( workflowname: $workflowname, input :$input){
            workflowId
            workflowname
            inWork
        }
    }
`;
/**
 *
 * @param client ApolloClient
 * @param args Object
 * @returns {*|Promise<FetchResult<any>>}
 */
export default (client, args) => {
    return client.mutate({
        mutation: WORKFLOW_START,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
