import React from 'react';
import { Link } from 'react-router-dom';
import { linkToRecord, useTranslate } from 'ra-core';
import Button from '@material-ui/core/Button';



const CancelButton = ({
    basePath = '',
    label = 'ra.action.cancel',
    record,
    redirect = 'show',
}) => {
    const translate = useTranslate()

    return (
        <Button
            component={Link}
            to={`${linkToRecord(basePath, record && record.id)}/${redirect}`}
            onClick={stopPropagation}
            size="small"
        >
            {translate(label)}
        </Button>
    );
};

const stopPropagation = e => e.stopPropagation();

export default CancelButton;
