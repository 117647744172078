import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import AppAvatarField from "./AppAvatarField";
import {ability} from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
}));

const AppBanner = ({classes: classesOverride, className, record, ...props}) => {
    const classes = useStyles({ classes: classesOverride });

    if (!record) return null;
    const name = record.name;
    const description = record.description;
    return (
        <Card className={`${classes.root} ${className}`}>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs >
                        <Typography variant="h6" gutterBottom>{name}</Typography>
                        <Typography variant="subtitle1" gutterBottom>{description}</Typography>
                    </Grid>
                    <Grid item >
                        <AppAvatarField record={record}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};



export default AppBanner;