import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useQuery from '../../../dataProvider/Prometheus/useQuery';
import MetricsCard from "../MetricsCard";
import Loading from "../Loading";
import useTargets from "../../../dataProvider/Prometheus/useTargets";


const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textBold: {
        fontWeight: "bold"
    },
    mtAuto: {
        marginTop: "auto"
    }
}));

export const Instance = ({filter}) => {
    const { job } = filter
    const classes = useStyles();
    const {data, loading, error} = useTargets({});

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    let all = 0;
    let up = 0;
    if (!data || !data.length) {return (<Typography variant="subtitle1" color="textSecondary" display="inline">Нет данных</Typography>)}
    data.forEach((inst) => {
        if (inst.discoveredLabels.job === job){
            all++;
            if (inst.health === 'up') {
                up++;
            }
        }
    });
    // const perDay = data && data.length && data[1][0] && data[1][0].value[1];

    return (
        <div className={classes.root}>
            <span><Typography variant="h4" display="inline" className={classes.textBold}>{up}</Typography><Typography display="inline">{` / ${all}`}</Typography></span>
            <Typography variant="caption" color="textSecondary" className={classes.mtAuto}>В работе / Всего</Typography>
        </div>

    );
};

const InstanceCounter = (props) => {
    const { record } = props;
    return (
        <MetricsCard title="Metrics.Instance" {...props}>
            <Instance job={record.prometheusJob}/>
        </MetricsCard>
    );
};

export default InstanceCounter;