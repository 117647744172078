import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTranslate } from 'react-admin'
import {makeStyles} from "@material-ui/core/styles";
import MetricsToolbar from "./MetricsToolbar";

const useStyles = makeStyles(theme => {

    return {
        column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flexStart',
            marginTop: theme.spacing(2),
        },
        textField: {
            // marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            // width: 200,
        },
        toolBar: {
            // marginBottom: theme.spacing(1),
        },
        filters: {
            display: "flex",
            alignItems: "center"
        }
    }
});


const MetricDefaultFilters = ({ filter, setFilter, withoutPeriods, periods, ...props}) => {
    const { time, rangeVector } = filter;
    const translate = useTranslate();
    const classes = useStyles();


    const handleClickPeriod = (event) => {
        setFilter({
            ...filter,
            rangeVector: event.currentTarget.value,
        })
    }

    const handleChangeDate = (event) => {
        setFilter({
            ...filter,
            time: event.target.value,
        })
    };
    return (
        <div className={classes.filters}>
            <TextField
                id="datetime-local"
                label={translate('ra.action.input_date')}
                type="datetime-local"
                value={time}
                size="small"
                variant={"filled"}
                className={classes.textField}
                onChange={handleChangeDate}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {!withoutPeriods && Object.keys(periods).map(key => {
                const value = periods[key];
                return (
                    <Button
                        key={value}
                        size={"small"}
                        variant={value === rangeVector ? "contained" : "text"}
                        disableElevation
                        value={value}
                        onClick={handleClickPeriod}
                    >
                        {key}
                    </Button>
                );
            })}
        </div>
    )
}

MetricDefaultFilters.defaultProps = {
    periods: {
        ["1 час"]: "1h",
        ["6 часов"]: "6h",
        ["12 часов"]: "12h",
        ["Сутки"]: "24h",
    },
};

export default MetricDefaultFilters;