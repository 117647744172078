import React, { useState } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "./ChartCard";
import Loading from "./Loading";
import getAllConverterJobs from "../../dataProvider/Core/getAllConverterJobs";
import PeriodBar from "./PeriodBar";
import useQuery from "../../dataProvider/Prometheus/useQuery";
import { URI_PROMETHEUS } from "../../appsettings";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BlockIcon from '@material-ui/icons/Block';
import { useTranslate } from "react-admin";


const useStyles = makeStyles(theme => ({
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    chip: {
        borderRadius: 4,
    },
    periodBar: {
        alignSelf: "center",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
}));

export const CronGuage = ({ filter, ...props }) => {
    const { time, rangeVector } = filter;
    const classes = useStyles();
    const translate = useTranslate();
    const jobs = getAllConverterJobs();
    const date = moment(time)
    const { data, loading, error } = useQuery({
        query: [`askug_convert_convert_workflow_windows_guage${jobs.data ? '{job=~' + `"${jobs.data.map(el => el.prometheusJob).join('|')}"}` : ''}[${rangeVector}]&time=${date.unix()}`],
        uri: URI_PROMETHEUS,
    });

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    // const handlePeriod = (period) => {
    //     setPeriod(period)
    // }
    const cronGuageMetric = data && data.length && data[0][0];
    const metrics = cronGuageMetric ? cronGuageMetric.values.map(value => ([moment.unix(value[0]).toDate(), Number(value[1])])) : [];
    if (!metrics.length) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            {/*<PeriodBar*/}
            {/*    period={period}*/}
            {/*    setPeriod={handlePeriod}*/}
            {/*    className={classes.periodBar}*/}
            {/*/>*/}
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    loader={<Loading />}
                    data={[["", "Окон"], ...(metrics.length ? metrics : [[date, 0]])]}
                    options={{
                        hAxis: {
                            titleTextStyle: { color: '#333' },
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            format: 'HH:mm'
                        },
                        vAxis: {
                            minValue: 0,
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                        },
                        legend: {
                            position: "bottom"
                        },
                        chartArea: {
                            top: 10,
                            width: '86%',
                            height: '86%'
                        },
                        lineWidth: 1,
                        explorer: {
                            actions: ['dragToZoom', 'rightClickToReset'],
                            axis: 'horizontal',
                            keepInBounds: true,
                            maxZoomIn: 4.0,
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const CronGuageChart = ({ filter, ...props }) => {
    const classes = useStyles();

    return (
        <>
            <ChartCard
                avatar={<Avatar variant="rounded" ><TrendingUpIcon /></Avatar>}
                title="Metrics.CronGuage"
                action={<Chip size="small" label="Конвертер" className={classes.chip} />}
            >
                <CronGuage filter={filter} />
            </ChartCard>
        </>

    );
};

export default CronGuageChart;