import React from 'react';
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useQuery from '../../../dataProvider/Prometheus/useQuery';
import MetricsCard from "../MetricsCard";
import Loading from "../Loading";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textBold: {
        fontWeight: "bold"
    },
    mtAuto: {
        marginTop: "auto"
    }
}));

export const Runs = ({filter}) => {
    const {time, job} = filter;
    const classes = useStyles();
    const translate = useTranslate();
    const date = moment(time);
    const {data, loading, error} = useQuery({
        query: [
            `sum(count_cron_runs{job="${job}"})&time=${date.unix()}`,
            `round(delta(count_cron_runs{job="${job}"} [1d])) > 0&time=${date.unix()}`,
        ]
    });

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    const total = data && data.length && data[0][0] && data[0][0].value[1];
    const perDay = data && data.length && data[1][0] && data[1][0].value[1];
    if (!total) {
        return (<Typography variant="subtitle1" color="textSecondary" display="inline">{translate("Metrics.NoData")}</Typography>)
    }

    return (
        <div className={classes.root}>
            <span><Typography variant="h4" display="inline" className={classes.textBold}>{perDay || 0}</Typography><Typography display="inline">{` / ${total}`}</Typography></span>
            <Typography variant="caption" color="textSecondary" className={classes.mtAuto}>{translate("Metrics.DataBusToXml.TitleAll")}</Typography>
        </div>
    );
};

const RunsCounter = (props) => {
    return (
        <MetricsCard title="Metrics.EventCount" {...props}>
            <Runs />
        </MetricsCard>
    );
};

export default RunsCounter;