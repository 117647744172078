import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';



const styles = makeStyles(theme => ({
    container:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: "3rem",
        paddingBottom: "3rem",

    },
    title:{
        maxWidth: 256
    },
    subtitle:{
        maxWidth: 256
    },
    mt3:{
        marginTop: "1rem"
    },

}));


const InfoBanner = ({ children, title, subtitle, action, style }) => {
    const classes = styles();
    const translate = useTranslate();
    return (
    <div className={classes.container} style={style}>
        {children}
        <Typography variant="title" align="center" gutterBottom className={classes.mt3}>{translate(title, { _: title })}</Typography>
        {subtitle && <Typography variant="subheading" color="textSecondary" align="center" gutterBottom className={`${classes.subtitle}`} >{translate(subtitle, { _: subtitle })}</Typography>}
        {action}
    </div>
)};

InfoBanner.defaultProps = {
    title: null,
    subtitle: null,
    action: null,

};

InfoBanner.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    action: PropTypes.node,
};

export default InfoBanner;