import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import JsonInput from "../Inputs/JsonInput";
import PasswordInput from '../Inputs/PasswordInput';
import { ability } from "../../abilityProvider";


// const useStyles = makeStyles(theme => ({

// }));

const AnalyticsServiceCreate = props => {
    // const classes = useStyles();

    const validatePassword = [required()];

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {ability.can('create', 'AnalyticsService', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {ability.can('create', 'AnalyticsService', 'description') && <TextInput source="description" multiline fullWidth />}
                {ability.can('create', 'AnalyticsService', 'login') && <TextInput source="login" validate={required()} fullWidth />}
                {ability.can('create', 'AnalyticsService', 'password') && <PasswordInput source="password" validate={validatePassword} />}
                {ability.can('create', 'AnalyticsService', 'url') && <TextInput source="url" validate={required()} fullWidth />}
                {ability.can('create', 'AnalyticsService', 'prometheusJob') && <TextInput source="prometheusJob" />}
                {ability.can('create', 'AnalyticsService', 'meta') && <JsonInput source="meta" />}
            </SimpleForm>
        </Create>
    );
};

export default AnalyticsServiceCreate;