import React from 'react';
import {useShowController, TextField, DateField} from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import DataBusBanner from './DataBusBanner';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";
import MetricsContainer from "../Layout/MetricsContainer";
import TotalMessagesCounter from "../Metrics/DataBusCounters/TotalMessagesCounter";
import AmountOfTimeCounter from "../Metrics/DataBusCounters/AmountOfTimeCounter";
import RpsCounter from "../Metrics/DataBusCounters/RpsCounter";
import SnapshotCounter, {Snapshot} from "../Metrics/DataBusCounters/SnapshotCounter";
import MetricsArrayField from "../Layout/MetricsArrayField";
import MessageCountMetric from "../Metrics/DataBusCharts/MessageCountCharts";
import EventCountMetric from "../Metrics/DataBusCharts/EventCountChart";
import MetricsLayout from "../Layout/MetricsLayout";
import MetricsToolbar from "../Layout/MetricsToolbar";
import MetricDefaultFilters from "../Layout/MetricsDefaultFilters";



const DataBusShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showMetricsButton = React.useRef(null);

    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            <DataBusBanner />
            <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/>}>
                <MetricsContainer xs={6} sm={4} md={3} xl={2}>
                    <TotalMessagesCounter/>
                    {/*<AmountOfTimeCounter/>*/}
                    <RpsCounter/>
                    <SnapshotCounter/>
                </MetricsContainer>
            </MetricsLayout>

            <Separator action={(<div ref={showMetricsButton} ></div>)}/>

            <MoreContent showMoreButton={showMetricsButton} label="resources.DataBus.fields.more_metrics">
                <MetricsLayout>
                    <MetricsContainer xs={6}>
                        <MessageCountMetric/>
                        <EventCountMetric/>
                    </MetricsContainer>
                </MetricsLayout>
            </MoreContent>

            <CardShowLayout>
                {ability.can('getOne', 'DataBus', 'login')&&<TextField source="login" />}
                {ability.can('getOne', 'DataBus', 'url')&&<TextField source="url" />}
            </CardShowLayout>

            {ability.can('getOne', 'DataBus', 'meta')&&<CardShowLayout>
                <JsonField source="meta"/>
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'DataBus', 'id')&&<TextField source="id" />}
                    {ability.can('getOne', 'DataBus', 'createdAt')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'DataBus', 'createdBy')&&<TextField source="createdBy.name" label="resources.DataBus.fields.createdBy" />}
                    {ability.can('getOne', 'DataBus', 'updatedAt')&&<DateField source="updatedAt" />}
                    {ability.can('getOne', 'DataBus', 'prometheusJob')&&<TextField source="prometheusJob" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default DataBusShow;