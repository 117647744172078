import Icon from '../Icons/FileParserService';
import FileParserServiceList from './FileParserServiceList';
import FileParserServiceCreate from './FileParserServiceCreate';
import FileParserServiceEdit from './FileParserServiceEdit';
import FileParserServiceShow from './FileParserServiceShow';

const FileParserServiceIcon = Icon;


export {
    FileParserServiceIcon,
    FileParserServiceList,
    FileParserServiceShow,
    FileParserServiceCreate,
    FileParserServiceEdit,
};
