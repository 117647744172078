import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListController,
    ListView,
    DateField,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    TextInput,
    ListContext
} from 'react-admin';
import EnumField from '../Fields/EnumField';
import {ability} from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
}));

const HistoryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

export const DefaultDatagrid = (props) => {
    const classes = useStyles();
    return (
    <Datagrid {...props} rowClick="show" classes={{row:classes.row}}>
        <EnumField source="action" noWrap/>
        <EnumField source="resource" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="createdBy.name" label="resources.History.fields.createdBy"/>
        <DateField source="createdAt" textAlign="right"/>
    </Datagrid>
    );
}

export const ReferenceDatagrid = (props) => {
    const classes = useStyles();
    return (
    <Datagrid {...props} rowClick="show" classes={{row:classes.row}}>
        <EnumField source="action" noWrap/>
        <EnumField source="resource" />
        <TextField source="name" />
        <TextField source="createdBy.name" label="resources.History.fields.createdBy"/>
        <DateField source="createdAt" textAlign="right"/>
    </Datagrid>
    );
}





const HistoryList = props => {
    const filter = {resource_in: ['App', 'FileParserService', 'OpcServer', 'DataBus', 'User', 'Converter', 'CallService', 'FillDataGapsService'].filter((resource) => ability.can('getList', resource))};
    const controllerProps = useListController({...props, filter});
    const classes = useStyles();
    return (
        <ListContext.Provider value={controllerProps}>
            <ListView
                filters={<HistoryFilter/>}
                exporter={false}
                {...props}
                {...controllerProps}
            >
                <DefaultDatagrid/>
            </ListView>
        </ListContext.Provider>
    )
};

HistoryList.defaultProps = {
    perPage: 10,
    sort: { field: 'name', order: 'ASC' },
    bulkActionButtons: false,
};


export default HistoryList;