import Icon from '../Icons/DataBus';
import DataBusList from './DataBusList';
import DataBusCreate from './DataBusCreate';
import DataBusEdit from './DataBusEdit';
import DataBusShow from './DataBusShow';

const DataBusIcon = Icon;


export {
    DataBusIcon,
    DataBusList,
    DataBusShow,
    DataBusCreate,
    DataBusEdit,
};
