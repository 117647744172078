import ArchiveIcon from '@material-ui/icons/Archive';
import DataBusToXmlServiceList from './DataBusToXmlServiceList';
import DataBusToXmlServiceCreate from './DataBusToXmlServiceCreate';
import DataBusToXmlServiceEdit from './DataBusToXmlServiceEdit';
import DataBusToXmlServiceShow from './DataBusToXmlServiceShow';

const DataBusToXmlServiceIcon = ArchiveIcon;


export {
    DataBusToXmlServiceIcon,
    DataBusToXmlServiceList,
    DataBusToXmlServiceShow,
    DataBusToXmlServiceCreate,
    DataBusToXmlServiceEdit,
};
