import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import useQuery from '../../../dataProvider/Prometheus/useQuery';
import Loading from "../Loading";
import { useTranslate } from 'react-admin';
import moment from "moment";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles(theme => ({
    card: {
        marginTop: 16,
        marginBottom: 8
    },
    cardHeader: {
        alignItems: "flex-start",
    },
    cardContent: {
        paddingTop: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.24),
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
    chip: {
        borderRadius: 4,
    }
}));



const LastUpdateProgressBar = ({ filter, ...props }) => {
    const { time, rangeVector } = filter;
    const classes = useStyles();
    const translate = useTranslate();
    const date = moment(time)
    const convert = {
        ['24h']: 24,
        ['12h']: 12,
        ['6h']: 6,
        ['1h']: 1,
    };

    const { data, loading, error } = useQuery({
        query: [
            `count(askug_convertItem_convert_lastupdate > time() - ${3600 * convert[rangeVector]}) / count(askug_convertItem_convert_lastupdate) * 100&time=${date.unix()}&_=${moment().endOf('day').unix()}`,
        ]
    });

    if (error) return ("error");
    if (loading) return (<Loading />)

    const today = data && data.length && data[0][0] && data[0][0].value[1] || 0;

    return (
        <Card classes={{ root: classes.card }}>
            <CardHeader
                avatar={
                    <Avatar variant="rounded" aria-label="recipe" className={classes.avatar}>
                        <DataUsageIcon />
                    </Avatar>
                }
                title="Загружено данных"
                titleTypographyProps={{ variant: "h6" }}
                action={<Chip size="small" label="Конвертер" className={classes.chip} />}
                classes={{ root: classes.cardHeader }}
            />
            <CardContent classes={{ root: classes.cardContent }}>
                <Box position="relative" display="inline-flex">
                    <Box position="relative" display="inline-flex">
                        <CircularProgress
                            variant="determinate"
                            className={classes.bottom}
                            size={128}
                            thickness={4}
                            value={100}
                        />
                        <CircularProgress
                            variant="determinate"
                            className={classes.top}
                            classes={{ circle: classes.circle }}
                            size={128}
                            thickness={4}
                            value={Number(today)}
                            color={Number(today) > 70 ? 'primary' : 'secondary'}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h5" component="div" color="textSecondary">{`${Number(today).toFixed(0)}%`}</Typography>
                            <Typography variant="caption" component="div" color="textSecondary">Загужено</Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

LastUpdateProgressBar.defaultProps = {
    filter: {
        time: moment().format('YYYY-MM-DDTHH:mm'),
        rangeVector: '24h'
    }
}


export default LastUpdateProgressBar;