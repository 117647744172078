import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { linkToRecord } from 'ra-core';
import { Link } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -theme.spacing(1),
        marginBottom: -theme.spacing(1),
    },
}));

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();




const handleClick = () => {};

function NestedArrayField({
    addLabel,
    classes: classesOverride,
    className,
    record,
    source,
    resource,
    reference,
    basePath,
    children,
    linkType,
    ...rest
}) {
    const classes = useStyles({ classes: classesOverride });
    const data = get(record, source);
    if (!data ) {return null}


    return (
        <div
            className={classnames(classes.root, className)}
            {...sanitizeRestProps(rest)}
        >
            {data.map(item => {
                const rootPath = basePath.replace(resource, reference);
                const resourceLinkPath = !linkType
                    ? false
                    : linkToRecord(rootPath, item.id, linkType);

                if (resourceLinkPath) {
                    return (
                        <Link
                            className={classes.link}
                            key={item.id}
                            to={resourceLinkPath}
                            onClick={stopPropagation}
                        >
                            {cloneElement(Children.only(children), {
                                record: item,
                                resource,
                                basePath,
                                // Workaround to force ChipField to be clickable
                                onClick: handleClick,
                            })}
                        </Link>
                    );
                }

                return cloneElement(Children.only(children), {
                    key: item.id,
                    record: item,
                    resource,
                    basePath,
                });
            })}
        </div>
    );
}

NestedArrayField.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        .isRequired,
    resource: PropTypes.string,
};

NestedArrayField.defaultProps = {
    classes: {},
    linkType: 'edit',
    addLabel: true,
};

export default NestedArrayField;
