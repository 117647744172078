import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorsPerCorrectorHeatMap from "../Metrics/CallServiceCharts/ErrorsPerCorrectorHeatMap";
import LastSuccessHeatMap from "../Metrics/CallServiceCharts/LastSuccessHeatMap";
import Chip from "@material-ui/core/Chip";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles(theme => ({
    row: {
        height: 52
    },
    chartContainer: {
        marginTop: "16px",
        marginBottom: "8px"
    },
    chip: {
        marginRight: theme.spacing(1),
        borderRadius: "5px"
    }
}));

const PollErrSimpleChartConverter = (metrics) => {
    const result = [];
    return result;
};

const CallServiceChartsField = ({classes: classesOverride, className, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { record } = props;
    if (!record.prometheusJob) {
        return null;
    }
    return (
        <Grid container spacing={3} className={classes.chartContainer} >
            <Grid item xs={12} >
                <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate('Metrics.ErrorsPerCorrector')}</Typography>
                <Paper style={{overflow: "hidden"}} >
                    <ErrorsPerCorrectorHeatMap id='ErrorsPerCorrector' source="metric" job={record.prometheusJob}/>
                </Paper>
                <Toolbar>
                    <Chip className={classes.chip} size="small" style={{background: '#f44b36'}} label='Больше 100'/>
                    <Chip className={classes.chip} size="small" style={{background: '#f4af36'}} label='Больше 0'/>
                    <Chip className={classes.chip} size="small" style={{background: '#4caf36'}} label='Меньше 0'/>
                </Toolbar>
            </Grid>
            <Grid item xs={12} >
                <Typography variant="subtitle2" color="textSecondary" gutterBottom noWrap>{translate('Metrics.LastSuccess')}</Typography>
                <Paper style={{overflow: "hidden"}} >
                    <LastSuccessHeatMap id="LastSuccess" source="metric" job={record.prometheusJob}/>
                </Paper>
                <Toolbar>
                    <Chip className={classes.chip} size="small" style={{background: '#f44b36'}} label='Больше 100 часов'/>
                    <Chip className={classes.chip} size="small" style={{background: '#f4af36'}} label='Больше 10 часов'/>
                    <Chip className={classes.chip} size="small" style={{background: '#4caf36'}} label='Меньше 0'/>
                </Toolbar>
            </Grid>
        </Grid>
    )

};

CallServiceChartsField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
};

export default CallServiceChartsField;