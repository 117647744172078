import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListController,
    ListView,
    Datagrid,
    DateField,
    TextField,
    Filter,
    SearchInput,
    BulkExportButton,
    ListContext
} from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import {converter, exporter} from '../../utils';
import UpField from "../Fields/UpField";
import InstanceField from "../Fields/InstancesField";
import PrometheusClient from "../../dataProvider/Prometheus/PrometheusClient";
import {ability} from "../../abilityProvider";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    cellButton: { 
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const DataBusToXmlServiceBulkActionButtons = props => (
    <>
        {ability.can('remove', 'DataBusToXmlService')&&<BulkRemoveButton {...props} />}
        {ability.can('getList', 'DataBusToXmlService')&&<BulkExportButton {...props} />}
    </>
);

const DataBusToXmlServiceFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const convertTable = {
    DataBusToXmlService: {
        id: 'ID',
        name: 'Название',
        description: 'Описание',
        createdAt: 'Создан',
        createdBy: 'Создал',
        updatedAt: 'Обновлен',
        login: 'Логин',
        meta: 'Конфигурация',
        password: 'Пароль',
        url: 'url адрес',
        up: 'Инстансы (работает/всего)',
        metrics: 'Метрики',
        metric: 'Метрика',
        instances: 'Инстансы'
    }
}

const DataBusToXmlServiceList = props => {
    const classes = useStyles();
    const controllerProps = useListController(props);
    const [instances, setInstances] = useState([])
    useEffect(() => {
        new PrometheusClient().getAllActiveInstances()
            .then((data) => {
                setInstances(data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [setInstances]);

    return (
        <ListContext.Provider value={useListController(props)}>
            <ListView
                filters={<DataBusToXmlServiceFilter/>}
                bulkActionButtons={<DataBusToXmlServiceBulkActionButtons/>}
                exporter={exporter(controllerProps.defaultTitle, converter(convertTable))}
                {...props}
                {...controllerProps}
            >
                <Datagrid rowClick="show" classes={{row: classes.row}}>
                    <UpField instances={instances} source="status" cellClassName={classes.cellMinWidth}/>
                    <TextField source="name"/>
                    <TextField source="description"/>
                    {ability.can('getList', 'DataBusToXmlService', 'authId') && <InstanceField instances={instances} source="up"/>}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth}/>
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth}/>
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'User') && <ShowBlankItem/>}
                        {ability.can('update', 'User') && <EditMenuItem/>}
                        {ability.can('remove', 'User') && <RemoveMenuItem/>}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

DataBusToXmlServiceList.defaultProps = {
    filter: {},
    perPage: 10,
    sort: { field: 'name', order: 'ASC' }
};



export default DataBusToXmlServiceList;