import {URI_FILL_DATA_GAPS_SERVICE} from "../../appsettings";
import StartFillDataGapsService from "./startFillDataGapsService";
import StopFillDataGapsService from "./stopFillDataGapsService";
import UpdateConfigFillDataGapsService from "./updateConfigFillDataGapsService";
import StatusFillDataGapsService from "./statusFillDataGapsService";

class FillDataGapsServiceClient {
    constructor(url = URI_FILL_DATA_GAPS_SERVICE) {
        this.url = url;
    }

    start(){
        return StartFillDataGapsService(this.url);
    }
    stop(){
        return StopFillDataGapsService(this.url);
    }
    updateConfig(){
        return UpdateConfigFillDataGapsService(this.url);
    }
    status(){
        return StatusFillDataGapsService(this.url);
    }
}

export default (url) => new FillDataGapsServiceClient(url);