import React, {useEffect} from 'react';
import {useShowController, TextField, DateField} from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import FillDataGapsServiceBanner from './FillDataGapsServiceBanner';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";
import FillDataGapsServiceClient from "../../dataProvider/FillDataGapsService/FillDataGapsServiceClient";
import MetricsContainer from "../Layout/MetricsContainer";
import DeviceScannerCounter from "../Metrics/FillDataGapsServiceCounters/DeviceScannerCounter";
import NewIntervalCounter from "../Metrics/FillDataGapsServiceCounters/NewIntervalCounter";
import RegetSuccessIntervalCounter from "../Metrics/FillDataGapsServiceCounters/RegetSuccessIntervalCounter";
import RegetIntervalInProgressCounter from "../Metrics/FillDataGapsServiceCounters/RegetIntervalInProgressCounter";
import VoidDataDeviceCounter from "../Metrics/FillDataGapsServiceCounters/VoidDataDeviceCounter";
import FillDataGapsServiceControlLayout from "./FillDataGapsServiceСontrolLayout";
import StatusCard from "../Metrics/FillDataGapsServiceCounters/Status";
import MetricsArrayField from "../Layout/MetricsArrayField";
import MessageCountMetric from "../Metrics/DataBusCharts/MessageCountCharts";
import EventCountMetric from "../Metrics/DataBusCharts/EventCountChart";
import DataGapsChart from "../Metrics/FillDataGapsServiceCharts/DataGapsChart";
import MetricsLayout from "../Layout/MetricsLayout";
import MetricsToolbar from "../Layout/MetricsToolbar";
import MetricDefaultFilters from "../Layout/MetricsDefaultFilters";



const FillDataGapsServiceShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showMetricsButton = React.useRef(null);

    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            <FillDataGapsServiceBanner />

            <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/>}>
                <MetricsContainer xs={6} sm={4} md={3} xl={2}>
                    <StatusCard/>
                    <DeviceScannerCounter/>
                    <VoidDataDeviceCounter/>
                    <NewIntervalCounter/>
                    <RegetSuccessIntervalCounter/>
                    <RegetIntervalInProgressCounter/>
                </MetricsContainer>
            </MetricsLayout>

            <Separator action={(<div ref={showMetricsButton} ></div>)}/>

            <MoreContent showMoreButton={showMetricsButton} label="resources.DataBus.fields.more_metrics">
                <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/> }>
                    <DataGapsChart/>
                </MetricsLayout>
            </MoreContent>

            <FillDataGapsServiceControlLayout label="resources.FillDataGapsService.fields.control_cadence"/>

            <CardShowLayout>
                {ability.can('getOne', 'FillDataGapsService', 'login')&&<TextField source="login" />}
                {ability.can('getOne', 'FillDataGapsService', 'url')&&<TextField source="url" />}
            </CardShowLayout>

            {ability.can('getOne', 'FillDataGapsService', 'meta')&&<CardShowLayout>
                <JsonField source="meta"/>
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'FillDataGapsService', 'id')&&<TextField source="id" />}
                    {ability.can('getOne', 'FillDataGapsService', 'createdAt')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'FillDataGapsService', 'createdBy')&&<TextField source="createdBy.name" label="resources.FillDataGapsService.fields.createdBy" />}
                    {ability.can('getOne', 'FillDataGapsService', 'updatedAt')&&<DateField source="updatedAt" />}
                    {ability.can('getOne', 'FillDataGapsService', 'prometheusJob')&&<TextField source="prometheusJob" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default FillDataGapsServiceShow;