import Icon from '../Icons/Apps';
import AppList from './AppList';
import AppCreate from './AppCreate';
import AppEdit from './AppEdit';
import AppShow from './AppShow';

const AppIcon = Icon;


export {
    AppIcon,
    AppList,
    AppShow,
    AppCreate,
    AppEdit,
};
