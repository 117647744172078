import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    root: {
    },
    up: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.getContrastText(theme.palette.success.main),
    },
    down: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText(theme.palette.error.main),
    }
}));


const UpField = ({record, instances, ...props}) => {
    const classes = useStyles();
    if (!(instances && instances.length > 0)) return null;
    const isUp = instances.some(instance => {
        if (instance.discoveredLabels.job === record.prometheusJob){
            if (instance.health === 'up') {
                return true;
            }
        }
        return false;
    });
    const label = isUp ? "Активен" : "Неактивен";
    return <Chip
        size="small"
        classes={{root: classes.root}}
        className={isUp ? classes.up : classes.down}
        label={label}
    />;
}

UpField.defaultProps = {
    addLabel: false,
};

export default UpField;