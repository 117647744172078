import gql from 'graphql-tag'
import { apolloAuthClientWithAuth } from './apolloAuthClient';



// const UPDATE_SERVICE = gql`
//     mutation updateService($password: String!, $name: String!, $type: ServiceType!, $url: String!, $login: String!, $meta: Json) {
//         createClient: updateService(password: $password, name: $name, type: $type, url: $url, login: $login, meta: $meta) {
//             id
//             name
//             login
//             url
//             type
//             meta
//         }
//     }
// `;

const UPDATE_SERVICE = gql`
    mutation updateUser($id: ID!, $password: String, $name: String, $login: String) {
        updateClient: updateUser(id:$id, password:$password, name:$name, login: $login) {
            id
            login
            name
            role
        }
    }
`;

export default (args) => {
    return apolloAuthClientWithAuth.mutate({
        mutation: UPDATE_SERVICE,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};



