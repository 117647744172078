import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput, required } from 'react-admin';
import { ability } from "../../abilityProvider";


const useStyles = makeStyles(theme => ({
    referenceArrayInput: {
        marginTop: 8,
        marginBottom: 4,
    },
}));

const AppCreate = props => {
    const classes = useStyles();


    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                <TextInput source="name" validate={required()} fullWidth />
                <TextInput source="description" multiline fullWidth />
                {ability.can('create', 'App', 'url') && <TextInput source="url" validate={required()} fullWidth />}
                {ability.can('create', 'App', 'avatar') && <TextInput source="avatar" fullWidth />}
                {ability.can('create', 'App', 'users') && <ReferenceArrayInput source="usersIds" reference="User" label="resources.App.fields.users" classes={{ container: classes.referenceArrayInput }} fullWidth>
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>}
            </SimpleForm>
        </Create>
    );
};

export default AppCreate;