const openRequest = indexedDB.open('storage', 1);
export let db;
openRequest.onupgradeneeded = () => {
    db = openRequest.result;
    db.createObjectStore('Notification', {
        autoIncrement: true,
        keyPath: 'id',
    });
};


openRequest.onsuccess = function() {
    db = openRequest.result;
};
