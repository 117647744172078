import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import russianMessages  from './ru'



const messages = {
    en: englishMessages,
    ru: russianMessages,
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'ru');