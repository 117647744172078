import gql from 'graphql-tag'



const WORKFLOW_STOP = gql`
    mutation workflowTerminate($workflowid: String!, $reason: String!){
        workflowTerminate( workflowid: $workflowid, reason: $reason )
    }
`;

export default (client, args) => {
    return client.mutate({
        mutation: WORKFLOW_STOP,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
