import gql from 'graphql-tag'
import {useEffect, useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useVersion } from 'react-admin';



const GET_WORK_FLOWS = gql`
    query allOpenWorkflows{
        allOpenWorkflows{
            workflowId
            runId
            startedAt
            workflowname
            inWork
        }
    }
`;

export default (client, args) => {
    const version = useVersion();
    const [state, setState] = useState({
       data: undefined,
       error: undefined,
       loading: true
    });

    useEffect(()=>{
        Promise.resolve(client.query({
            query: GET_WORK_FLOWS,
            variables: { ...args },
            fetchPolicy: 'network-only',
            onError: (error) => {
                alert(error)
            }
        }))
            .then(({data}) => {
                setState({
                    data: data,
                    loading: false,
                    loaded: true,
                });
            })
            .catch(error => {
                setState({
                    error,
                    loading: false,
                    loaded: false,
                });
            });
    }, [version]);
    return state
}

