import decodeJwt from 'jwt-decode';
import jsonExport from 'jsonexport/dist';
import { downloadCSV  } from 'react-admin';
import { get } from  'lodash';


export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {

    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires && options.expires.toUTCString) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};

export const deleteCookie = (name, options = {}) => {
    setCookie(name, "", {
        ...options,
        'max-age': -1
    })
};

const translateJsonKeys = (records, resource, convertTable) => {
    let result = {};
    Object.keys(records).forEach( key => {
        let newKey = key.replace('\.id', '');
        const value = get(convertTable, resource + '.' + key);
        if (!value) {
            return;
        }
        if (records[key] instanceof Array) {
            newKey = newKey.replace('Ids', '');
            if (!result.hasOwnProperty(get(convertTable,resource + '.' + newKey))) {
                result[get(convertTable,resource + '.' + newKey)] = records[key].map(value => {
                    if (typeof value === 'object') {
                        return translateJsonKeys(value, resource, convertTable)
                    } else return value;
                });
            }
        } else if (typeof records[key] === 'object') {
            result[get(convertTable,resource + '.' + newKey)] = translateJsonKeys(records[key], resource, convertTable)
        } else {
            result[get(convertTable,resource + '.' + newKey)] = records[key];
        }
    });
    return result
};

export const converter = (convertTable) => (records, fetchRelatedRecords, resource) => records.map( record => translateJsonKeys(record, resource, convertTable));

export const exporter = (title, converter, options={}) => (records, fetchRelatedRecords, proxy, resource, ...props) => {
    const BOM = "\uFEFF";
    let data;
    if (typeof converter === "function") {
        data = converter(records, fetchRelatedRecords, resource, ...props);
    } else {
        data = records;
    };
    jsonExport(data, {
        rowDelimiter: ";",
        // headers: headers,
        ...options,
    }, (err, csv) => {
        downloadCSV(BOM + csv, title);
    });
};

export const uid = ( prefix="SYS" ) => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = ("0"+ (now.getMonth()+1)).slice(-2);
    const date = ("0"+ now.getDate()).slice(-2);
    const ending = (now.getMilliseconds() + Math.random()).toString(36).slice(2).slice(-6);
    return `${prefix}-${year}${month}${date}-${ending}`.toUpperCase();
};