import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import get from 'lodash/get';


const getInitials = name => {
    const initials = name.split(" ").map(part => part.charAt(0).toUpperCase()).slice(0, 2).join('');

    return initials;
}

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.primary.light,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 13,
    },
    medium: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: "1rem",
        margin: -4,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      
    },
}));


const AvatarField = ({ className, record, name, src, size, ...props}) => {
    
    const classes = useStyles();
    if (!record) return null;
    const fullname = get(record, name);
    const avatar = get(record, src);

    return (
        <Avatar alt={fullname} src={avatar} className={`${classes[size]} ${className}`} classes={{root: classes.root}} >
            {getInitials(fullname)}
        </Avatar>
    ); 
};

AvatarField.defaultProps = {
    addLabel: false,
    size: "medium"
};

export default AvatarField;