import {useEffect, useState} from 'react';
import {useVersion} from 'react-admin';
import {getAccessToken} from "../../authPrivider/getAccessToken";

const StatusFillDataGapsService = (url) => {
    const [state, setState] = useState({
        status: undefined,
        error: undefined,
        loading: true
    });
    const version = useVersion();
    useEffect(()=>{
        const fetchData = async () => {
            const token = await getAccessToken();
            fetch(url+'/api/v1.0/status', {
                method: 'POST',
                credentials: 'omit',
                headers: {
                    authorization: token ? `Bearer ${token}` : "",
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.text();
                    } else {
                        throw new Error(`Request ended with status: ${response.status}`)
                    }
                })
                .then(body => {
                    setState({
                        status: body,
                        loaded: true,
                        loading: false,
                    })
                })
                .catch(error => {
                    setState({loading: false, error: error})
                })
        };
        fetchData();
    }, [version]);
    return state
};

export default StatusFillDataGapsService;