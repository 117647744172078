import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 13,
    },
    medium: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: "1rem",
        margin: -4,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      
    },
}));

const AppAvatarField = ({ record, className, size, ...props}) => {
    const classes = useStyles();
    if (!record) return null;
    const {name, avatar} = record

    return (
        <Avatar variant="rounded" alt={name} src={avatar} className={`${classes[size]} ${className}`}>
            {name.charAt(0).toUpperCase()}
        </Avatar>
    )
};

AppAvatarField.defaultProps = {
    addLabel: false,
    size: "medium"
};

export default AppAvatarField;


