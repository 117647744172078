import React, {Children, cloneElement, isValidElement, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { useTranslate, useVersion } from 'react-admin'
import MetricsToolbar from "./MetricsToolbar";
import MetricDefaultFilters from "./MetricsDefaultFilters";


const useStyles = makeStyles(theme => {

    return {
        column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flexStart',
            marginTop: theme.spacing(2),
        },
        textField: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            // width: 200,
        },
        toolBar: {
            // marginBottom: theme.spacing(1),
        }
    }
});



const MetricsLayout = ({record, children, filters, actions, ...props}) => {
    const [filter, setFilter] = useState({
        time: moment().format('YYYY-MM-DDTHH:mm'),
        rangeVector: '24h',
        job: record && record.prometheusJob
    })
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.column}>
            {
                (filters || actions) && (
                    <MetricsToolbar
                        filters={filters}
                        actions={actions}
                        filter={filter}
                        setFilter={setFilter}
                    />
                )
            }
            <div>
                {Children.map(children, (field, index) => {
                    return isValidElement(field) ? (
                            cloneElement(field, {
                                ...props,
                                filter,
                                record
                            })
                    ) : null

                })}
            </div>
        </div>
    )
};

MetricsLayout.defaultProps = {
    filters: <MetricDefaultFilters/>
};

export default MetricsLayout;