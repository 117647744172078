import russianMessages from 'ra-language-russian'

export default {
    resources: {
        TrashItem: {
            name: 'Корзина',
            menu: 'Корзина',
            fields: {
                id: 'ID',
                resource: 'Ресурс',
                name: 'Название',
                description: 'Описание',
                deletedBy: 'Удалил',
                deletedAt: 'Удален',
            },
            enums: {
                resource: {
                    App: 'Приложение',
                    DataBus: 'Шина данных',
                    FileParserService: 'Файл парсер',
                    OpcServer: 'OPC сервис',
                    User: 'Пользователь',
                    Converter: 'Конвертер',
                    CallService: 'Сервис опроса',
                },
            }
        },
        History: {
            name: 'История',
            menu: 'История',
            fields: {
                id: 'ID',
                resource: 'Ресурс',
                name: 'Название',
                action: 'Действие',
                description: 'Описание',
                'data.data': 'Текущее состояние',
                'data.previous': 'Предыдущее состояние',
                createdAt: 'Дата',
                createdBy: 'Пользователь',
            },
            enums: {
                action: {
                    CREATED: 'Создана',
                    UPDATED: 'Обновлена',
                    DELETED: 'Удалена навсегда',
                    REMOVED: 'Удалена',
                    RESTORED: 'Восстановлена',
                },
                resource: {
                    App: 'Приложение',
                    DataBus: 'Шина данных',
                    FileParserService: 'Файл парсер',
                    OpcServer: 'OPC сервис',
                    User: 'Пользователь',
                    Converter: 'Конвертер',
                    CallService: 'Сервис опроса',
                    FillDataGapsService: 'Сервис заполнения данных'
                },
            },
        },
        Counterparty: {
            name: 'Контрагент |||| Контрагенты',
            menu: 'Контрагент |||| Контрагенты',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                type: 'Тип',
                inn: 'ИНН',
                kpp: 'КПП',
                okpo: 'ОКПО',
                ogrn: 'ОГРН',
                fio: 'Наименование',
                passport: 'Паспорт',
                passdateissue: 'Дата выдачи',
                parentId: 'Родитель',
                addressLegal: 'Юридический адрес',
                addressActual: 'Фактический адрес',
                addressPostal: 'Почтовый адрес',
                phone: 'Телефон',
                phoneMobile: 'Мобильный',
                email: 'Email',
                users: 'Пользователи',
                accessResource: 'Ресурс',
                access: 'Доступ',
                filterGasRegion: 'Фильтр по Регионам',
                filterGasDistrict: 'Фильтр по Участкам',
                filterGasConsumer: 'Фильтр по Потребителям',
                filterGasMeterPad: 'Фильтр по Площадкам',
                accessGasRegion: 'Доступ по Регионам',
                accessGasDistrict: 'Доступ по Участкам',
                accessGasConsumer: 'Доступ по Потребителям',
                accessGasMeterPad: 'Доступ по Площадкам',
                q: 'Поиск',
            },
            enums: {
                type: {
                    LEGALENTITY: 'Юридическое лицо',
                    INDIVIDUAL: 'Физическое лицо',
                    SEPARATEDIVISION: 'Обособленное подразделение',
                    GOVERNMENTAGENCY: 'Гос.орган',
                },
                accessResource: {
                    GasRegion: 'Регион',
                    GasDistrict: 'Участок',
                    GasConsumer: 'Потребитель',
                    GasMeterPad: 'Площадка',
                    GasMeterUnit: 'УУГ',
                },
            },
            tabs: {
                general: 'Основное',
                access: 'Доступ',
            },
            empty: 'Контрагенты отсутствуют',
            invite: 'Вы хотите создать?',
        },
        User: {
            name: 'Пользователь |||| Пользователи',
            menu: 'Пользователь |||| Пользователи',
            fields: {
                id: 'ID',
                name: 'Наименование',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                banned: 'Заблокирован',
                email: 'Email',
                phone: 'Телефон',
                phoneContact: 'Телефон для связи',
                role: 'Роль',
                login: 'Логин',
                apps: 'Приложения',
                regions: 'Регионы',
                password: 'Пароль',
                counterparty: "Контрагент",
                position: "Должность",
                accessResource: 'Ресурс',
                access: 'Доступ',
                filterGasRegion: 'Фильтр по Регионам',
                filterGasDistrict: 'Фильтр по Участкам',
                filterGasConsumer: 'Фильтр по Потребителям',
                filterGasMeterPad: 'Фильтр по Площадкам',
                accessGasRegion: 'Доступ по Регионам',
                accessGasDistrict: 'Доступ по Участкам',
                accessGasConsumer: 'Доступ по Потребителям',
                accessGasMeterPad: 'Доступ по Площадкам',
                q: 'Поиск',
            },
            enums: {
                role: {
                    GUEST: 'Гость',
                    SERVICE: 'Сервис',
                    SUPERADMIN: 'Суперадмин',
                    ADMIN: 'Администратор',
                    ENGINEER: 'Инженер',
                    CONSUMER: 'Потребитель',
                    DISPATCHER: 'Диспетчер'
                },
                accessResource: {
                    GasRegion: 'Регион',
                    GasDistrict: 'Участок',
                    GasConsumer: 'Потребитель',
                    GasMeterPad: 'Площадка',
                    GasMeterUnit: 'УУГ',
                },
            },
            tabs: {
                general: 'Основное',
                access: 'Доступ',
            },
            empty: 'Пользователи отсутствуют',
            invite: 'Вы хотите создать?',
        },
        App: {
            name: 'Приложение |||| Приложения',
            menu: 'Приложение |||| Приложения',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                url: 'url адрес',
                users: 'Пользователи',
                avatar: 'Аватар'
            },
            empty: 'Приложения отсутствуют',
            invite: 'Вы хотите создать?',
        },
        FileParserService: {
            name: 'Файл парсер |||| Файл парсеры',
            menu: 'Файл парсер |||| Файл парсеры',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                up: 'Инстансы (работает/всего)',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                status: 'Статус',
            },
            empty: 'Файл парсеры отсутствуют',
            invite: 'Вы хотите создать?',
        },
        DataBus: {
            name: 'Шина данных |||| Шины данных',
            menu: 'Шина данных |||| Шины данных',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                default_metrics: 'Основные метрики',
                more_metrics: 'Дополнительные метрики',
                url: 'url адрес',
                up: 'Инстансы (работает/всего)',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                status: 'Статус',
            },
            empty: 'Шины данных отсутствуют',
            invite: 'Вы хотите создать?',
        },
        FillDataGapsService: {
            name: 'Сервис поиска пропусков данных |||| Сервисы поиска пропусков данных',
            menu: 'Пропуски данных |||| Пропуски данных',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                up: 'Инстансы (работает/всего)',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                default_metrics: 'Метрики',
                control_cadence: 'Управление',
                status: 'Статус',
            },
            enums: {
                status: {
                    True: "Активен",
                    False: "Отключен",
                }
            },
            empty: 'Сервисы поиска пропусков данных отсутствуют',
            invite: 'Вы хотите создать?',
        },
        Converter: {
            name: 'Конвертер |||| Конвертеры',
            menu: 'Конвертер |||| Конвертеры',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                upInstances: 'Инстансы (работает/всего)',
                default_metrics: 'Основные метрики',
                more_metrics: 'Дополнительные метрики',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                control_cadence: 'Управление',
                status: 'Статус',
            },
            actions: {
                workFlowsNotAccess: 'Процессы недоступны'
            },
            empty: 'Конвертеры отсутствуют',
            invite: 'Вы хотите создать?',
        },
        OpcServer: {
            name: 'OPC сервис |||| OPC сервисы',
            menu: 'OPC сервис |||| OPC сервисы',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                up: 'Инстансы (работает/всего)',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                status: 'Статус',
            },
            empty: 'OPC сервисы отсутствуют',
            invite: 'Вы хотите создать?',
        },
        CallService: {
            name: 'Сервис опроса |||| Сервисы опроса',
            menu: 'Сервис опроса |||| Сервисы опроса',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                default_metrics: 'Основные метрики',
                more_metrics: 'Дополнительные метрики',
                up: 'Инстансы (работает/всего)',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                status: 'Статус',
            },
            empty: 'Сервисы опроса отсутствуют',
            invite: 'Вы хотите создать?',
        },
        DataBusToXmlService: {
            name: 'Экспорт XML',
            menu: 'Экспорт XML',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                upInstances: 'Инстансы (работает/всего)',
                default_metrics: 'Основные метрики',
                more_metrics: 'Дополнительные метрики',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                status: 'Статус',
            }
        },
        AnalyticsService: {
            name: 'Сервис аналитики |||| Сервисы аналитики',
            menu: 'Сервис аналитики |||| Сервисы аналитики',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                login: 'Логин',
                meta: 'Конфигурация',
                password: 'Пароль',
                url: 'url адрес',
                up: 'Инстансы (работает/всего)',
                metrics: 'Метрики',
                metric: 'Метрика',
                instances: 'Инстансы',
                status: 'Статус',
            },
            empty: 'Сервисы аналитики отсутствуют',
            invite: 'Вы хотите создать?',
        },
        Notification: {
            name: 'Уведомление |||| Уведомления',
            tags: {
                NEWORDER: 'Новая заявка',
                EXPIRED: 'Заявка просрочена',
            },
            no_notifications: 'Уведомлений нет',
            you_have_unconfirmed_notifications: 'Есть неподтвержденные уведомления',
            show_notifications: 'Просмотреть уведомления',
        },
        ImportFromAis: {
            name: 'Импорт АИС',
            menu: 'Импорт АИС',
            description: 'Утилита импортирует из Excel файла данные, выгруженные из АИС.',
            button: {
                run: 'Загрузить и синхронизировать'
            }
        },

    },
    dashboard: {
        averageElapsedTime: "Среднее время выполнения",
        guage: "Обработано окон Данных",
        ConvertItem: "Конвертеры",
        startConvertItem: "Конвентеров запущено",
        Convert: "Конвертация",
        Cron: "Cron",
        DBId: "ID базы данных",
        Count: "Число",
        convertItemStartTotal: "Конвертеров запущено базой данных",
        polledWithErr: "Отработанно с ошибкой",
        instances: "Инстсансы",
        convertItemStartTotalCount: 'Обработано',
        guageOne: "Счетчиков",
        convertItemStartSum: "Всего ConvertItems",
        convertItemLastUpdate: "Распределение по давности данных",
        convertStart: "Запусков Convert",
        convertItemTotal: "Всего конвертеров",
        cronStart: "Запусков CRON",
        cronFailed: "Ошибок CRON",
        convertFailed: "Ошибок Convert",
        convertItemFailed: "Ошибок convertItem",
        dcsCorrectorsNeverPolled: "Не отработанно",
        summaryDay: 'summaryDay',
        uniInfo: '_'

    },
    Metrics: {
        CronGuage: "Окон обработанно",
        AverageExecuteTime: "Среднее время выполнения",
        ConverterItemErrors: "Ошибки конвертера",
        ActiveGasMeters: "Корректоров активно",
        ConvertItemAll: "Всего ConvertItem",
        ConvertItemForDay: "ConvertItem за сутки",
        ConvertItemStart: "Корректоров обработанно",
        convertItemLastUpdate: "Распределение по давности данных",
        ConvertStarted: "Запусков Convert",
        ConvertItemTotal: "Всего конвертеров",
        CronStarted: "Запусков CRON",
        CronErrors: "Ошибок CRON",
        ConverterErrors: "Ошибок Convert",
        CorrectorsProcessing: 'Всего корректоров',
        PollingOk: "Опрос без ошибок",
        PollingWithError: "Опрос с ошибками",
        ServerErrors: "Ошибок сервера",
        ErrorsPerCorrector: "Ошибок по каждому корректору",
        LastSuccess: "C момента последнего удачного опроса",
        Instance: "Инстансы",
        DeviceScanner: "Проанализированных приборов",
        VoidDataDevice: "Приборов с пропусками данных",
        NewInterval: "Новых интервалов",
        RegetSuccessInterval: "Восстановленных интервалов",
        RegetIntervalInProgress: "Интервалов в процессе восстановления",
        LastUpdate: 'Загружено данных',
        Status: "Статус",
        NoData: "Нет данных",
        NeverPolled: "Не опрашивалось",
        ZeroStarts: "Нет запусков",
        PolledWithError: "Выполнено с ошибкой",
        TotalMessages: "Записано в БД с последнего запуска",
        MessagesQueue: "Размер очереди на запись в БД",
        Rps: "Скорость записи в БД (сообщ/мс)",
        Snapshot: "Количество уникальных тэгов в снэпшоте",
        EventCount: "Событий",
        DataGaps: 'Пропусков по корректорам',
        AmountOfTime: "Время",
        ParsedFiles: "Обработанно файлов",
        DataBusToXml: {
            GenerateXml: "Сгенерировано xml",
            HoursDays: "Суточные / Часовые",
            TitleAll: "Последние 24 часа / Всего",
        },
        ErrorsCount: 'Ошибок',
        ParsedFilesFields: {
            error_files: 'Ошибок',
            send_error_files: 'Ошибок отправки',
            ok_files: 'Успешно',
            not_supported_files: 'Не поддерживаемые',
            warning_files: 'Предупреждений',
            no_configuration: 'Нет конфигурации'
        },
        summaryDay: 'Дневная сводка',
        RamLoad: {
            name: 'Память',
        },
        CpuLoad: {
            name: 'Процессор',
        },
        FileSystemLoad: {
            name: 'Диск',
        },
        ServerInstances: {
            ['server-1']: 'Сервер 1',
            ['server-2']: 'Сервер 2',
            ['server-3']: 'Сервер 3',
            ['server-4']: 'Сервер 4',
            ['server-5']: 'Сервер 5',
            ['server-6']: 'Сервер 6',
            ['server-7']: 'Сервер 7',
            ['server-8']: 'Сервер 8',
            cpuLoad: 'Процессор',
            ramLoad: 'Память',
            fileSystemLoad: 'Диск',
        }
    },
    menu: {
        groups: {
            default: "",
            resources: "Ресурсы",
            directories: "Справочники",
            access: "Доступ",
            utils: "Утилиты",
        }
    },
    ra: {
        action: {
            ...russianMessages.ra.action,
            unselect: "снять выделение",
            show_blank: "Просмотр в новом окне",
            bulk_actions: "%{smart_count} выбрано |||| %{smart_count} выбрано |||| %{smart_count} выбрано",
            create_and_continue: "Coздать и продолжить",
            save_and_continue: "Сохранить и продолжить",
            show_history: "Показать историю",
            delete: "Удалить навсегда",
            restore: "Восстановить",
            check_all: "Выбрать все",
            trash_open: "Корзина",
            input_date: 'Время',
            start_cron_workflow: "Запустить CRON",
            stop_cron_workflow: "Остановить CRON",
            update_config_fill_data_gaps_service: "Обновить конфигурацию сервиса заполнения",
            no_config_to_upload: "Нет конфигурации для отправки",
            successfully_uploaded: "Запрос на обновление конфигурации успешно отправлен",
            start_fill_data_gaps_service: "Запустить сервис заполнения",
            stop_fill_data_gaps_service: "Остановить сервис заполнения",
            start_converter_workflow: "Запустить CONVERTER",
            stop_converter_workflow: "Остановить CONVERTER",
            more: "Дополнительно",
            print: "Печать",
            upload: "Загрузка",
            one_of_fields_is_required: "Требуется хотя бы одно из полей: Логин, Телефон или Email",
            clear_all: 'Очистить',
            close: 'Закрыть',
            clear: 'Очистить',
            ban: "Заблокировать",
            unban: "Разблокировать",
        },
        boolean: {
            ...russianMessages.ra.boolean,
        },
        page: {
            ...russianMessages.ra.page,
            empty: 'Здесь ничего нет',
            invite: 'Вы хотите создать?',
            removed: "Удалено",
        },
        input: {
            ...russianMessages.ra.input,
            password: {
                toggle_visible: 'Показать',
                toggle_hidden: 'Скрыть',
            },
        },
        message: {
            ...russianMessages.ra.message,
            copy: "Копия от %{from}",
            removed: "Ресурс перемещен в Корзину",
        },
        navigation: {
            ...russianMessages.ra.navigation,
            user_apps: "Приложения"
        },
        auth: {
            ...russianMessages.ra.auth,
            permissions_error: 'Нет доступа',
            role_SERVICE_not_allowed: 'Роль пользователя "Сервис" не подходит',
            Role_GUEST_is_not_allowed: 'Гостевой доступ запрещен. Обратитесь к администратору.',
            ["User is banned"]: 'Пользователь заблокирован. Обратитесь к администратору',
            ['User with such login does not exist.']: 'Пользователь с таким логином не существует',
            ['User with such login and password does not exist.']: 'Пользователь с таким логином и паролем не существует',
            ['Аuthorization error!']: 'Ошибка авторизации!',
        },
        notification: {
            ...russianMessages.ra.notification,
            profile_saved: 'Профиль сохранен',
            moved_to_trash: '1 элемент перемещен в корзину |||| %{smart_count} элементов(а) перемещено в корзину |||| %{smart_count} элементов(а) перемещено в корзину',
            restored_from_trash: '1 элемент восстановлен из корзины |||| %{smart_count} элементов(а) восстановлено из корзины |||| %{smart_count} элементов(а) восстановлено из корзины',
            deleted_from_trash: '1 элемент удален из корзины |||| %{smart_count} элементов(а) удалено из корзины |||| %{smart_count} элементов(а) удалено из корзины',
            cron_started: 'Рабочий процесс CRON %{workflow_id} запущен',
            cron_stopped: 'Рабочий процесс CRON %{workflow_id} остановлен',
            cron_error: 'Конфигурация рабочего процесса CRON не найдена',
            converter_started: 'Рабочий процесс CONVERTER %{workflow_id} запущен',
            converter_stopped: 'Рабочий процесс CONVERTER %{workflow_id} остановлен',
            converter_error: 'Конфигурация рабочего процесса CONVERTER не найдена',
            fill_data_gaps_service_started: 'Сервис заполнения успешно запущен',
            fill_data_gaps_service_stopped: 'Сервис заполнения успешно остановлен',
            databustoxml_error_url: 'Не указан url сервиса'
        },
        validation: {
            ...russianMessages.ra.validation,
            ip_invalid: 'Должен быть в формате 255.255.255.255',
            phone_invalid: 'Должен быть в формате +7 (999) 999-9999',
            password_invalid: 'Пароль должен содержать: \n - минимум 8 латинских символов \n - минимум одну заглавную букву \n - минимум одну строчную букву \n - минимум одну цифру \n - минимум один спецсимвол \n',
        }
    },
};