import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SimpleForm, TextInput, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import RestoreButton from '../Buttons/RestoreButton';
import FileParserServiceBanner from './FileParserServiceBanner';
import JsonInput from "../Inputs/JsonInput";
import PasswordInput from '../Inputs/PasswordInput';
import { ability } from "../../abilityProvider";



const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const FileParserServiceToolbar = props => {
    const classes = useStyles();
    const isDeleted = props.record.deleted;
    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {!isDeleted && <SaveButton />}
            {isDeleted && <RestoreButton />}
            <ToolbarSpacer />
            {!isDeleted && <RemoveButton redirect="show" />}
            {isDeleted && <DeleteButton undoable icon={<DeleteForeverIcon />} />}
        </Toolbar>
    );
}

const FileParserServiceEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <FileParserServiceBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<FileParserServiceToolbar />}>
                    {ability.can('update', 'FileParserService', 'name') && <TextInput source="name" fullWidth />}
                    {ability.can('update', 'FileParserService', 'description') && <TextInput source="description" multiline fullWidth />}
                    {ability.can('update', 'FileParserService', 'login') && <TextInput source="login" fullWidth />}
                    {ability.can('update', 'FileParserService', 'password') && <PasswordInput source="password" />}
                    {ability.can('update', 'FileParserService', 'url') && <TextInput source="url" fullWidth />}
                    {ability.can('update', 'FileParserService', 'prometheusJob') && <TextInput source="prometheusJob" />}
                    {ability.can('update', 'FileParserService', 'meta') && <JsonInput source="meta" />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default FileParserServiceEdit