import React, {Children, cloneElement, isValidElement, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { useTranslate } from 'react-admin'


const useStyles = makeStyles(theme => {

    return {
        column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flexStart',
            marginTop: theme.spacing(2),
        },
        textField: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            // width: 200,
        },
        toolBar: {
            // marginBottom: theme.spacing(1),
        }
    }
});



const MetricsArrayField = ({periods, children, withoutPeriods, ...props}) => {

    const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));
    const [period, setPeriod] = useState('24h')
    const translate = useTranslate();
    const classes = useStyles();
    const handleClickPeriod = (event) => {
        setPeriod(event.currentTarget.value)
    }

    const handleChangeDate = (event) => {
        setDate(event.target.value);
    };

    return (
        <div className={classes.column}>
            <Toolbar className={classes.toolBar}>
                <TextField
                    id="datetime-local"
                    label={translate('ra.action.input_date')}
                    type="datetime-local"
                    value={date}
                    size="small"
                    variant={"filled"}
                    className={classes.textField}
                    onChange={handleChangeDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                { !withoutPeriods && Object.keys(periods).map(key => {
                    const value = periods[key];
                    return (
                        <Button
                            key={value}
                            size={"small"}
                            variant={value === period ? "contained" : "text"}
                            disableElevation
                            value={value}
                            onClick={handleClickPeriod}
                        >
                            {key}
                        </Button>
                    );
                }) }
            </Toolbar>
            <Grid container spacing={2}>
                {Children.map(children, (field, index) => {
                    return isValidElement(field) ? (cloneElement(field, {
                        ...props,
                        date,
                        period
                    })) : null

                                    })}
            </Grid>
        </div>
    )
};

MetricsArrayField.defaultProps = {
    periods: {
        ["1 час"]: "1h",
        ["6 часов"]: "6h",
        ["12 часов"]: "12h",
        ["Сутки"]: "24h",
    },
};

export default MetricsArrayField;