import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import {SnackbarProvider, useSnackbar} from 'notistack';
import NotificationsStack from "../Notifications/NotificationsStack";


const CustomLayout = props => {


    return (
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Layout {...props} appBar={AppBar} menu={Menu}/>
            <NotificationsStack/>
        </SnackbarProvider>
    );
};

export default CustomLayout