import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, getResourceLinkPath } from 'react-admin';
import get from 'lodash/get';
import sanitizeRestProps from './sanitizeRestProps';


const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.link.main,
    },
}));

const stopPropagation = e => e.stopPropagation();

const NestedField = ({
    children,
    className,
    classes: classesOverride,
    basePath,
    record,
    reference,
    resource,
    source, 
    link,
    ...props 
}) =>{
    const classes = useStyles({ classes: classesOverride });
    const referenceRecord = get(record, source);
    if (!referenceRecord) return null;
    const rootPath = basePath.replace(resource, reference);
    const resourceLinkPath = !link ? false : (typeof link === 'function') ? link(record, reference) : `${rootPath}/${encodeURIComponent(referenceRecord.id)}/${link}`;
    

    if (resourceLinkPath) {
        return (
            <Link
                to={resourceLinkPath}
                className={className}
                onClick={stopPropagation}
            >
                {React.cloneElement(children, {
                    className: `${children.props.className} ${classes.link}`,
                    record: referenceRecord,
                    resource: reference,
                    basePath,
                    ...sanitizeRestProps(props),
                })}
            </Link>
        );
    }

    return React.cloneElement(children, {
        className: `${className} ${children.props.className}`,
        record: referenceRecord,
        resource: reference,
        basePath,
        ...sanitizeRestProps(props),
    });
} 


NestedField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    reference: PropTypes.string.isRequired,
    resource: PropTypes.string,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]).isRequired,
};


NestedField.defaultProps = {
    classes: {},
    link: 'show',
    record: {},
    addLabel: true,
};


export default NestedField;
