import React, {useState, useEffect, Fragment} from 'react';
import { useSnackbar } from 'notistack';
import Notification from './Notification';
import useDeepCompareEffect from "use-deep-compare-effect";
import {useStorage} from "../../IndexedDB/usePushNotifications";
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DismissIcon from '@material-ui/icons/ChevronRight';

const getChildren = (tag) => {
    switch (tag) {
        case "NEWORDER":
            return <Notification />;
        
        default:
            return null;
    }
};



const NotificationsStack = ({
    classes,
    ...props
}) => {


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {notificationsToShow} = useStorage();


    useDeepCompareEffect(() => {

        notificationsToShow.forEach(notification => {
            
            const { id, title, body, type } = notification;
            const action = key => (
                <Fragment>
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <DismissIcon/>
                    </IconButton>
                </Fragment>
            );

            enqueueSnackbar(title, {
                key: id,
                persist: false,
                variant: type.toLowerCase(),
                action,
                // children: <Notification notification={notification}/>
            });
        });
    }, [notificationsToShow]);

    return null;

};

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications.items,
    }
};

export default NotificationsStack;