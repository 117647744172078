import Icon from '../Icons/CallService';
import CallServiceList from './CallServiceList';
import CallServiceCreate from './CallServiceCreate';
import CallServiceEdit from './CallServiceEdit';
import CallServiceShow from './CallServiceShow';

const CallServiceIcon = Icon;


export {
    CallServiceIcon,
    CallServiceList,
    CallServiceShow,
    CallServiceCreate,
    CallServiceEdit,
};
