import React from 'react';
import { useShowController, ChipField, TabbedShowLayout, Tab, TextField, DateField, Pagination, ReferenceManyField as RaReferenceManyField, ReferenceArrayField, SingleFieldList } from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import NestedField from '../Fields/NestedField';
import EnumField from '../Fields/EnumField';
import PhoneField from '../Fields/PhoneField';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import {ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import NestedArrayField from "../Fields/NestedArrayField";
import UserBanner from './UserBanner';
import {ability} from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({

}));

const UserShow = props => {
    const classes = useStyles();
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const accessResource = controllerProps.record && controllerProps.record.accessResource;

    return (
        <ShowView 
            {...props}
            {...controllerProps}
            title={<TitleForRecord source="name" {...props}/>}
        >
            <UserBanner />

            <CardBlankLayout >
                <TabbedShowLayout>
                    <Tab label="resources.Counterparty.tabs.general" >
                        {ability.can('getOne', 'User', 'name') && <TextField source="name" />}
                        {ability.can('getOne', 'User', 'description') && <TextField source="description" />}
                        {ability.can('getOne', 'User', 'position') && <TextField source="position" />}
                        {ability.can('getOne', 'User', 'role') && <EnumField source="role" />}
                        {ability.can('getOne', 'User', 'login') && <TextField source="login" />}
                        {ability.can('getOne', 'User', 'email') && <TextField source="email" />}
                        {ability.can('getOne', 'User', 'phone') && <PhoneField source="phone" />}
                        {ability.can('getOne', 'User', 'phone') && <PhoneField source="phoneContact" />}   
                    </Tab>
                    <Tab label="resources.Counterparty.tabs.access" path="access">
                        {ability.can('getOne', 'User', 'apps') && <NestedArrayField source="apps" reference="App" linkType="show" >
                            <ChipField source="name" />
                        </NestedArrayField>}
                        {ability.can('getOne', 'User', 'counterparty') && <NestedField source="counterparty" reference="Counterparty" >
                            <TextField source="name" />
                        </NestedField>}
                        {ability.can('getOne', 'User', 'access') && accessResource && <ReferenceArrayField source="accessIds" reference={accessResource} label={`resources.User.fields.access${accessResource}`}>
                            <SingleFieldList linkType="show">
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>}
                    </Tab>
                </TabbedShowLayout>
            </CardBlankLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)}/>
            
            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'User', 'id') && <TextField source="id" />}
                    {ability.can('getOne', 'User', 'createdAt') && <DateField source="createdAt" />}
                    {ability.can('getOne', 'User', 'createdBy') && <NestedField source="createdBy" reference="User" >
                        <TextField source="name" />
                    </NestedField>}
                    {ability.can('getOne', 'User', 'updatedAt') && <DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>
            
            <Separator action={(<div ref={showHistoryButton} ></div>)}/>

            <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <RaReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="User"/>
                    </RaReferenceManyField>
                </CardBlankLayout>
            </MoreContent>
        </ShowView>
    );
};

export default UserShow;