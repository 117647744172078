import React, { useEffect, useState } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "../ChartCard";
import Loading from "../Loading";
import getAllConverterJobs from "../../../dataProvider/Core/getAllConverterJobs";
import useQuery from "../../../dataProvider/Prometheus/useQuery";
import { URI_PROMETHEUS, URI_SERVICE_PORTAL } from "../../../appsettings";
import { useDataProvider, useTranslate, useVersion } from 'react-admin';
import Grid from "@material-ui/core/Grid";
import { getUUG } from "../../../dataProvider/Core/getUUG";
import getAllUUG from "../../../dataProvider/Core/getAllUUG";
import { groupBy } from 'lodash';
import Typography from "@material-ui/core/Typography";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { fade } from "@material-ui/core/styles/colorManipulator";
import HeatMapCard from "../HeatMapCard";
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles(theme => ({

    chip: {
        borderRadius: 4,
    },
    periodBar: {
        alignSelf: "center",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        marginTop: 16,
        marginBottom: 8,
        paddingRight: -10
    },
    cardHeader: {
        alignItems: "flex-start",
        padding: "16px 0px",
        marginLeft: "8px"
    },
    content: {
        display: "flex",
        padding: 0,
        flexGrow: 1,
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "300px",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
}));

export const DataGaps = ({ filter, ...props }) => {
    const { time, job } = filter;
    const date = moment(time)
    const [gasMeters, setGasMeters] = useState({});
    const version = useVersion();
    const translate = useTranslate();
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        query: [`askug_void_data_scanner_data_gaps{job="${job}"}&time=${date.unix()}`],
        uri: URI_PROMETHEUS,
    });
    useEffect(() => {
        getAllUUG().then(({ data: { allGasMeters } }) => {
            setGasMeters(groupBy(allGasMeters, 'dbId'));
        })
    }, [version])

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    // const handlePeriod = (period) => {
    //     setPeriod(period)
    // }
    const dataGapsMetric100 = data && data.length && data[0].filter(metric => Number(metric.value[1]) === 100);
    const dataGapsMetric90 = data && data.length && data[0].filter(metric => Number(metric.value[1]) < 100 && metric.value[1] >= 80);
    const dataGapsMetric70 = data && data.length && data[0].filter(metric => Number(metric.value[1]) < 80 && metric.value[1] >= 60);
    const dataGapsMetric50 = data && data.length && data[0].filter(metric => Number(metric.value[1]) < 60 && metric.value[1] >= 40);
    const dataGapsMetric30 = data && data.length && data[0].filter(metric => Number(metric.value[1]) < 40 && metric.value[1] >= 20);
    const dataGapsMetric10 = data && data.length && data[0].filter(metric => Number(metric.value[1]) < 20 && metric.value[1] > 0);
    const dataGapsMetric0 = data && data.length && data[0].filter(metric => Number(metric.value[1]) === 0);
    // const metrics = dataGapsMetric ? dataGapsMetric.values.map(value => ([moment.unix(value[0]).toDate(), Number(value[1])])) : [];
    const metrics = [
        ['УУГ', 'Parent', 'size', 'color'],
        ['Пропусков данных по линиям корректоров', null, 0, 0],
        [`100% пропусков (${dataGapsMetric100.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 100],
        [`80-100 % пропусков (${dataGapsMetric90.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 90],
        [`60-80 % пропусков (${dataGapsMetric70.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 70],
        [`40-60 % пропусков (${dataGapsMetric50.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 50],
        [`20-40 % пропусков (${dataGapsMetric30.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 30],
        [`0-20 % пропусков (${dataGapsMetric10.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 10],
        [`0% пропусков (${dataGapsMetric0.length} линий)`, 'Пропусков данных по линиям корректоров', 0, 10],
        ...dataGapsMetric100.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `100% пропусков (${dataGapsMetric100.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])]),
        ...dataGapsMetric90.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `80-100 % пропусков (${dataGapsMetric90.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])]),
        ...dataGapsMetric70.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `60-80 % пропусков (${dataGapsMetric70.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])]),
        ...dataGapsMetric50.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `40-60 % пропусков (${dataGapsMetric50.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])]),
        ...dataGapsMetric30.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `20-40 % пропусков (${dataGapsMetric30.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])]),
        ...dataGapsMetric10.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `0-20 % пропусков (${dataGapsMetric10.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])]),
        ...dataGapsMetric0.map(metric => [metric.metric.dbid + ' ' + metric.metric.flow, `0% пропусков (${dataGapsMetric0.length} линий)`, Number(metric.value[1]) + 1000000, Number(metric.value[1])])
    ];
    if (metrics.length === 9) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );

    const chartEvents = [
        {
            callback: ({ chartWrapper, ...props }) => {
                const chart = chartWrapper.getChart();
                const selected = chart.getSelection();
                const val = metrics[selected[0].row + 1];
                const dbId = Number(val[0].substring(0, val[0].length - 2))
                if (dbId) {
                    window.location = URI_SERVICE_PORTAL + `/#/GasMeter/${gasMeters[dbId][0].id}/show`
                }
            },
            eventName: "select"
        }
    ]
    return (
        <Box display="flex" flexDirection="column" style={{ margin: "-0 -13px -3px -3px" }} flexGrow={1} >
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="TreeMap"
                    chartLanguage="ru"
                    // style={{marginRight: "-50px",}}
                    loader={<Loading />}
                    data={metrics}
                    chartEvents={chartEvents}
                    options={{
                        // highlightOnMouseOver: true,
                        // maxDepth: 1,
                        // maxPostDepth: 2,
                        // minHighlightColor: '#4caf36',
                        // midHighlightColor: '#f4af36',
                        // maxHighlightColor: '#f44b36',
                        minColor: '#4caf36',
                        midColor: '#f4af36',
                        maxColor: '#f44b36',
                        // headerHeight: 0,
                        // tooltip: {isHtml: true},
                        showScale: false,
                        height: 500,
                        showTooltips: true,
                        // useWeightedAverageForAggregation: true,
                        backgroundColor: '#4caf36',
                        generateTooltip: (row, size, value) => {
                            const dbid = metrics[row + 1][0].substring(0, metrics[row + 1][0].length - 2);
                            const name = gasMeters[dbid] && gasMeters[dbid][0] && gasMeters[dbid][0].name;
                            const id = gasMeters[dbid] && gasMeters[dbid][0].id
                            if (!name) {
                                return null;
                            }
                            return (
                                `
                                <div style="background:#fd9; padding:10px; border-style:solid; border-radius: 5px; position: absolute; display: flex; width: 200px; z-index: 1; display: flex; flex-direction: column; transform: translate(-200px, 0); "  >
                                    <div style="display: flex; justify-content: space-between">
                                        <div>
                                            <span style="font-weight: bold">Значение: ${(parseFloat(size) - 1000000).toFixed(2)}</span>
                                            <span style="font-weight: bold">dbid: ${dbid}</span>
                                        </div>
                                        <button onclick="(() => {
                                            const addr = '${id}';
                                            if (addr) {
                                                window.location = '${URI_SERVICE_PORTAL}' + \`/#/GasMeter/\$\{addr\}/show\`
                                            }    
                                        })()">Открыть</button>
                                    </div>                                  
                                    <span>${name}</span>
                                </div>
                                `
                            )
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const DataGapsChart = ({ filter, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <div style={{
            display: "flex",
            width: "100%"
        }}>
            <HeatMapCard
                avatar={<Avatar variant="rounded" ><AccountTreeIcon /></Avatar>}
                title="Metrics.DataGaps"
            // action={<Chip size="small" label="Конвертер" className={classes.chip} />}
            >
                {/*<Typography variant="subtitle2" color="textSecondary" style={{marginLeft: "4px"}} gutterBottom noWrap>{translate('Metrics.DataGaps')}</Typography>*/}
                <DataGaps filter={filter} />
            </HeatMapCard>
            {/*<div style={{*/}
            {/*    width: "20px",*/}
            {/*    background: "#f4f6f9",*/}
            {/*    marginLeft: "-12px"*/}
            {/*}}/>*/}
        </div>
    );
};

export default DataGapsChart;