import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import gql from 'graphql-tag'
import { useNotify, useRefresh, useTranslate, fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncIcon from '@material-ui/icons/Sync';
import {ability} from "../../abilityProvider";



export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    root: {
        height: 156,
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#f44336",
        backgroundColor: "#ffcdd2",
    },
    action: {
        height: "100%",
    },
    ml2: {marginLeft: 8},
    stopButton: {
        color: "#f44336",
        '&:hover': {
            backgroundColor: fade("#f44336", 0.12),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}));

const StopCronWorkflowCard = (
    {
        basePath,
        classes: classesOverride,
        className,
        record,
        resource,
        workflow_id,
        client,
        redirect: redirectTo,
        ...rest }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if (ability.cannot('control', 'Converter', 'workFlow')) {
            notify('ra.action.control_cadence_not_allowed');
            return
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {

        setLoading(true);
        dispatch(fetchStart());


        if (!workflow_id) {
            notify( 'ra.notification.cron_error', 'warning' );
            setLoading(false);
            dispatch(fetchEnd()); 
            return false;
        }
        
        const args = {
            workflowid: workflow_id,
            reason: "Остановлен порталом АСКУГ"
        }

        client.stopWorkFlow(args)
        .then(() => {
            notify( 'ra.notification.cron_stopped', 'info', { workflow_id } );

            apolloCoreClient.mutate({
                mutation: gql`
                    mutation updateConverter($where: ConverterWhereUniqueInput!, $meta: Json) {
                        updateConverter(where: $where, meta: $meta) { id }
                }`,
                variables: { 
                    where: {
                        id: record.id
                    },
                    meta: {
                        ...record.meta,
                        stopCronWorkflowOptions: args
                    }
                },
                onError: (error) => {
                    alert(error)
                }
            })
            .then((data) => {

                refresh();
            });
        })
        .catch((error) => {
            notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
        })
        .finally(() => {
            setLoading(false);
            setOpen(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    };

    return (
        <>
            <Card className={classes.root}>
                <CardActionArea onClick={handleOpen} disabled={loading} classes={{root: classes.action}}>
                    <CardHeader
                        avatar={<Avatar aria-label="stop_cron_workflow" variant="rounded" className={classes.avatar}>{workflow_id.match('CRON') ? <WatchLaterIcon/> : <SyncIcon/>}</Avatar>}
                        title={translate(workflow_id.match('CRON') ? 'ra.action.stop_cron_workflow' : 'ra.action.stop_converter_workflow')}
                        titleTypographyProps={{variant:"subtitle1"}}
                        classes={{title: classes.title}}
                    />
                    <CardContent>
                        <Typography variant="body1" color="textSecondary">{workflow_id}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="stop-cron-dialog-title"
                aria-describedby="stop-cron-dialog-description"
            >
                <DialogTitle id="stop-cron-dialog-title">{`${translate(workflow_id.match('CRON') ? 'ra.action.stop_cron_workflow' : 'ra.action.stop_converter_workflow')}?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="stop-cron-dialog-description">
                        Для сервиса Cadence будет остановлен текущий рабочий процесс {workflow_id}.
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Отмена
                    </Button>
                    <Button onClick={handleClick} disabled={loading} className={classes.stopButton} autoFocus>
                        Остановить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
        
};

export default StopCronWorkflowCard;