import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient'

export const GET_GAS_METER_META = gql`
    query gasMeterMetaData{
        _allGasMetersMeta{ count },
    }
`

export const GET_ALL_GAS_METER = gql`
    query allGasMeter($gasMeterCount: Int!) {
        allGasMeters( perPage: $gasMeterCount ){ id dbId name },
    }
`

export default () => {

    return apolloCoreClient.query({
        query: GET_GAS_METER_META,
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    }).then(({data: {_allGasMetersMeta}}) => {

        return apolloCoreClient.query({
            query: GET_ALL_GAS_METER,
            variables: { gasMeterCount: _allGasMetersMeta.count },
            fetchPolicy: 'network-only',
            onError: (error) => {
                alert(error)
            }
        });
    });
};