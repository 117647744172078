import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useQuery from '../../../dataProvider/Prometheus/useQuery';
import MetricsCard from "../MetricsCard";
import Loading from "../Loading";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textBold: {
        fontWeight: "bold"
    },
    mtAuto: {
        marginTop: "auto"
    }
}));

export const ConverterErrors = ({filter}) => {
    const { time, job} = filter;
    const classes = useStyles();
    const date = moment(time);
    const {data, loading, error} = useQuery({
        query: [
            `sum(askug_convert_convert_workflow_failed_total{job="${job}"})&time=${date.unix()}`,
            `round(delta(askug_convert_convert_workflow_failed_total{job="${job}"} [1d])) > 0&time=${date.unix()}`,
        ]
    })

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)
    
    const total = data && data.length && data[0][0] && data[0][0].value[1];
    const perDay = data && data.length && data[1][0] && data[1][0].value[1];
    if (!total) {
        return (<Typography variant="subtitle1" color="textSecondary" display="inline">Нет данных</Typography>)
    }

    return (
        <div className={classes.root}>
            <span><Typography variant="h4" display="inline" className={classes.textBold}>{perDay || 0}</Typography><Typography display="inline">{` / ${total}`}</Typography></span>
            <Typography variant="caption" color="textSecondary" className={classes.mtAuto}>Последние 24 часа / Всего</Typography>
        </div>

    );
};

const ConverterErrorsCounter = (props) => {

    return (
        <MetricsCard title="Metrics.ConverterErrors" {...props}>
            <ConverterErrors/>
        </MetricsCard>
    );
};

export default ConverterErrorsCounter;