import React from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "./ChartCard";
import Loading from "./Loading";
import useQueryRange from "../../dataProvider/Prometheus/useQueryRange";
import Typography from "@material-ui/core/Typography";
import BlockIcon from '@material-ui/icons/Block';
import { useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    chip: {
        borderRadius: 4,
    },
    periodBar: {
        alignSelf: "center",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
}));

export const AverageExecuteTime = ({ filter, ...props }) => {
    const translate = useTranslate();
    const { time, rangeVector } = filter;
    const convert = {
        ['24h']: 24,
        ['12h']: 12,
        ['6h']: 6,
        ['1h']: 1,
    };
    const classes = useStyles();
    const date = moment(time)
    const { data, loading, error } = useQueryRange({
        query: [
            `delta(askug_convert_convert_workflow_elapsed_sum[20m])%2Fdelta(askug_convert_convert_workflow_elapsed_count[20m])&start=${date.unix() - convert[rangeVector] * 3600}&end=${date.unix()}&step=${Math.abs(convert[rangeVector] * 14)}`,
            `delta(askug_convert_convert_workflow_elapsed_sum[20m])%2Fdelta(askug_convert_convert_workflow_elapsed_count[20m])&start=${date.unix() - convert[rangeVector] * 3600}&end=${date.unix()}&step=${Math.abs(convert[rangeVector] * 14)}`,
            `delta(askug_convertItem_convert_workflow_elapsed_sum[20m])%2Fdelta(askug_convertItem_convert_workflow_elapsed_count[20m])&start=${date.unix() - convert[rangeVector] * 3600}&end=${date.unix()}&step=${Math.abs(convert[rangeVector] * 14)}`],
    })

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)


    const cronAverageMetric = data && data.length && data[0][0];
    const convertAverageMetric = data && data.length && data[1][0];
    const convertItemAverageMetric = data && data.length && data[2][0];
    const metrics = cronAverageMetric ? cronAverageMetric.values.map((value, i) => {
        return [moment.unix(value[0]).toDate(), Number(value[1] || 0), Number(convertAverageMetric.values[i][1] || 0), Number(convertItemAverageMetric.values[i][1]) || 0]
    }) : [];

    if (!metrics.length) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    loader={<Loading />}
                    data={[["", "Cron", "Конвертация", "Конвертеры"], ...(metrics.length ? metrics : [[date, 0, 0, 0]])]}
                    options={{
                        hAxis: {
                            titleTextStyle: { color: '#333' },
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            format: 'HH:mm'
                        },
                        vAxis: {
                            minValue: 0,
                            format: 'short',
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                        },
                        legend: {
                            position: "bottom",
                        },
                        chartArea: {
                            top: 10,
                            width: '86%',
                            height: '86%'
                        },
                        lineWidth: 1,
                        explorer: {
                            actions: ['dragToZoom', 'rightClickToReset'],
                            axis: 'horizontal',
                            keepInBounds: true,
                            maxZoomIn: 4.0,
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const AverageExecuteTimeChart = ({ filter, ...props }) => {
    const classes = useStyles();

    return (
        <>
            <ChartCard
                avatar={<Avatar variant="rounded" ><TrendingUpIcon /></Avatar>}
                title="Metrics.AverageExecuteTime"
                action={<Chip size="small" label="Конвертер" className={classes.chip} />}
            >
                <AverageExecuteTime filter={filter} />
            </ChartCard>
        </>

    );
};

export default AverageExecuteTimeChart;