import {PUSH_API_SERVER} from "./appsettings";
import Subscribe from "./dataProvider/PushApi/Subscribe";
import Unsubcribe from "./dataProvider/PushApi/Unsubcribe";
import { setPushDialog } from './redux/pushDialog/actions';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from "react";

const appServerKey = 'BITGUTWAcsILNP1oeIHeDcdPFNlcJy6aL7O7pDaRB_iapYOCZvA-q6P-aGqwMtFxxfjX1V3d1HLfKFliTvk1f64';



let hasSubscription = false;
let serviceWorkerRegistration = null;
let subscriptionData = false;

const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const isPushNotificationSupported = () => {
    return "serviceWorker" in navigator && "PushManager" in window;
};

export const askUserPermission = async () => {
    return await Notification.requestPermission();
};

export const registerServiceWorker = () => {
    return navigator.serviceWorker.register('/sw.js')
};

export const createNotificationSubscription = async () => {
    const serviceWorker = await navigator.serviceWorker.ready;
    Notification.requestPermission();
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: appServerKey
    })
        .then((subscription) => {
            Subscribe(subscription)
                .then( (response) => {
                    return response;
                })
                .then((text) => {
                    console.log('User is subscribed.');
                    hasSubscription = true;

                })
                .catch((error) => {
                    hasSubscription = false;
                    console.error('error fetching subscribe', error);
                });
            return subscription
        })
        .catch((err) => {
            console.log('Failed to subscribe the user: ', err);
            return err
        });
};

export const getSubscription = () => {
    return navigator.serviceWorker.ready
        .then((serviceWorker) => {
            return serviceWorker.pushManager.getSubscription();
        })
        .then((pushSubscription) => {
            return pushSubscription;
        });
};

export const subscribeUser = () => {
    return serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(appServerKey)
    })
        .then((subscription) => {
            Subscribe(subscription)
                .then( (response) => {
                    return response;
                })
                .then((text) => {
                    console.log('User is subscribed.');
                    hasSubscription = true;

                })
                .catch((error) => {
                    hasSubscription = false;
                    console.error('error fetching subscribe', error);
                });
            return subscription
        })
        .catch((err) => {
            console.log('Failed to subscribe the user: ', err);
        });
};

export const unsubscribeUser = () => {
    serviceWorkerRegistration.pushManager.getSubscription()
        .then((subscription) => {
            if (subscription) {
                Unsubcribe(subscription)
                    .then((response) => {
                        return response;
                    })
                    .then((text) => {
                        hasSubscription = false;

                    })
                    .catch((error) => {
                        hasSubscription = true;
                        console.error('error fetching subscribe', error);
                    });

                hasSubscription = false;

                return subscription.unsubscribe();
            }
        });
};

export const InitPush = () => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(() => {
                if (serviceWorkerRegistration) {
                    serviceWorkerRegistration.pushManager.getSubscription()
                        .then((subscription) => {
                            hasSubscription = !(subscription === null);
                            if (!hasSubscription) setOpen(true);
                            else setOpen(false);
                        });
                }
            })
        }
    }, [setOpen]);
    return { open, setOpen };
};

export const register = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
            .then((sw) => {
                serviceWorkerRegistration = sw;
            })
            .catch(function (error) {
                console.error('Service Worker Error', error);
            });
    }
};
