
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uid } from '../../utils'
import gql from 'graphql-tag'
import { useNotify, useRedirect, useRefresh, useTranslate, fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import startWorkflow from '../../dataProvider/Converter/startWorkflow';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import SyncIcon from '@material-ui/icons/Sync';



export const sanitizeRestProps = ({
                                      basePath,
                                      classes,
                                      filterValues,
                                      handleSubmit,
                                      handleSubmitWithRedirect,
                                      invalid,
                                      label,
                                      pristine,
                                      resource,
                                      saving,
                                      selectedIds,
                                      undoable,
                                      redirect,
                                      submitOnEnter,
                                      ...rest
                                  }) => rest;

const useStyles = makeStyles(theme => ({
    root: {
        height: 156,
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#689F38",
        backgroundColor: "#c5e1a5",
    },
    action: {
        height: "100%",
    },
    ml2: {marginLeft: 8},
}));

const UpdateConfigFillDataGapsServiceCard = ({
                                          basePath,
                                          classes: classesOverride,
                                          className,
                                          record,
                                          resource,
                                          client,
                                          redirect: redirectTo,
                                          ...rest }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        if (!record.meta) {
            notify("ra.action.no_config_to_upload",'warning');
            return
        }
        client.updateConfig(record.meta)
            .then(responce => {
                if (responce.status === 200) {
                    notify("ra.action.successfully_uploaded")
                }
            })
            .catch((error)=>{
            notify(error.message, 'warning');
        });
    };


    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} disabled={loading} classes={{root: classes.action}}>
                <CardHeader
                    avatar={<Avatar aria-label="update_config_fill_data_gaps_service" variant="rounded" className={classes.avatar}><SyncIcon/></Avatar>}
                    title={translate('ra.action.update_config_fill_data_gaps_service')}
                    titleTypographyProps={{variant:"subtitle1"}}
                    classes={{title: classes.title}}
                />
                <CardContent>
                    <Typography variant="body1" color="textSecondary">Обновить конфигурацию на сервисе</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );

};

export default UpdateConfigFillDataGapsServiceCard;
