import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListController,
    ListView,
    Datagrid,
    DateField,
    TextField,
    Filter,
    SearchInput,
    BulkExportButton,
    ListContext
} from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import PhoneField from '../Fields/PhoneField';
import { converter, exporter } from '../../utils';
import { ability } from "../../abilityProvider";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const CounterpartyBulkActionButtons = props => (
    <>
        {ability.can('remove', 'Counterparty') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'Counterparty') && <BulkExportButton {...props} />}
    </>
);

const CounterpartyFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const convertTable = {
    Counterparty: {
        id: 'ID',
        name: 'Название',
        description: 'Описание',
        createdAt: 'Создан',
        createdBy: 'Создал',
        updatedAt: 'Обновлен',
        type: 'Тип',
        inn: 'ИНН',
        kpp: 'КПП',
        okpo: 'ОКПО',
        ogrn: 'ОРГН',
        fio: 'Наименование',
        passport: 'Паспорт',
        passdateissue: 'Дата выдачи',
        parentId: 'Родитель',
        addressLegal: 'Юридический адрес',
        addressActual: 'Фактический адрес',
        addressPostal: 'Почтовый адрес',
        phone: 'Телефон',
        phoneMobile: 'Мобильный',
        email: 'Email',
        users: 'Пользователи',
        accessResource: 'Ресурс',
        access: 'Доступ',
        ownershipResource: 'Ресурс',
        ownership: 'Владение',
        filterGasRegion: 'Фильтр по Регионам',
        filterGasDistrict: 'Фильтр по Участкам',
        filterGasConsumer: 'Фильтр по Потребителям',
        filterGasMeterPad: 'Фильтр по Площадкам',
        accessGasRegion: 'Доступ по Регионам',
        accessGasDistrict: 'Доступ по Участкам',
        accessGasConsumer: 'Доступ по Потребителям',
        accessGasMeterPad: 'Доступ по Площадкам',
    }
};

const CounterpartyList = props => {
    const classes = useStyles();
    const controllerProps = useListController(props);
    return (
        <ListContext.Provider value={useListController(props)}>
            <ListView
                bulkActionButtons={<CounterpartyBulkActionButtons />}
                filters={<CounterpartyFilter />}
                {...props}
                {...controllerProps}
                exporter={exporter(controllerProps.defaultTitle, converter(convertTable))}
            >
                <Datagrid rowClick="show" classes={{ row: classes.row }}>
                    {ability.can('getList', 'Counterparty', 'name') && <TextField source="name" />}
                    {ability.can('getList', 'Counterparty', 'description') && <TextField source="description" />}
                    {ability.can('getList', 'Counterparty', 'phone') && <PhoneField source="phone" />}
                    {ability.can('getList', 'Counterparty', 'email') && <TextField source="email" />}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'Counterparty') && <ShowBlankItem />}
                        {ability.can('update', 'Counterparty') && <EditMenuItem />}
                        {ability.can('remove', 'Counterparty') && <RemoveMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

CounterpartyList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default CounterpartyList;