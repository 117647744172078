import React from 'react';
import { useShowController, TextField, DateField } from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import AnalyticsServiceBanner from './AnalyticsServiceBanner';
import JsonField from "../Fields/JsonField";
import { ability } from "../../abilityProvider";



const AnalyticsServiceShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);

    return (
        <ShowView
            title={<TitleForRecord source="name" {...props} />}
            {...props}
            {...controllerProps}
        >

            <AnalyticsServiceBanner />

            <CardShowLayout>
                {ability.can('getOne', 'AnalyticsService', 'login') && <TextField source="login" />}
                {ability.can('getOne', 'AnalyticsService', 'url') && <TextField source="url" />}
                {ability.can('getOne', 'AnalyticsService', 'prometheusJob') && <TextField source="prometheusJob" />}
            </CardShowLayout>

            {ability.can('getOne', 'AnalyticsService', 'meta') && <CardShowLayout>
                <JsonField source="meta" />
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'AnalyticsService', 'id') && <TextField source="id" />}
                    {ability.can('getOne', 'AnalyticsService', 'createdAt') && <DateField source="createdAt" />}
                    {ability.can('getOne', 'AnalyticsService', 'createdBy') && <TextField source="createdBy.name" label="resources.AnalyticsService.fields.createdBy" />}
                    {ability.can('getOne', 'AnalyticsService', 'updatedAt') && <DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default AnalyticsServiceShow;