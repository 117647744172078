import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';

const regexp = /^(\+7|7|8)?[\s\-]?([489][0-9]{2})?[\s\-]?([0-9]{3})[\s\-]?([0-9]{4})$/;

const PhoneField = ({ className, resource, source, record = {}, ...props }) => {

    const phone = get(record, source);
    if (!phone) return null;
    const maskedPhone = phone.replace(regexp, '$1 ($2) $3-$4');
    
    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeRestProps(props)}
        >
            {maskedPhone}
        </Typography>
    )
};

PhoneField.defaultProps = {
    addLabel: true,
};

export default PhoneField;