import React, { Children, isValidElement } from 'react';
import { Tooltip, IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslate } from 'react-admin';



const MoreButton = ({label='ra.action.more', children, cellClassName, ...props}) =>{
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip title={translate('ra.action.more', { _: 'More' })} enterDelay={700}>
                <IconButton
                    aria-label={translate('ra.action.more', { _: 'More' })}
                    aria-controls="more-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="small"
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                { Children.map(children, field =>
                    field && isValidElement(field)
                    ? React.cloneElement(field, {...props})
                    : null
                )}
            </Menu>
        </div>
    );
}

export default MoreButton;