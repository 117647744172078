import React from 'react';
import { ability, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
    useListController,
    ListView,
    DateField,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    BulkExportButton,
    TextInput,
    ListContext
} from 'react-admin';
import EnumField from '../Fields/EnumField';
import BulkRestoreButton from '../Buttons/BulkRestoreButton';
import { DeleteButton } from 'react-admin';
import RestoreButton from '../Buttons/RestoreButton';
import BulkDeleteButton from "../Buttons/BulkDeleteButton";

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
}));

const TrashItemBulkActionButtons = props => {
    console.log(props)
    return (
        <>
            {ability.can("restore", "TrashItem") && <BulkRestoreButton {...props} resource=""/>}
            {ability.can("delete", "TrashItem") && <BulkDeleteButton {...props} />}
            {/*<BulkExportButton {...props} />*/}
        </>
    )
};

const TrashItemFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const EmbededRestoreButton = (props) => props.record && ability.can('restore', props.record.resource)&&(<RestoreButton {...props} resource={props.record.resource} record={{id: props.record.item}} variant="text"/>);
const EmbededDeleteButton = (props) => props.record && ability.can('delete', props.record.resource)&&(<DeleteButton {...props} resource={props.record.resource} record={{id: props.record.item}} icon={<DeleteForeverIcon />} variant="text"/>);

const TrashItemList = props => {
    const filter = {resource_in: ['App', 'FileParserService', 'OpcServer', 'DataBus', 'User', 'Converter', 'CallService', 'FillDataGapsService'].filter((resource) => ability.can('getList', resource))};
    const controllerProps = useListController({...props, filter});
    const classes = useStyles();

    return (
        <ListContext.Provider value={controllerProps}>
            <ListView
                filters={<TrashItemFilter/>}
                bulkActionButtons={<TrashItemBulkActionButtons/>}
                actions={false}
                exporter={false}
                filter={filter}
                {...props}
                {...controllerProps}
            >
                <Datagrid /*rowClick={redirectToItem}*/ classes={{row: classes.row}}>
                    <EnumField source="resource"/>
                    <TextField source="name"/>
                    <TextField source="description"/>
                    <TextField source="deletedBy.name" label="resources.TrashItem.fields.deletedBy"/>
                    <DateField source="deletedAt" cellClassName={classes.cellMinWidth}/>
                    <EmbededRestoreButton/>
                    <EmbededDeleteButton/>}
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

TrashItemList.defaultProps = {
    perPage: 10,
    sort: { field: 'deletedAt', order: 'DESC' },
};

export default TrashItemList;