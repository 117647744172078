import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';



const LOGIN_USER_MUTATION = gql`
     mutation loginUser($id: ID!, $name: String!, $description: String, $email: String, $phone: String, $role: UserRole!) {
        loginUser(authId: $id, name: $name, description: $description, email: $email, phone: $phone, role: $role) {
            id
            authId
            name
            email
            phone
            role
        }
    }
`;

export default (args) => {
    return apolloCoreClient.mutate({
        mutation: LOGIN_USER_MUTATION,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
