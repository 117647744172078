import React, {Children, cloneElement, isValidElement, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { useTranslate } from 'react-admin'


const useStyles = makeStyles(theme => {

    return {
        column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flexStart',
            marginTop: theme.spacing(2),
        },
        textField: {
            // marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            // width: 200,
        },
        toolBar: {
            // marginBottom: theme.spacing(1),
        },
        filters: {
            display: "flex",
            alignItems: "flex-end"
        }
    }
});



const MetricsToolbar = ({ actions, filters, filter, setFilter, ...props }) => {

    const classes = useStyles();


    return (
        <Toolbar className={classes.toolBar} disableGutters>
            <div className={classes.filters}>
                {Children.map(filters, (field, index) => {
                    return isValidElement(field) ? (
                        cloneElement(field, {
                            ...props,
                            filter,
                            setFilter
                        })
                    ) : null

                })}
            </div>
            <div>
                {Children.map(actions, (field, index) => {
                    return isValidElement(field) ? (
                        cloneElement(field, {
                            ...props,
                            filter,
                            setFilter
                        })
                    ) : null

                })}
            </div>
        </Toolbar>
    )
};

MetricsToolbar.defaultProps = {
    periods: {
        ["1 час"]: "1h",
        ["6 часов"]: "6h",
        ["12 часов"]: "12h",
        ["Сутки"]: "24h",
    },
};

export default MetricsToolbar;