import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import {Chart} from "react-google-charts";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PrometheusClient from "../../../dataProvider/Prometheus/PrometheusClient";
import { Title, useTranslate } from 'react-admin';
import {i18nProvider} from "../../../i18n/i18nProvider";

const useStyles = makeStyles(theme => ({
    chartbox: {
        overflow: "",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // padding: "24px",
        height: "460px"
    },
    title: {
        // height: "40px",
        marginBottom: "-60px",
        padding: "16px 24px 8px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        // borderBottom: "solid 1px #c3c3c2",
    }
}));

const defaultConverter = (metrics, setHeadersByMetrics) => {
    metrics = metrics.filter((value) => value&&value.length);
    if (!metrics.length) { return [] }
    const result =[];
    if (setHeadersByMetrics) {
        const elem = [];
        metrics.forEach(metric => {
            elem.push(...metric.map(metricValue => {
                return parseFloat(metricValue.metric.instance)
            }))
        });
        result.push([
            'Время',
            ...elem
            // ...metrics.map((value, index, array) => {
            //     // if (isNaN(value[0].values[i][1])) {
            //     //     return parseFloat(array[index][0].values[i-1][1]);
            //     // }
            //     return value[0].metric.instance;
            // })
        ])
    }
    for (let i = 0; i < metrics[0][0].values.length; i++) {
        const elem = [new Date(metrics[0][0].values[i][0] * 1000).getHours()];
        metrics.forEach(metric => {
            elem.push(...metric.map(metricValue => {
                return parseFloat(metricValue.values[i][1])
            }))
        });
        result.push(...elem
            // [
            // new Date(metrics[0][0].values[i][0] * 1000).getHours(),//.getHours().toString()+ ':' + new Date(metrics[0][0].values[i][0] * 1000).getMinutes().toString(),
            // ...metrics.map((value, index, array) => {
            //     // if (isNaN(value[0].values[i][1])) {
            //     //     return parseFloat(array[index][0].values[i-1][1]);
            //     // }
            //     return parseFloat(value[0].values[i][1]);
            // })
            // ]
        );
    }
    return result;
};

const SimpleMetricChart = ({classes: classesOverride, className, converter = defaultConverter, refresh, setHeadersByMetrics, ...props}) => {
    const classes = useStyles({ classes: classesOverride });
    const [metrics, setMetrics] = useState([]);
    const translate = useTranslate();
    const useRefresh = useSelector(state => state.admin.ui.viewVersion, shallowEqual())

    useEffect( () => {
        new PrometheusClient(props.job).getArrayOfMetrics(props.metrics, ).then(metrics => {
            metrics = metrics.filter(value=>value ? value.length : value);
            if (!metrics.length) {setMetrics([]); return} else {

                if (setHeadersByMetrics) {
                    setMetrics([...converter(metrics, setHeadersByMetrics)]);
                } else {
                    setMetrics([[...props.headers.map(value => translate(value))], ...converter(metrics)]);
                }
            }
        });
        setMetrics([])
    }, [useRefresh]);
    const { record } = props;
    return !!metrics.length && (
        <Card >
            <div className={classes.title}>
                <Typography  variant={"h5"} style={{zIndex: 1000000000,}}>{translate(props.title)}</Typography>
                {props.subtitle&&<Typography  variant={"subtitle1"}>{translate(props.subtitle)}</Typography>}
            </div>
            <CardContent className={classes.chartbox}>
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    style={{marginTop: "10px"}}
                    loader={<img style={{alignSelf: "center"}} src="loadingChartGif.gif"/>}
                    data={metrics}
                    options={{
                        legend: {

                            ...props.legend
                        },
                        titlePosition: 'in',
                        // ticks: [array[0][0], array[Math.floor(array.length/2)][0], array[array.length-1][0]]
                        hAxis: {
                            title: props.headers[0] || '',
                            titleTextStyle: { color: '#333' },
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            ticks: [
                                metrics[1][0],
                                metrics[Math.floor(metrics.length/6)][0],
                                metrics[Math.floor(metrics.length*2/6)][0],
                                metrics[Math.floor(metrics.length*3/6)][0],
                                metrics[Math.floor(metrics.length*4/6)][0],
                                metrics[Math.floor(metrics.length*5/6)][0],
                                metrics[metrics.length - 1][0],
                            ],
                            format: {format: 'hh:mm'}
                        },
                        vAxis: { minValue: 0, textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            }, },                    // For the legend to fit, we make the chart area smaller
                        chartArea: { width: '90%', height: '76%', zIndex: "1" },
                        width: "100%",
                        lineWidth: 1,

                    }}
                    // For tests
                    rootProps={{ 'data-testid': '1' }}
                />
            </CardContent>
        </Card>
    )
};

SimpleMetricChart.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    metrics: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    converter: PropTypes.func,
    legend: PropTypes.object,
    setHeadersByMetrics: PropTypes.bool
};

export default SimpleMetricChart;