import gql from 'graphql-tag'
import { apolloAuthClientWithAuth } from './apolloAuthClient';



const UPDATE_CLIENT = gql`
  mutation updateUser($id: ID!, $login: String, $email: String, $phone: String, $password: String, $name: String, $banned: Boolean, $role: String) {
    updateClient: updateUser(id: $id, login: $login, email: $email, phone: $phone, password: $password, name:$name, banned: $banned, role: $role) {       
      id
      login
      email
      phone
      name
      role
      banned
    }
  }
`;

export default (args) => {
    return apolloAuthClientWithAuth.mutate({
        mutation: UPDATE_CLIENT,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
