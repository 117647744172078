import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import Portal from '@material-ui/core/Portal';



const useStyles = makeStyles(theme => ({
    cardActions: {
        justifyContent: "center",
    },
    button: {
        color: theme.palette.text.secondary,
    },
    expandButton: {},
    expandIcon: {
        marginLeft: 4,
        fontSize: 20,
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },

}));

const MoreContent = ({children, classes: classesOverride, resource, basePath, record, version, label, showMoreButton, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles({ classes: classesOverride });
    const [more, setMore ] = useState(false);
    
    const handleMoreClick = () => {
        setMore(!more);
    };

    return (
        <>
            <Portal container={showMoreButton.current}>
                <Button size="small" onClick={handleMoreClick} aria-expanded={more} aria-label="Show more" className={`${classes.button} ${classes.expandButton}`}>
                    <span>{translate(label)}</span>
                    <ExpandMoreIcon className={`${classes.expandIcon} ${more ? classes.expandOpen : ""}`}/>
                </Button>
            </Portal>
            <Collapse in={more} timeout="auto" unmountOnExit>
            {React.Children.map(children, field => (
                React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                    version,
                })
            ))}
            </Collapse>  
        </>    
    );
};

MoreContent.propTypes = {
    label: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, ]),
};

MoreContent.defaultProps = {
    classes: {},
    label: "ra.action.more"
};


export default MoreContent;
