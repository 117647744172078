import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TextIcon from '../Icons/DocumentBox';
import ExcelIcon from '../Icons/ExcelBox';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    dropZone: ({isDragAccept, isDragReject}) => ({
        backgroundColor: isDragAccept ? "#c8e6c9" : isDragReject ? "#ffcdd2" : theme.palette.background.default,
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        transition: "background-color .24s ease-in-out",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }),
    fileDropZone : {
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex", 
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(2),
        padding: 8
    },
    fileItem : {
        flexGrow: 1, 
        marginTop: '1em',
        marginBottom: '1em'        
    },   
    closebtn : {
        margin: theme.spacing(1),       
    }
}));

const getIcon = ext => {
    switch( ext ) {
        case 'xls':
        case 'xlsb':
        case 'xlsm':
        case 'xlsx': {
            return <ExcelIcon style={{color:"#1D7E44", fontSize: '2.5em', padding: 8 }}/>
        }      
        default: {
            return <TextIcon style={{fontSize: 20 }} />
        }       
    }
};

const Dropzone = ({ onDrop, onClear, accept, disabled }) => {
 
    const translate = useTranslate();
    const [fileName, setFileName] = useState(null);
    const isFileLoaded = fileName != null; 

    //application/vnd.ms-excel

    const {getRootProps, getInputProps, isDragAccept, isDragReject} = useDropzone({
        accept: accept || '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        disabled,
        multiple: false,
        onDrop : (acceptedFiles) => {
            acceptedFiles.forEach((f) => {
                setFileName(f.name);
            });
            onDrop(acceptedFiles);
        },
    })

    const classes = useStyles({isDragAccept, isDragReject});
    
    const handleClose = () => {
        setFileName(null);
        onClear && onClear();
    }

    if(!isFileLoaded) {
        return (
            <div className={classes.dropZone} {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{translate('ra.input.file.upload_single')}</p>
            </div>
        )
    } else {
        const ext = fileName.split('.')[1];
        return (
            <div className={classes.fileDropZone}>
                {getIcon(ext)}
                <div className={classes.fileItem}>{fileName}</div>
                <Button disabled={disabled} className={classes.closebtn} onClick={handleClose} color="primary" autoFocus>
                    <CloseIcon/>
                </Button>                
            </div>
        )
    }


    /*return (
        <div className={classes.dropZone} {...getRootProps()}>
            { !isFileLoaded ? <><input {...getInputProps()} /><p>{translate('ra.input.file.upload_single')}</p></>
                           : <div>{fileName}</div> 
            }
        </div>
    )*/
};

export default Dropzone

