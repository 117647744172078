import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslate, fetchEnd} from 'react-admin';
import MetricsCard from "../MetricsCard";
import Loading from "../Loading";
import FillDataGapsServiceClient from "../../../dataProvider/FillDataGapsService/FillDataGapsServiceClient";


const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textBold: {
        fontWeight: "bold"
    },
    mtAuto: {
        marginTop: "auto"
    }
}));

export const Status = ({url}) => {
    const classes = useStyles();
    const {status, loading, error} = FillDataGapsServiceClient(url).status();
    const translate = useTranslate();

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    // const perDay = data && data.length && data[1][0] && data[1][0].value[1];
    if (!status) {
        return (<Typography variant="subtitle1" color="textSecondary" display="inline">Нет данных</Typography>)
    }



    return (
        <div className={classes.root}>
            <Typography variant="h6" display="inline">{translate('resources.FillDataGapsService.enums.status.' + status)}</Typography>
            {/*<Typography variant="caption" color="textSecondary" className={classes.mtAuto}>Последние 24 часа / Всего</Typography>*/}
        </div>

    );
};

const StatusCard = (props) => {
    const { record } = props;
    return (
        <MetricsCard title="Metrics.Status" {...props}>
            <Status url={record.url}/>
        </MetricsCard>
    );
};

export default StatusCard;