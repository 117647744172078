import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRedirect, useRefresh, useTranslate, fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { refreshView } from 'ra-core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PausePresentationRoundedIcon from '@material-ui/icons/PausePresentationRounded';
import ClearIcon from '@material-ui/icons/Clear';



export const sanitizeRestProps = ({
                                      basePath,
                                      classes,
                                      filterValues,
                                      handleSubmit,
                                      handleSubmitWithRedirect,
                                      invalid,
                                      label,
                                      pristine,
                                      resource,
                                      saving,
                                      selectedIds,
                                      undoable,
                                      redirect,
                                      submitOnEnter,
                                      ...rest
                                  }) => rest;

const useStyles = makeStyles(theme => ({
    root: {
        height: 156,
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#f44336",
        backgroundColor: "#ffcdd2",
    },
    action: {
        height: "100%",
    },
    ml2: {marginLeft: 8},
}));

const StopFillDataGapsServiceCard = ({
                                          basePath,
                                          classes: classesOverride,
                                          className,
                                          record,
                                          resource,
                                          client,
                                          redirect: redirectTo,
                                          ...rest }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        dispatch(fetchStart());
        client.stop()
            .then(()=>{
                dispatch(fetchEnd());
                dispatch(refreshView());
                notify("ra.notification.fill_data_gaps_service_stopped", 'info');
            })
            .catch((error)=>{
                notify(error.message, 'error');
                dispatch(fetchEnd());
        });
    };


    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} disabled={loading} classes={{root: classes.action}}>
                <CardHeader
                    avatar={<Avatar aria-label="start_fill_data_gaps_service" variant="rounded" className={classes.avatar}><ClearIcon/></Avatar>}
                    title={translate('ra.action.stop_fill_data_gaps_service')}
                    titleTypographyProps={{variant:"subtitle1"}}
                    classes={{title: classes.title}}
                />
                <CardContent>
                    <Typography variant="body1" color="textSecondary">Остановка сервиса заполнения</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );

};

export default StopFillDataGapsServiceCard;
