import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import JsonInput from "../Inputs/JsonInput";
import PasswordInput from '../Inputs/PasswordInput';
import { ability } from "../../abilityProvider";



// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
//     meta: {
//         width: '100px',
//     }
// }));

const FileParserServiceCreate = props => {
    // const classes = useStyles();

    const validatePassword = [required()];
    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {ability.can('create', 'FileParserService', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {ability.can('create', 'FileParserService', 'description') && <TextInput source="description" multiline fullWidth />}
                {ability.can('create', 'FileParserService', 'login') && <TextInput source="login" validate={required()} fullWidth />}
                {ability.can('create', 'FileParserService', 'password') && <PasswordInput source="password" validate={validatePassword} />}
                {ability.can('create', 'FileParserService', 'url') && <TextInput source="url" validate={required()} fullWidth />}
                {ability.can('create', 'FileParserService', 'prometheusJob') && <TextInput source="prometheusJob" />}
                {ability.can('create', 'FileParserService', 'meta') && <JsonInput source="meta" />}
            </SimpleForm>
        </Create>
    );
};

export default FileParserServiceCreate;