import React, { useState } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "../ChartCard";
import Loading from "../Loading";
import { URI_PROMETHEUS } from "../../../appsettings";
import useQuery from "../../../dataProvider/Prometheus/useQuery";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import BlockIcon from '@material-ui/icons/Block';


const useStyles = makeStyles(theme => ({
    avatar: {
        color: "#1565c0",
        backgroundColor: "#bbdefb",
    },
    chip: {
        borderRadius: 4,
    },
    periodBar: {
        alignSelf: "center",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
}));

export const EventCountChart = ({ filter, ...props }) => {
    const { time, job, rangeVector } = filter;
    const classes = useStyles();
    const date = moment(time)
    const translate = useTranslate();
    const { data, loading, error } = useQuery({
        query: [`databus_messages{events="count", job="${job}"}[${rangeVector}]&time=${date.unix()}`],
        uri: URI_PROMETHEUS,
    });

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)


    const metrics = data[0] && data[0][0] ? data[0][0].values.map((value, i) => {
        return [moment.unix(value[0]).toDate(), ...(data[0].map(array => {
            return Number(array.values[i] ? array.values[i][1] : 0);
        }))]
    }) : [];
    if (!metrics.length) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="AreaChart"
                    chartLanguage="ru"
                    loader={<Loading />}
                    data={[["", ...(data[0].length ? data[0].map(array => "На " + array.metric.instance) : ['Сообщений'])], ...(metrics.length ? metrics : [[moment(date).toDate(), ...(data[0].length ? data[0].map(a => 0) : [0])]])]}
                    options={{
                        hAxis: {
                            titleTextStyle: { color: '#333' },
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                            format: 'HH:mm'
                        },
                        vAxis: {
                            minValue: 0,
                            textStyle: {
                                color: "grey",
                                fontSize: 12,
                                fontName: "Roboto"
                            },
                        },
                        legend: {
                            position: "bottom"
                        },
                        chartArea: {
                            top: 10,
                            width: '86%',
                            height: '86%'
                        },
                        lineWidth: 1,
                        explorer: {
                            actions: ['dragToZoom', 'rightClickToReset'],
                            axis: 'horizontal',
                            keepInBounds: true,
                            maxZoomIn: 4.0,
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const EventCountMetric = (props) => {
    const classes = useStyles();
    return (
        <ChartCard
            avatar={<Avatar variant="rounded"><TrendingUpIcon /></Avatar>}
            title="Metrics.EventCount"
            // action={<Chip size="small" label="Конвертер" className={classes.chip} />}
            {...props}
        >
            <EventCountChart />
        </ChartCard>
    );
};

export default EventCountMetric;