import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uid } from '../../utils'
import gql from 'graphql-tag'
import { useNotify, useRefresh, useTranslate, fetchStart, fetchEnd } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import stopWorkflow from '../../dataProvider/Converter/stopWorkflow';
import startWorkflow from '../../dataProvider/Converter/startWorkflow';
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    root: {
        height: 156,
    },
    title: {
        fontWeight: 500,
        lineHeight: 1.33,
    },
    avatar: {
        color: "#689F38",
        backgroundColor: "#c5e1a5",
    },
    action: {
        height: "100%",
    },
    ml2: {marginLeft: 8},
}));

const StartCronWorkflowButton = ({ 
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    client,
    redirect: redirectTo,
    ...rest }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const notify = useNotify();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = async () => {

        setLoading(true);
        dispatch(fetchStart());

        const startCronWorkflowOptions = record.meta && record.meta.startCronWorkflowOptions && {...record.meta.startCronWorkflowOptions, input: JSON.parse(record.meta.startCronWorkflowOptions.input)}
        const start_workflow_id = startCronWorkflowOptions.input.workflowsettings.workflowId
        const stop_workflow_id = record.meta && record.meta.stopCronWorkflowOptions && record.meta.stopCronWorkflowOptions.workflowid

        let stopCronWorkflowOptions;

        if (!startCronWorkflowOptions) {
            notify( 'ra.notification.cron_error', 'warning' );
            setLoading(false);
            dispatch(fetchEnd());
            return false;
        }

        if (start_workflow_id && stop_workflow_id && (start_workflow_id !== stop_workflow_id)) {
            
            stopCronWorkflowOptions = {
                workflowid: start_workflow_id,
                reason: "Остановлен порталом АСКУГ"
            }
            
            await client.stopWorkFlow(stopCronWorkflowOptions)
        }

        const startCronWorkflowArgs = {
            workflowname: "CronWorkflow",
            input: JSON.stringify({
                workflowsettings: {
                    ...startCronWorkflowOptions.input.workflowsettings,
                    workflowId: uid("CRON"),
                }
            })
        }

        client.startWorkFlow(startCronWorkflowArgs)
        .then(({ data: {workflowStart} }) => {
            notify( 'ra.notification.cron_started', 'info', { workflow_id: workflowStart.workflowId } );

            apolloCoreClient.mutate({
                mutation: gql`
                    mutation updateConverter($where: ConverterWhereUniqueInput!, $meta: Json) {
                        updateConverter(where: $where, meta: $meta) { id }
                }`,
                variables: { 
                    where: {
                        id: record.id
                    },
                    meta: {
                        ...record.meta,
                        startCronWorkflowOptions: startCronWorkflowArgs,
                        stopCronWorkflowOptions,
                    }
                },
                onError: (error) => {
                    alert(error)
                }
            })
            .then((data) => {
                refresh();
            });
        })
        .catch((error) => {
            notify( typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning' );
        })
        .finally(() => {
            setLoading(false);
            setOpen(false);
            dispatch(fetchEnd()); // stop the global loading indicator
        });
    };

    return (
        <>
            <Card className={classes.root}>
                <CardActionArea onClick={handleOpen} disabled={loading} classes={{root: classes.action}}>
                    <CardHeader
                        avatar={<Avatar aria-label="start_cron_workflow" variant="rounded" className={classes.avatar}><WatchLaterIcon/></Avatar>}
                        title={translate('ra.action.start_cron_workflow')}
                        titleTypographyProps={{variant:"subtitle1"}}
                        classes={{title: classes.title}}
                    />
                    <CardContent>
                        <Typography variant="body1" color="textSecondary">Запуск рабочего процесса CRON</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="start-cron-dialog-title"
                aria-describedby="start-cron-dialog-description"
            >
                <DialogTitle id="start-cron-dialog-title">{`${translate('ra.action.start_cron_workflow')}?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="start-cron-dialog-description">
                        Для сервиса Cadence будет стоздан новый рабочий процесс CRON, предыдущий будет остановлен.
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Отмена
                    </Button>
                    <Button onClick={handleClick} disabled={loading} color="primary" autoFocus>
                        Запустить
                    </Button>
                    </DialogActions>
            </Dialog>
        </>
    );
        
};

export default StartCronWorkflowButton;