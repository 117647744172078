import React from 'react';
import {useShowController, TextField, DateField} from 'react-admin';
import { ShowView } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import CallServiceBanner from './CallServiceBanner';
import JsonField from "../Fields/JsonField";
import {ability} from "../../abilityProvider";
import CallServiceChartsField from "./CallServiceChartField";
import MetricsContainer from "../Layout/MetricsContainer";
import CorrectorsProcessingCounter from "../Metrics/CallServiceCounters/CorrectorsProcessingCounter";
import PollingOkCounter from "../Metrics/CallServiceCounters/PollingOkCounter";
import PollingWithErrorCounter from "../Metrics/CallServiceCounters/PollingWithErrorCounter";
import NeverPolledCounter from "../Metrics/CallServiceCounters/NeverPolledCounter";
import ServerErrorsCounter from "../Metrics/CallServiceCounters/ServerErrorsCounter";
import MetricsArrayField from "../Layout/MetricsArrayField";
import MetricsLayout from "../Layout/MetricsLayout";
import MetricsToolbar from "../Layout/MetricsToolbar";
import MetricDefaultFilters from "../Layout/MetricsDefaultFilters";



const CallServiceShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showChartsButton = React.useRef(null);
    return (
        <ShowView
            title={<TitleForRecord source="name" {...props}/>}
            {...props}
            {...controllerProps}
        >
            <CallServiceBanner />
            <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/> }>
                <MetricsContainer xs={6} sm={4} md={3} xl={2}>
                    <CorrectorsProcessingCounter/>
                    <PollingOkCounter/>
                    <PollingWithErrorCounter/>
                    <NeverPolledCounter/>
                    <ServerErrorsCounter/>
                </MetricsContainer>
            </MetricsLayout>

            <Separator action={(<div ref={showChartsButton}></div> )}/>

            <MoreContent showMoreButton={showChartsButton} label="resources.CallService.fields.more_metrics">
                <MetricsLayout filters={<MetricDefaultFilters withoutPeriods/>} >
                    <CallServiceChartsField source="metrics"/>
                </MetricsLayout>
            </MoreContent>

            <CardShowLayout>
                {ability.can('getOne', 'CallService', 'login')&&<TextField source="login" />}
                {ability.can('getOne', 'CallService', 'url')&&<TextField source="url" />}
            </CardShowLayout>

            {ability.can('getOne', 'CallService', 'meta')&&<CardShowLayout>
                <JsonField source="meta"/>
            </CardShowLayout>}

            <Separator action={(<div ref={showMoreButton} ></div>)}/>

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'CallService', 'id')&&<TextField source="id" />}
                    {ability.can('getOne', 'CallService', 'createdAt')&&<DateField source="createdAt" />}
                    {ability.can('getOne', 'CallService', 'createdBy')&&<TextField source="createdBy.name" label="resources.CallService.fields.createdBy" />}
                    {ability.can('getOne', 'CallService', 'updatedAt')&&<DateField source="updatedAt" />}
                    {ability.can('getOne', 'CallService', 'prometheusJob')&&<TextField source="prometheusJob" />}
                </CardShowLayout>
            </MoreContent>
        </ShowView>
    );
};

export default CallServiceShow;