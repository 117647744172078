import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
function Icon(props) {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M17 3H3C1.89 3 1 3.89 1 5V19C1 20.1 1.9 21 3 21H13.2547C12.4638 19.8662 12 18.4872 12 17C12 13.134 15.134 10 19 10V5C19 3.89 18.1 3 17 3ZM7 18H4V16H7V18ZM7 15H4V13H7V15ZM7 12H4V10H7V12ZM11 18H8V16H11V18ZM11 15H8V13H11V15ZM11 12H8V10H11V12ZM19 13.5V12L16.75 14.25L19 16.5V15C19.663 15 20.2989 15.2634 20.7678 15.7322C21.2366 16.2011 21.5 16.837 21.5 17.5C21.5 17.9 21.41 18.28 21.24 18.62L22.33 19.71C22.75 19.08 23 18.32 23 17.5C23 16.4391 22.5786 15.4217 21.8284 14.6716C21.0783 13.9214 20.0609 13.5 19 13.5ZM19 21.5V23L21.25 20.75L19 18.5V20C18.337 20 17.7011 19.7366 17.2322 19.2678C16.7634 18.7989 16.5 18.163 16.5 17.5C16.5 17.1 16.59 16.72 16.76 16.38L15.67 15.29C15.25 15.92 15 16.68 15 17.5C15 18.5609 15.4214 19.5783 16.1716 20.3284C16.9217 21.0786 17.9391 21.5 19 21.5Z" />
        </SvgIcon>
    );
}

export default Icon;