import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
function Icon(props) {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M15 0H13V2H15V0ZM7 1H5V3H7V1ZM7 4H5V6H5.06944C4.38922 6.58826 4 7.2713 4 8C4 10.21 7.58 12 12 12C16.42 12 20 10.21 20 8C20 7.2713 19.6108 6.58826 18.9306 6H19V4H17V4.87675C16.3985 4.63573 15.7257 4.43728 15 4.2905V3H13V4.03091C12.6724 4.01051 12.3387 4 12 4C10.1077 4 8.36937 4.32809 7 4.87675V4ZM9 2H11V4H9V2ZM19 1H17V3H19V1ZM4 10V13C4 15.21 7.58 17 12 17C16.42 17 20 15.21 20 13V10C20 12.21 16.42 14 12 14C7.58 14 4 12.21 4 10ZM4 15V18C4 20.21 7.58 22 12 22C16.42 22 20 20.21 20 18V15C20 17.21 16.42 19 12 19C7.58 19 4 17.21 4 15ZM5 7H7V9H5V7ZM15 6H13V8H15V6ZM17 7H19V9H17V7ZM11 5H9V7H11V5ZM9 8H11V10H9V8Z" />
        </SvgIcon>
    );
}

export default Icon;