import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';
import {useEffect, useState} from "react";
import { useRefresh } from 'react-admin';

export const GET_ALL_CONVERTER_JOBS = gql`
    query allConverters {
        items: allConverters { prometheusJob }
    }
`;

export const getAllJobs = (args) => {
    return apolloCoreClient.query({
        query: GET_ALL_CONVERTER_JOBS,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};

export default (args) => {
    const [state, setState] = useState({
        data: undefined,
        loading: true,
        error: undefined,
    });
    const refresh = useRefresh();
    useEffect(()=> {
        getAllJobs().then(({data: {items: items}})=> {
            setState({
                data: items,
                loading: false,
                loaded: true,
            })
        }).catch(error=> {
            setState({
                error: error,
                loading: false,
                loaded: false,
            })
        })
    }, [refresh, setState]);

    return state;
}