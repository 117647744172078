import React, { useEffect, useState } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Chart } from "react-google-charts";
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ChartCard from "../ChartCard";
import Loading from "../Loading";
import getAllConverterJobs from "../../../dataProvider/Core/getAllConverterJobs";
import useQuery from "../../../dataProvider/Prometheus/useQuery";
import { URI_PROMETHEUS, URI_SERVICE_PORTAL } from "../../../appsettings";
import { useDataProvider, useTranslate, useVersion } from 'react-admin';
import Grid from "@material-ui/core/Grid";
import { getUUG } from "../../../dataProvider/Core/getUUG";
import getAllUUG from "../../../dataProvider/Core/getAllUUG";
import { groupBy } from 'lodash';
import Typography from "@material-ui/core/Typography";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { fade } from "@material-ui/core/styles/colorManipulator";
import HeatMapCard from "../HeatMapCard";
import Toolbar from "@material-ui/core/Toolbar";
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles(theme => ({

    chip: {
        marginRight: theme.spacing(1),
        borderRadius: "5px"
    },
    periodBar: {
        alignSelf: "center",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        marginTop: 16,
        marginBottom: 8,
        paddingRight: -10
    },
    cardHeader: {
        alignItems: "flex-start",
        padding: "16px 0px",
        marginLeft: "8px"
    },
    content: {
        display: "flex",
        padding: 0,
        flexGrow: 1,
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        width: "100%",
        height: "300px",
    },
    icon: {
        width: "50%",
        height: "50%",
    },
}));

export const LastUpdate = ({ filter, ...props }) => {
    const { time, job } = filter;
    const date = moment(time)
    const [gasMeters, setGasMeters] = useState({});
    const version = useVersion();
    const classes = useStyles();
    const translate = useTranslate();
    const { data, loading, error } = useQuery({ query: [`(time() - askug_convertItem_convert_lastupdate{job="${job}"})/3600 and (time() - askug_convertItem_convert_lastupdate{job="${job}"})/3600 < 240&time=${date.unix()}`] });
    useEffect(() => {
        getAllUUG().then(({ data: { allGasMeters } }) => {
            setGasMeters(groupBy(allGasMeters, 'dbId'));
        })
    }, [version])

    if (error) return (typeof error === "string" ? error : "error");
    if (loading) return (<Loading />)

    // const handlePeriod = (period) => {
    //     setPeriod(period)
    // }
    // const metrics = dataGapsMetric ? dataGapsMetric.values.map(value => ([moment.unix(value[0]).toDate(), Number(value[1])])) : [];
    const avg = data[0].reduce((sum, metric) => sum + Number(metric.value[1]), 0) / data[0].length
    const ROOT = 'Распределение по давности данных\n'
    const metrics = [
        ['УУГ', 'Parent', 'size', 'color'],
        [ROOT, null, 0, 0],
        ...data[0].map(metric => [metric.metric.dbid, ROOT, Number(metric.value[1]), Number(metric.value[1])]),
    ];
    if (metrics.length === 2) return (
        <div className={classes.root}>
            <Typography variant="h6">{translate('Metrics.NoData')}</Typography>
            <BlockIcon className={classes.icon} />
        </div>
    );

    const chartEvents = [
        {
            callback: ({ chartWrapper, ...props }) => {
                const chart = chartWrapper.getChart();
                const selected = chart.getSelection();
                const val = metrics[selected[0].row + 1];
                const dbId = Number(val[0])
                if (dbId) {
                    window.location = URI_SERVICE_PORTAL + `/#/GasMeter/${gasMeters[dbId][0].id}/show`
                }
            },
            eventName: "select"
        }
    ]
    return (
        <Box display="flex" flexDirection="column" style={{ margin: "-0 -13px -3px -3px" }} flexGrow={1} >
            <Box flexGrow={1} >
                <Chart
                    width={'100%'}
                    height={'100%'}
                    chartType="TreeMap"
                    chartLanguage="ru"
                    loader={<Loading />}
                    data={metrics}
                    chartEvents={chartEvents}
                    options={{
                        minColor: '#4caf36',
                        midColor: '#4caf36',
                        maxColor: '#f44b36',
                        maxColorValue: 24,
                        minColorValue: 23.9,
                        showScale: false,
                        height: 500,
                        showTooltips: true,
                        useWeightedAverageForAggregation: true,
                        generateTooltip: (row, size, value) => {
                            const dbid = metrics[row + 1][0];
                            const name = gasMeters[dbid] && gasMeters[dbid][0] && gasMeters[dbid][0].name;
                            const id = gasMeters[dbid] && gasMeters[dbid][0].id
                            if (!name) {
                                return null;
                            }
                            return (
                                `
                                <div style="background:#fd9; padding:10px; border-style:solid; border-radius: 5px; position: absolute; display: flex; width: 200px; z-index: 1; display: flex; flex-direction: column; transform: translate(-200px, 0); "  >
                                    <div style="display: flex; justify-content: space-between">
                                        <div>
                                            <span style="font-weight: bold">Значение: ${parseFloat(size).toFixed(2)}</span>
                                            <span style="font-weight: bold">dbid: ${dbid}</span>
                                        </div>
                                        <button onclick="(() => {
                                            const addr = '${id}';
                                            if (addr) {
                                                window.location = '${URI_SERVICE_PORTAL}' + \`/#/GasMeter/\$\{addr\}/show\`
                                            }    
                                        })()">Открыть</button>
                                    </div>                                  
                                    <span>${name}</span>
                                </div>
                                `
                            )
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

const LastUpdateHeatMap = ({ filter, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <div style={{
            display: "flex",
            width: "100%"
        }}>
            <HeatMapCard
                avatar={<Avatar variant="rounded" ><AccountTreeIcon /></Avatar>}
                title="dashboard.convertItemLastUpdate"
                legend={
                    <Toolbar>
                        <Chip className={classes.chip} size="small" style={{ background: '#f44b36' }} label='Больше суток' />
                        <Chip className={classes.chip} size="small" style={{ background: '#4caf36' }} label='Меньше суток' />
                    </Toolbar>
                }
            // action={<Chip size="small" label="Конвертер" className={classes.chip} />}
            >
                {/*<Typography variant="subtitle2" color="textSecondary" style={{marginLeft: "4px"}} gutterBottom noWrap>{translate('Metrics.DataGaps')}</Typography>*/}
                <LastUpdate filter={filter} />
            </HeatMapCard>
            {/*<div style={{*/}
            {/*    width: "20px",*/}
            {/*    background: "#f4f6f9",*/}
            {/*    marginLeft: "-12px"*/}
            {/*}}/>*/}
        </div>
    );
};

export default LastUpdateHeatMap;