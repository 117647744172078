import gql from 'graphql-tag'
import { apolloAuthClientWithAuth } from './apolloAuthClient';



// const CREATE_SERVICE = gql`
//     mutation createService($password: String!, $name: String!, $type: ServiceType!, $url: String!, $login: String!, $meta: Json) {
//         createClient: createService(password: $password, name: $name, type: $type, url: $url, login: $login, meta: $meta) {
//             id
//             name
//             login
//             url
//             type
//             meta
//         }
//     }
// `;

const CREATE_CONVERTER = gql`
    mutation createUser( $password: String!, $name: String!, $role: String, $login: String) {
        createClient: createUser(password: $password, name: $name, role: $role , login: $login) {
            id
            name
            login
            role
        }
    }
`;

export default (args) => {
    return apolloAuthClientWithAuth.mutate({
        mutation: CREATE_CONVERTER,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
