import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { getAccessToken } from './getAccessToken';
import { URI_AUTH } from '../appsettings';



const httpLink = createHttpLink({
  uri: URI_AUTH
});

const authLink = setContext(async (_, { headers }) => {           
    
  const token = await getAccessToken(); 

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


export const apolloAuthClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export const apolloAuthClientWithAuth = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});