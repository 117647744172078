import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, Toolbar, TabbedForm, FormTab, SaveButton, DateInput, ReferenceArrayInput, AutocompleteArrayInput, required, FormDataConsumer, email } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import CounterpartyBanner from './CounterpartyBanner';
import EnumInput from '../Inputs/EnumInput';
import PhoneInput from '../Inputs/PhoneInput';
import AccessResourceInput from './AccessResourceInput';
import AccessReferenceArrayInput from './AccessReferenceArrayInput';
import GridField from '../Fields/GridField';
import { ability } from "../../abilityProvider";



const validateEmail = [email()];

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const CounterpartyToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can('update', 'Counterparty') && <SaveButton />}
            {ability.can("update", "Counterparty") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {ability.can('remove', 'Counterparty') && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const CounterpartyEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <CounterpartyBanner />
            <CardBlankLayout >
                <TabbedForm redirect="show" toolbar={<CounterpartyToolbar />}>
                    <FormTab label="resources.Counterparty.tabs.general">
                        {ability.can('getOne', 'Counterparty', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                        {ability.can('getOne', 'Counterparty', 'description') && <TextInput source="description" multiline fullWidth />}
                        {ability.can('getOne', 'Counterparty', 'inn') && <TextInput source="inn" validate={required()} />}
                        {ability.can('getOne', 'Counterparty', 'type') && <EnumInput source="type" Enum="CounterpartyType" validate={required()} />}
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (formData.type === "LEGALENTITY" || formData.type === "GOVERNMENTAGENCY") &&
                                <GridField>
                                    {ability.can('getOne', 'Counterparty', 'kpp') && <TextInput source="kpp" xs {...rest} />}
                                    {ability.can('getOne', 'Counterparty', 'okpo') && <TextInput source="okpo" xs {...rest} />}
                                    {ability.can('getOne', 'Counterparty', 'ogrn') && <TextInput source="ogrn" xs {...rest} />}
                                </GridField>
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.type === "INDIVIDUAL" &&
                                <GridField>
                                    {ability.can('getOne', 'Counterparty', 'fio') && <TextInput source="fio" xs={6} {...rest} />}
                                    {ability.can('getOne', 'Counterparty', 'passport') && <TextInput source="passport" xs {...rest} />}
                                    {ability.can('getOne', 'Counterparty', 'passdateissue') && <DateInput source="passdateissue" xs  {...rest} />}
                                </GridField>
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.type === "SEPARATEDIVISION" &&
                                <>
                                    <TextInput source="parentId" {...rest} />
                                </>
                            }
                        </FormDataConsumer>
                        <TextInput source="addressLegal" fullWidth />
                        <TextInput source="addressActual" fullWidth />
                        <TextInput source="addressPostal" fullWidth />
                        <PhoneInput source="phone" />
                        <PhoneInput source="phoneMobile" />
                        <TextInput source="email" type="email" validate={validateEmail} />
                    </FormTab>
                    <FormTab label="resources.Counterparty.tabs.access" path="access">
                        <AccessResourceInput source="accessResource" Enum="AccessResource" allowEmpty emptyValue={null} emptyText="ra.message.no" />
                        <AccessReferenceArrayInput source="accessIds" label="resources.Counterparty.fields.access" />
                        <ReferenceArrayInput source="usersIds" reference="User" filter={{ counterpartyReference: null }} label="resources.Counterparty.fields.users" fullWidth>
                            <AutocompleteArrayInput />
                        </ReferenceArrayInput>
                    </FormTab>
                </TabbedForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default CounterpartyEdit