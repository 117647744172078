import Icon from '../Icons/Converter';
import ConverterList from './ConverterList';
import ConverterCreate from './ConverterCreate';
import ConverterEdit from './ConverterEdit';
import ConverterShow from './ConverterShow';

const ConverterIcon = Icon;


export {
    ConverterIcon,
    ConverterList,
    ConverterShow,
    ConverterCreate,
    ConverterEdit,
};
