import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListController,
    ListView,
    Datagrid,
    DateField,
    TextField,
    Filter,
    SearchInput,
    BulkExportButton,
    ListContext
} from 'react-admin';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import {converter, exporter} from '../../utils';
import InstanceField from "../Fields/InstancesField";
import PrometheusClient from "../../dataProvider/Prometheus/PrometheusClient";
import {ability} from "../../abilityProvider";
import UpField from "../Fields/UpField";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    cellButton: { 
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const ConverterBulkActionButtons = props => (
    <>
        {ability.can('remove', 'Converter') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'Converter') && <BulkExportButton {...props} />}
    </>
);

const ConverterFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);



const convertTable = {
    DataBus: {
        id: 'ID',
        name: 'Название',
        description: 'Описание',
        createdAt: 'Создан',
        createdBy: 'Создал',
        updatedAt: 'Обновлен',
        login: 'Логин',
        meta: 'Конфигурация',
        password: 'Пароль',
        url: 'url адрес',
        up: 'Инстансы (работает/всего)',
        metrics: 'Метрики',
        metric: 'Метрика',
        instances: 'Инстансы'
    }
}

const ConverterList = props => {
    const classes = useStyles();
    const controllerProps = useListController(props);
    const [instances, setInstances] = useState([])
    useEffect(() => {
        new PrometheusClient().getAllActiveInstances('api2')
            .then((data) => {
                setInstances(data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [setInstances]);

    return (
        <ListContext.Provider value={useListController(props)}>
            <ListView
                filters={<ConverterFilter/>}
                bulkActionButtons={<ConverterBulkActionButtons/>}
                {...props}
                {...controllerProps}
                exporter={exporter(controllerProps.defaultTitle, converter(convertTable))}
            >

                <Datagrid rowClick="show" classes={{row: classes.row}}>
                    <UpField instances={instances} source="status" cellClassName={classes.cellMinWidth}/>
                    {ability.can('getList', 'Converter', 'name') && <TextField source="name"/>}
                    {ability.can('getList', 'Converter', 'description') && <TextField source="description"/>}
                    {ability.can('getList', 'Converter', 'authId') &&
                    <InstanceField instances={instances} source="upInstances"/>}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth}/>
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth}/>
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'User') && <ShowBlankItem/>}
                        {ability.can('update', 'User') && <EditMenuItem/>}
                        {ability.can('remove', 'User') && <RemoveMenuItem/>}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

ConverterList.defaultProps = {
    filter: {},
    perPage: 10,
    sort: { field: 'name', order: 'ASC' }
};



export default ConverterList;