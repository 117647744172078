import XLSX from 'xlsx';
import _ from 'lodash';
import moment from 'moment';
import gql from "graphql-tag"
import apolloCoreClient from '../../dataProvider/Core/apolloCoreClient'

const getRowsFromSheet = (xlsx_workbook, sheetname, columns) => {   
    const sheet = xlsx_workbook.Sheets[sheetname];
    if(!sheet) {
        return false;    
    }
       
    const count = sheet['!rows'].length;
    const result = []; 
    
    columns = columns.filter(x => sheet[`${x}1`]);
    const fields = columns.map(x => sheet[`${x}1`].v);
    for (let i = 2; i <= count; i++) {   
        const item = {};
        columns.forEach((c, j) => {
            const cell = sheet[`${c}${i}`];                        
            item[fields[j].replace(/\s+/g, '_').toLowerCase()] = cell ? cell.v : null;          
        });
        result.push(item);
    }
    return result;  
}

const validete_sheet = (xlsx_workbook, name, columns, columns_names) => {
    const validate_errors = [];
    const xslx_data = getRowsFromSheet(xlsx_workbook, name, columns);
    if(!xslx_data) {
        validate_errors.push(`Вкладка "${name}" не найдена`);
    } else if(xslx_data.length == 0) {
        validate_errors.push(`Во вкладке "${name}" нет данных`);
    } else {
        const first = xslx_data[0];
        columns_names.forEach(cn => {
            if(first[cn] == undefined) {
                validate_errors.push(`Во вкладке "${name}" отсутсвует колонка "${cn}"`);
            }
        })
    }
    
    return {
        validate_errors,
        data : xslx_data || []
    }
}

export default async(data) => {
    const validate_errors = [];
    const validate_alert = [];

    const workbook = XLSX.read(data, {type: 'array'});

    
    
    const res_districts = validete_sheet(workbook, 'Абонентские участки', ['B', 'C'], ['site_id', 'site_name']);
    validate_errors.push(...res_districts.validate_errors);
    const group_districts = _.groupBy(res_districts.data, 'site_id');

    const res_consumers = validete_sheet(workbook, 'Потребители', ['B', 'C', 'D'], ['cons_id', 'cons_adr', 'cons_name']);
    validate_errors.push(...res_consumers.validate_errors);
    const group_consumers = _.groupBy(res_consumers.data, 'cons_id');
    
    const res_meterpads = validete_sheet(workbook, 'Площадки', ['B', 'C', 'D'], ['area_id', 'area_name', 'area_adr']);
    validate_errors.push(...res_meterpads.validate_errors);
    const group_meterpads = _.groupBy(res_meterpads.data, 'area_id');

    const res_districts_meterpads = validete_sheet(workbook, 'Площадка-Участок', ['B', 'C'], ['area_id', 'site_id']);
    validate_errors.push(...res_districts_meterpads.validate_errors);
    const group_districts_meterpads = _.groupBy(res_districts_meterpads.data, 'area_id');
    for (const key in group_districts_meterpads) {
        if(key == "undefined") { continue; }

        if(group_districts_meterpads[key].length > 1) {
            validate_errors.push(`Во вкладке "Площадка-Участок" площадка [area_id=${key}] принадлежит нескольким участкам: [site_id=${group_districts_meterpads[key].map(p => p.site_id).join(',')}] `);
        }
        if(!group_meterpads[key]) {
            validate_errors.push(`Во вкладке "Площадка-Участок" площадка [area_id=${key}] отсутствует во вкладке "Площадки"`);
        }
        
        if(!group_districts[group_districts_meterpads[key][0].site_id]) {
            validate_errors.push(`Во вкладке "Площадка-Участок" участок [site_id=${group_districts_meterpads[key][0].site_id}] отсутствует во вкладке "Абонентские участки"`);
        }
    }
    
    const res_consumer_meterpads = validete_sheet(workbook, 'Площадка-Потребитель-даты', ['B', 'C', 'D', 'E'], ['area_id', 'cons_id', 'date_begin', 'date_end']);
    validate_errors.push(...res_consumer_meterpads.validate_errors);
    const group_consumer_meterpads = _.groupBy(res_consumer_meterpads.data, 'area_id');
    for (const key in group_consumer_meterpads) {  
        if(key == "undefined") { continue; }  
        
        group_consumer_meterpads[key].forEach(x => {
            if(!moment(x.date_begin, 'DD.MM.YYYY').isValid()) {
                validate_errors.push(`Во вкладке "Площадка-Потребитель-даты" на площадке [area_id=${key}] неверная дата "date_begin" = ${x.date_begin}`);
            }
            
            if(!moment(x.date_end, 'DD.MM.YYYY').isValid()) {
                validate_errors.push(`Во вкладке "Площадка-Потребитель-даты" на площадке [area_id=${key}] неверная дата "date_end" = ${x.date_end}`);
            }
        });
               
        const consumers = group_consumer_meterpads[key].filter(x => moment(x.date_begin, 'DD.MM.YYYY').isValid() && moment().isSameOrAfter(moment(x.date_begin, 'DD.MM.YYYY')) && moment(x.date_end, 'DD.MM.YYYY').isValid() && moment().isSameOrBefore(moment(x.date_end, 'DD.MM.YYYY')));
        if(consumers.length > 1) {
            validate_alert.push(`Во вкладке "Площадка-Потребитель-даты" площадка [area_id=${key}] принадлежит нескольким потребителям: [cons_id=${consumers.map(p => p.cons_id).join(',')}]`);
        }        
        if(!group_meterpads[key]) {
            validate_errors.push(`Во вкладке "Площадка-Потребитель-даты" площадка [area_id=${key}] отсутствует во вкладке "Площадки"`);
        }

        if(!group_consumers[group_consumer_meterpads[key][0].cons_id]) {           
            validate_errors.push(`Во вкладке "Площадка-Потребитель-даты" потребитель [cons_id=${key}] отсутствует во вкладке "Потребители"`);
        }
    }

    const res_gro = validete_sheet(workbook, 'ГРО', ['B', 'C', 'D'], ['gro_id', 'gro_name', 'gro_adr']);
    validate_errors.push(...res_gro.validate_errors);
    const group_gro = _.groupBy(res_gro.data, 'gro_id');

    const res_grs = validete_sheet(workbook, 'ГРС', ['B', 'C'], ['grs_id', 'grs_name']);
    validate_errors.push(...res_grs.validate_errors);
    const group_grs = _.groupBy(res_grs.data, 'grs_id');

    const res_grsOuts = validete_sheet(workbook, 'Выходы ГРС', ['B', 'C', 'D'], ['grs_id', 'grs_out_name', 'grs_out_id']);
    validate_errors.push(...res_grsOuts.validate_errors);
    const group_grsOuts = _.groupBy(res_grsOuts.data, 'grs_out_id');

    const res_gro_meterpads = validete_sheet(workbook, 'Площадка-ГРО', ['B', 'C'], ['area_id', 'gro_id']);
    validate_errors.push(...res_gro_meterpads.validate_errors);
    const group_gro_meterpads = _.groupBy(res_gro_meterpads.data, 'area_id');
    for (const key in group_gro_meterpads) {
        const gro_meterpads = group_gro_meterpads[key];
        if(gro_meterpads.length > 1) {
            validate_errors.push(`Во вкладке "Площадка-ГРО" площадка [area_id=${key}] принадлежит нескольким ГРО: [gro_id=${gro_meterpads.map(p => p.gro_id).join(',')}]`);
        }
        
        const v = gro_meterpads[0];
        if(v.area_id && !group_meterpads[v.area_id]) {
            validate_errors.push(`Во вкладке "Площадка-ГРО" площадка [area_id=${v.area_id}] отсутствует во вкладке "Площадки"`);
        }

        if(v.gro_id && !group_gro[v.gro_id]) {
            validate_errors.push(`Во вкладке "Площадка-ГРО" ГРО [gro_id=${v.gro_id}] отсутствует во вкладке "ГРО"`);
        }

    }

    const res_outgrs_meterpads = validete_sheet(workbook, 'Площадка-ГРС-Выход ГРС', ['B', 'C', 'D'], ['area_id', 'grs_id', 'grs_out_id']);
    validate_errors.push(...res_outgrs_meterpads.validate_errors);
    
    const group_outgrs_meterpads = _.groupBy(res_outgrs_meterpads.data, 'area_id');
    for (const key in group_outgrs_meterpads) {
        const outgrs_meterpads = group_outgrs_meterpads[key];
        if(outgrs_meterpads.length > 1) {
            validate_errors.push(`Во вкладке "Площадка-ГРС-Выход ГРС" площадка [area_id=${key}] принадлежит нескольким Выход ГРС: [gro_id=${outgrs_meterpads.map(p => p.grs_out_id).join(',')}]`);
        }

        const v = outgrs_meterpads[0];
        if(v.area_id && !group_meterpads[v.area_id]) {
            validate_errors.push(`Во вкладке "Площадка-ГРС-Выход ГРС" площадка [area_id=${v.area_id}] отсутствует во вкладке "Площадки"`);
        }
        if(v.grs_id && !group_grs[v.grs_id]) {
            validate_errors.push(`Во вкладке "Площадка-ГРС-Выход ГРС" ГРС [grs_id=${v.grs_id}] отсутствует во вкладке "ГРС"`);
        }
        if(v.grs_out_id && !group_grsOuts[v.grs_out_id]) {
            validate_errors.push(`Во вкладке "Площадка-ГРС-Выход ГРС" Выход ГРС [grs_out_id=${v.grs_out_id}] отсутствует во вкладке "Выходы ГРС"`);
        }
    }
    

    const res_uugs = validete_sheet(workbook, 'УУГ (Приборы)', ['B', 'C', 'D'], ['equip_id', 'equip_id_askug', 'equip_name']);
    validate_errors.push(...res_uugs.validate_errors);
    const group_uugs = _.groupBy(res_uugs.data, 'equip_id');

    const res_meterpads_meters = validete_sheet(workbook, 'Площадка-Вычислитель', ['B', 'C'], ['area_id', 'equip_id']);
    validate_errors.push(...res_meterpads_meters.validate_errors);
    
    const group_meterpads_meters = _.groupBy(res_meterpads_meters.data, 'equip_id');
    for (const key in group_meterpads_meters) {
        const meterpads_meters = group_meterpads_meters[key];
        if(meterpads_meters.length > 1) {
            validate_errors.push(`Во вкладке "Площадка-Вычислитель" УГГ [equip_id=${key}] принадлежит нескольким площадкам: [area_id=${meterpads_meters.map(p => p.area_id).join(',')}]`);
        }

        const v = meterpads_meters[0];
        if(v.area_id && !group_meterpads[v.area_id]) {
            validate_errors.push(`Во вкладке "Площадка-Вычислитель" площадка [area_id=${v.area_id}] отсутствует во вкладке "Площадки"`);
        }
        if(v.equip_id && !group_uugs[v.equip_id]) {
            validate_errors.push(`Во вкладке "Площадка-Вычислитель" УГГ [equip_id=${v.equip_id}] отсутствует во вкладке "УУГ (Приборы)"`);
        }        
    }

    const res_lines = validete_sheet(workbook, 'Линии-Вычислитель', ['B', 'C', 'D', 'E'], ['line_id', 'line_name', 'equip_id', 'equip_id_askug']);
    validate_errors.push(...res_lines.validate_errors);
    const group_lines = _.groupBy(res_lines.data, 'equip_id');
    
    const { data : { allGasMeterUnits }} = await apolloCoreClient.query({
        query: gql`
                query allGasMeterUnits($filter: GasMeterUnitFilter) {
                    allGasMeterUnits(filter: $filter, perPage: 10000000) { 
                        id                                    
                        gasMeter {
                            id
                            dbId                         
                        }                      
                    }
                }
            `,     
      });

    const added_dbids = allGasMeterUnits.filter(x => x.gasMeter && x.gasMeter.dbId).map(x => x.gasMeter.dbId);
       
    res_uugs.data.forEach(u => {
        
        if(added_dbids.indexOf(u.equip_id_askug) == -1) {
            validate_alert.push(`УУГ с "Equip id ASKUG" = ${u.equip_id_askug} [${u.equip_name}] не добавлен на портал`);
        }
        
        if(!group_lines[u.equip_id]) {
            validate_errors.push(`По УГГ [equip_id=${u.equip_id}] отсутствуют линии во вкладке "Линии-Вычислитель"`);
        }          
    });


    return {
        validate_errors,
        validate_alert
    }
};